export function authHeader() {
  let authorization = sessionStorage.getItem("access_token");
  if (authorization) {
    return {
      Authorization: "Bearer " + authorization,
    };
  } else {
    return {};
  }
}

export function contentHeader() {
  return {
    "Content-Type": "application/json",
    "Accept-Encoding": "gzip",
  };
}
export function getHeaders() {
  return {
    ...authHeader(),
    ...contentHeader(),
  };
}
