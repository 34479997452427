import JoditEditor from 'jodit-react'
import React, { Component } from 'react'
import config from '_utils/config'
function prepareData(data, customTenantId) {
  data.append('upload_preset', 'default')
  let file = data.getAll('files[0]')[0]
  var mimesToUpdate = ['.vnd', '.plain', '.x', '.javascript']
  let newName = (file && file.name) || 'Arquivo'
  mimesToUpdate.forEach((ext) => {
    if (newName && newName.endsWith(ext)) {
      newName = newName.substring(0, newName.indexOf(ext))
    }
  })
  newName = newName.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>{}[\]\\/]/gi, '')
  data.append('public_id', new Date().getTime() + '_' + newName)
  data.append('file', file, newName)
  data.append('folder', 'question/T' + customTenantId)
  data.delete('files[0]')
  return data
}
function defaultHandlerSuccess(data, resp, editor) {
  let file = data['file']
  let imgTemplate =
    "<a target='_blank' href='" +
    file.secure_url +
    "' alt='" +
    file.original_filename +
    "'>" +
    '<img  width=' +
    '500px' +
    " src='" +
    file.secure_url +
    "' style='max-width=" +
    '50vh' +
    "'/></a>"
  let linkTemplate =
    "<a target='_blank' href='" +
    file.secure_url +
    "' alt='" +
    file.original_filename +
    "'><i style='font-size:14px' class='fa fa-paperclip fa-xs'/> " +
    file.original_filename +
    '</a>'

  let videoTemplate =
    "<video width='" +
    file.width / 2 +
    "' height='" +
    file.height / 2 +
    "' controls='false'><source src='" +
    file.secure_url +
    "' type='video/" +
    file.format +
    "'/> </video>"
  let audioTemplate =
    "<audio  controls='false'><source src='" +
    file.secure_url +
    "' type='audio/" +
    file.format +
    "'/> </audio >"
  editor.s.insertHTML('<br/>')
  switch (file.format) {
    case 'mp4':
      editor.s.insertHTML(videoTemplate)
      break
    case 'ogg':
      editor.s.insertHTML(audioTemplate)
      break
    case 'mp3':
      editor.s.insertHTML(audioTemplate)
      break
    case 'mov':
      editor.s.insertHTML(videoTemplate)
      break
    case 'jpeg':
      editor.s.insertHTML(imgTemplate)
      break
    case 'jpg':
      editor.s.insertHTML(imgTemplate)
      break
    case 'png':
      editor.s.insertHTML(imgTemplate)
      break
    default:
      editor.s.insertHTML(linkTemplate)
      break
  }
}
const commonConfig = {
  language: 'pt_br',
  askBeforePasteFromWord: false,
  enter: 'DIV',
  toolbarAdaptive: false,
  width: '100%'
}

export class HtmlViwer extends Component {
  jodit
  setRef = (jodit) => (this.jodit = jodit)

  config = {
    ...commonConfig,
    readonly: true,
    toolbar: false,
    spellcheck: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    enableDragAndDropFileToEditor: false,
    preset: 'inline'
  }
  render() {
    return (
      <JoditEditor
        tabIndex={1}
        style={{ minHeight: '10px' }}
        editorRef={this.setRef}
        value={this.props.value || ''}
        config={this.config}
      />
    )
  }
}
export class HtmlEditor extends Component {
  config = {
    colors: ['#ff0000', '#00ff00', '#00ff00'],
    ...commonConfig,
    events: {
      getIcon: function (name, control, clearName) {
        switch (clearName) {
          case 'file':
            return '<i style="font-size:14px" class="fa fa-paperclip fa-xs"></i>'
          default:
            return ''
        }
      },
      afterInit: (e) => {
        this.editor2 = e
        this.editor2.name = this.props.field && this.props.field.name
        this.editor2.onChange = this.props.field && this.props.onChange
        this.editor2.tenantId = this.props.field && this.props.tenantId

        this.editor2.events.on('blur', () => {
          this.props.onChange(this.props.field.name, this.editor2.value || '')
        })
      }
    },
    link: { noFollowCheckbox: false, openInNewTabCheckbox: true },
    uploader: {
      imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
      error: function (e) {
        console.error(e)
      },
      filesVariableName: function (e) {
        return 'files[' + e + ']'
      },
      isSuccess: (resp) => {
        return !resp.error
      },
      getMsg: function (resp) {
        return resp.msg
      },
      process: (resp) => {
        return {
          file: resp,
          url: resp.secure_url,
          name: resp.original_filename,
          error: resp.error,
          msg: resp.msg
        }
      },
      prepareData: (data) =>
        prepareData(data, this.editor2.options.plainOptions.uploader.tenantId),
      defaultHandlerSuccess: (data, resp) => defaultHandlerSuccess(data, resp, this.editor2),
      defaultHandlerError: function (resp) {
        this.events.fire('errorPopap', [this.options.uploader.getMsg(resp)])
      },
      url: 'https://api.cloudinary.com/v1_1/' + config.storage.CLOUD_NAME_STORAGE + '/upload',
      tenantId: this.props.tenantId,
      method: 'POST'
    },
    buttons: this.props.buttons
      ? this.props.buttons
      : 'source,bold,italic,underline,|,ul,ol,|,outdent,indent,|,fontsize,brush,paragraph,|,file,table,link,|,align,hr,|,fullsize',
    statusBar: this.props.statusBar || false,
    enableDragAndDropFileToEditor: true,
    toolbar: this.props.toolbar,
    maxFileSize: 10000000,
    placeholder: this.props.placeholder || 'Escreva algo',
    readonly: this.props.readOnly || false
  }
  editor2
  typingTimer
  doneTypingInterval = 600
  handleKeyUp = () => {
    clearTimeout(this.typingTimer)
    this.typingTimer = setTimeout(() => {
      this.props.onChange(this.editor2.name, this.editor2.value || '')
    }, this.doneTypingInterval)
  }
  render() {
    return (
      <div>
        <JoditEditor
          style={{ minHeight: '10px' }}
          value={this.props.value || ''}
          config={this.config}
        />
        <small>{this.props.tip}</small>
      </div>
    )
  }
}
