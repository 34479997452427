import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Loading from 'components/Loading'
import AlertDialog from 'components/Messaging/AlertDialog'
import { CandidateList } from 'pages/Candidate/CandidatesPage'
import PropTypes from 'prop-types'
import React from 'react'
import { toast } from 'react-toastify'
import { GET_TAGS, SHARE_ASSESSMENT } from '_queries/ASSESSMENTS'
import { GET_CANDIDATES } from '_queries/CANDIDATES'
import { handleApolloErrors } from '_services/apollo'
import { msgs } from '_utils/appConstants'
import { isEmailValid } from '_utils/tools'
import SharingConfig from './SharingConfig'
export const letters = 'abcdefghijklmnopqrstuvxzw'
function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

export function DatabaseCandidateList({ onShare, loading }) {
  const [selectedEmails, setSelectedEmails] = React.useState([])
  return (
    <Grid container>
      <Grid container item md={12} xs={12} justify="center">
        {loading ? (
          <Loading />
        ) : (
          <Button
            style={{ marginBottom: '0.5rem' }}
            disabled={selectedEmails.length === 0}
            onClick={() => onShare(selectedEmails)}>
            Enviar Links
          </Button>
        )}
      </Grid>
      <Grid item md={12} xs={12}>
        <CandidateList
          columns={[
            { title: 'Nome', field: 'fullName' },
            { title: 'Email', field: 'email' }
          ]}
          options={{ selection: true, sorting: true, padding: 'dense', grouping: false }}
          onSelectionChange={(rows) =>
            setSelectedEmails(
              rows
                .map((item) => item.email)
                .filter(function (el) {
                  return el != null
                })
            )
          }
        />
      </Grid>
    </Grid>
  )
}
export function BatchSharingTest({ emails, loading, onShare }) {
  const [inputEmails, setEmails] = React.useState(emails || [])
  return (
    <Grid container>
      <Grid container item md={12} xs={12} justify="center">
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            style={{ marginBottom: '0.5rem' }}
            disabled={inputEmails.filter((ie) => ie.valid).length === 0}
            onClick={() => onShare(inputEmails.filter((eml) => eml.valid).map((e) => e.label))}>
            Enviar Links
          </Button>
        )}
      </Grid>

      <Grid item md={12} xs={12}>
        <Autocomplete
          multiple
          id="tags-filled"
          options={[]}
          limitTags={15}
          value={inputEmails}
          onChange={(e, s) => {
            let processedEmails = []
            if (e.target.value) {
              new Set(e.target.value.split(/[ =:;?!~,`&|{}\r\n/\\]+/)).forEach((eml) => {
                if (inputEmails.filter((e) => e.label === eml).length === 0) {
                  processedEmails.push({ label: eml, valid: isEmailValid(eml) })
                }
              })
              setEmails([...inputEmails, ...processedEmails])
            } else {
              setEmails(s)
            }
          }}
          freeSolo
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => {
              return option.valid ? (
                <Chip
                  label={option.label}
                  variant="outlined"
                  icon={option.valid ? <DoneIcon /> : void 0}
                  color={option.valid ? 'default' : 'primary'}
                  {...getTagProps({ index })}
                />
              ) : (
                <Tooltip title="Não é um email válido" arrow>
                  <Chip
                    label={option.label}
                    variant="outlined"
                    icon={option.valid ? <DoneIcon /> : void 0}
                    color={option.valid ? 'default' : 'primary'}
                    {...getTagProps({ index })}
                  />
                </Tooltip>
              )
            })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={'Email dos avaliados '}
              helperText={`${inputEmails.length} email(s) selecionado(s), ${
                inputEmails.filter((ie) => ie.valid).length
              } válido(s), ${
                inputEmails.length - inputEmails.filter((ie) => ie.valid).length
              } inválido(s)`}
              placeholder="Inclua aqui emails separados por espaço ou virgula e tecle ENTER para confirmar"
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
const SharingDialogContent = ({ onSharing, loading }) => {
  const [tabIndex, setTabIndex] = React.useState(0)
  const handleChange = (event, newValue) => {
    setTabIndex(newValue)
  }
  const [config, setConfig] = React.useState({})

  React.useEffect(() => {}, [config])
  return (
    <div style={{ textAlign: 'center' }}>
      <Grid container justify="center">
        <SharingConfig config={{ hardDueDate: null }} onConfigChange={(cfg) => setConfig(cfg)} />
      </Grid>{' '}
      <Typography variant="h6">Fonte de avaliados</Typography>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        centered
        value={tabIndex}
        onChange={handleChange}
        aria-label="disabled tabs example">
        <Tab label="Em Massa" />
        <Tab label="Usuários da base" />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        {loading ? (
          <Grid container justify="center">
            <CircularProgress />
          </Grid>
        ) : (
          <BatchSharingTest onShare={(v) => onSharing(v, config)} />
        )}
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        {loading ? (
          <Grid container justify="center">
            <CircularProgress />
          </Grid>
        ) : (
          <DatabaseCandidateList
            onShare={(v) => {
              onSharing(v, config)
            }}
          />
        )}
      </TabPanel>
    </div>
  )
}

export function SharingDialog(props) {
  const { dialogOpen, onDialog, assessment } = props
  const [loading, setLoading] = React.useState(false)
  const [shareAssessmentByEmail] = useMutation(SHARE_ASSESSMENT, {
    name: 'shareAssessmentByEmail',
    refetchQueries: [
      {
        query: GET_CANDIDATES
      },
      {
        query: GET_TAGS
      }
    ],
    onError: (error) => handleApolloErrors(error),
    onCompleted: (results) => {
      window.location = '/tests/results'
      toast.success(msgs.operationSuccess)
    }
  })
  const handleSharingAction = (emails, config) => {
    setLoading(true)
    shareAssessmentByEmail({
      variables: {
        assessmentId: assessment.id,
        emails,
        tags: [], //_tags,
        config: config
      }
    }).then(() => {
      setLoading(false)
    })
  }
  return (
    <AlertDialog
      open={dialogOpen}
      onClose={() => {
        onDialog(!dialogOpen)
      }}
      title={`Aplicar Teste ${assessment && assessment.title}`}
      content={
        <SharingDialogContent
          onSharing={(emails, config) => handleSharingAction(emails, config)}
          loading={loading}
        />
      }
    />
  )
}

export default SharingDialog
