import { userConstants } from "_constants/userConstants";

let user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? { loggedIn: true, user } : { loggedIn: false };

export const authentication = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        user: void 0,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loading: false,
      };
    case userConstants.GETALL_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loading: false,
        users: action.response,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        redirectUrl: "/login",
      };

    case userConstants.GETSELF_REQUEST:
      return {
        ...state,
        loading: true,
        user: action.user,
      };
    case userConstants.GETSELF_SUCCESS:
      let newState = {
        ...state,
        user: action.payload,
        loggedIn: true,
      };

      return newState;
    case userConstants.GETSELF_FAILURE:
      return {
        ...state,
        redirectUrl: "/login",
      };

    case userConstants.CHANGE_PASSWORD_SOLICITATION_REQUEST:
      return {
        code: userConstants.CHANGE_PASSWORD_SOLICITATION_REQUEST,
        loading: true,
      };
    case userConstants.CHANGE_PASSWORD_SOLICITATION_SUCCESS:
      return {};
    case userConstants.CHANGE_PASSWORD_SOLICITATION_FAILURE:
      return {};
    case userConstants.CHANGE_PASSWORD_REQUEST:
      return {
        loading: true,
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return { isPasswordChanged: true };
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
};
