import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Typography, Grid, Breadcrumbs, Link, Icon, Button } from '@material-ui/core'
import { getHomeByProfile } from 'components/Layout/Navigation/AppToolBar'
import gql from 'graphql-tag'
import TestVisualizationForm from './TestVisualizationForm'
import { handleApolloErrors } from '_services/apollo'
import PageHeaderPath from 'components/PageHeaderPath'
import Loading from 'components/Loading'
import { useQuery } from '@apollo/client'
import SharingDialog from '../SharingDialog'
import HelpButton from 'components/HelpButton'
import config from '_utils/config'

function TestVisualizationPage(_pageProps, { onShare }) {
  document.title = 'Question - Visualização de Teste'
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [assessmentId] = React.useState(_pageProps.match.params.id)
  const { data, loading } = useQuery(GET_ASSESSMENT, {
    fetchPolicy: 'no-cache',
    variables: {
      id: assessmentId
    },
    onError: (error) => handleApolloErrors(error)
  })
  return loading ? (
    <Loading />
  ) : (
    data && (
      <Grid item lg={12} xs={12}>
        <PageHeaderPath>
          <Breadcrumbs separator="›" aria-label="Breadcrumb">
            <Link to={getHomeByProfile()} component={RouterLink}>
              Home
            </Link>
            <Link to={'/tests'} component={RouterLink}>
              Testes
            </Link>
            <Typography color="textPrimary">Visualização</Typography>
            <Button
              size="small"
              onClick={() => {
                setDialogOpen(true)
              }}
              disableElevation
              startIcon={<Icon>launch</Icon>}>
              Aplicar Teste
            </Button>
            <Button
              to={'/tests/edit/' + assessmentId}
              component={RouterLink}
              size="small"
              disableElevation
              startIcon={<Icon>edit</Icon>}>
              Editar Teste
            </Button>
          </Breadcrumbs>{' '}
          <HelpButton link={config.tutorials.TUTORIAL_VIEW_TEST_PAGE} />
        </PageHeaderPath>
        <SharingDialog
          dialogOpen={dialogOpen}
          onDialog={(state) => setDialogOpen(state)}
          assessment={data && data.assessment}
        />
        <Grid container justify="center" alignContent="center" alignItems="center">
          <TestVisualizationForm assessment={data && data.assessment} />
        </Grid>
      </Grid>
    )
  )
}

export const GET_ASSESSMENT = gql`
  query GET_ASSESSMENT($id: Long!) {
    assessment(id: $id) {
      id
      title
      instructions
      testDurationInMinutes
      questions {
        id
        text
        questionType
        choices {
          id
          text
          score
        }
      }
    }
  }
`
export default TestVisualizationPage
