import { Avatar } from '@material-ui/core'
import MaterialTable from 'material-table'
import React from 'react'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { autoCloseDelay, msgs, pageSize, tableLocalization } from '_utils/appConstants'
import history from '_utils/history'
import moment, { toLocalDate, toMoment } from '_utils/moment'
let toastId = null
const notifyProgress = () => (toastId = toast('Processando...', { autoClose: false }))
const success = (result) => {
  console.debug(result)
  toast.update(toastId, {
    render: msgs.operationSuccess,
    type: toast.TYPE.SUCCESS,
    autoClose: autoCloseDelay
  })
}
function error(_exception) {
  console.error(_exception)
  if (_exception.toString().indexOf(':') !== -1) {
    var pieces = _exception.toString().split(':')
    toast.update(toastId, {
      render: pieces[pieces.length - 1],
      type: toast.TYPE.ERROR,
      autoClose: autoCloseDelay
    })
  } else {
    toast.update(toastId, {
      render: msgs.operationError,
      type: toast.TYPE.ERROR,
      autoClose: autoCloseDelay
    })
  }
}

function UsersTable({ data, onToggleUserAccess, onDelete, loading }) {
  document.title = 'Question - Lista Usuários'

  const tableRef = React.createRef()
  return (
    <MaterialTable
      // style={{ width: '100%' }}
      tableRef={tableRef}
      localization={tableLocalization}
      title={'Usuários'}
      options={{
        pageSize: pageSize,
        actionsColumnIndex: -1,
        exportButton: true,
        defaultExpanded: true,
        search: false,
        padding: 'dense',
        sorting: true,
        debounceInterval: 500,
        rowStyle: (rowData) => ({
          color: rowData.enabled ? '' : '#CD5C5C'
        })
      }}
      columns={[
        {
          title: 'Nome',
          field: 'fullName',
          render: (rowData) => (
            <span style={{ display: 'table' }}>
              <div
                style={{
                  display: 'table-cell',
                  verticalAlign: 'middle'
                }}>
                <Avatar
                  style={{
                    marginRight: '0.5rem'
                  }}
                  src={rowData.avatarUrl}
                  sizes={'small'}
                />
              </div>
              <div
                style={{
                  display: 'table-cell',
                  verticalAlign: 'middle'
                }}>
                {' ' + rowData.fullName}
              </div>
            </span>
          )
        },
        { title: 'Email', field: 'email', grouping: false },
        {
          title: 'Cliente',
          field: 'tenant.organization',
          grouping: true
        },
        {
          title: 'Tipo',
          field: 'roles[0].label',
          grouping: true
        },

        {
          title: 'Ativo',
          field: 'enabled',
          grouping: true,
          type: 'boolean'
        },
        {
          title: 'Criação',
          field: 'createdAt',
          grouping: true,
          type: 'datetime',
          customSort: (a, b) => {
            var Ax = toMoment(a.createdAt)
            var By = toMoment(b.createdAt)
            var duration = moment.duration(By.diff(Ax))
            return duration
          },
          render: (rowData) => toLocalDate(rowData.createdAt)
        },
        {
          title: 'Último Login',
          field: 'lastLoginAt',
          type: 'datetime',
          emptyValue: '',
          render: (rowData) => toLocalDate(rowData.lastLoginAt)
        }
      ]}
      data={data}
      isLoading={loading}
      actions={[
        {
          icon: 'edit',
          tooltip: 'Editar Usuário',
          onClick: (event, rowData) => {
            history.push('/users/edit/' + rowData.id)
          }
        },
        {
          icon: 'block',
          tooltip: 'Bloquear/Desbloquear Usuário',
          onClick: (event, rowData) => {
            notifyProgress()
            onToggleUserAccess({
              variables: { id: rowData.id }
            })
              .then((results) => {
                rowData.enabled !== results.data.toggleUserAccess.enabled
                  ? success(msgs.operationSuccess)
                  : error(msgs.operationError)
              })
              .catch((e) => error(e))
          }
        },
        (rowData) => ({
          icon: 'delete',
          tooltip: 'Remover Usuário',
          onClick: (event) => {
            Swal.fire({
              title: 'Tem certeza que deseja remover este usuário?',
              text: 'Não será possível desfazer está ação!',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#FE5000',
              cancelButtonColor: '#979696',
              cancelButtonText: 'Cancelar',
              confirmButtonText: 'Sim, remover usuário!'
            }).then((result) => {
              if (result.value) {
                notifyProgress()
                onDelete({
                  variables: { id: rowData.id }
                })
                  .then((results) => {
                    results.data.deleteUser
                      ? success(msgs.operationSuccess)
                      : error(msgs.operationError)
                  })
                  .catch((exception) => {
                    error(exception)
                  })
              }
            })
          }
        })
      ]}
    />
  )
}
export default UsersTable
