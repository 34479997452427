import * as Yup from 'yup'
export default () =>
  Yup.object().shape({
    title: Yup.string()
      .min(2, 'Muito curto!')
      .max(50, 'Campo excedeu a quantidade de caracteres permitidos!')
      .required('Campo Obrigatório'),
    testDurationInMinutes: Yup.number().when('timeControlled', {
      is: true,
      then: Yup.number().min(5, 'Tempo de execução deve ser no mínimo de 5 minutos'),
      otherwise: Yup.number().min(0)
    })
  })
