import React, { Component } from 'react'
import { connect } from 'react-redux'
import ForgotPasswordForm from 'pages/ForgotPassword/ForgotPasswordForm'
import { Grid } from '@material-ui/core'
import { Formik } from 'formik'
import ForgotPasswordValidationSchema from './ForgotPasswordValidationSchema'
import { userService } from '_services/user.service'
import { toast } from 'react-toastify'
import history from '_utils/history'

class ForgotPassword extends Component {
  render() {
    return (
      <Grid container alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
        <Formik
          enableReinitialize={true}
          initialValues={{ email: '' }}
          validationSchema={ForgotPasswordValidationSchema}
          onSubmit={(values, actions) => {
            userService
              .requestPasswordRecovery(values.email)
              .then((response) => {
                toast.success(response.message)
                history.push('/login')
              })
              .catch((error) => toast.error(error.message))
              .then(() => actions.setSubmitting(false))
          }}>
          {(props) => <ForgotPasswordForm {...props} {...this.props} />}
        </Formik>
      </Grid>
    )
  }
}
export default connect()(ForgotPassword)
