import React from "react";
import { Grid } from "@material-ui/core";

export default function PageHeaderPath(props) {
  return (
    <Grid
      container
      item
      md={12}
      style={{
        paddingRight: "0.5rem",
        marginBottom: "0.7rem",
        minHeight: "52px",
      }}
      alignItems="center"
    >
      <Grid container item md={6} justify="flex-start">
        {props.children}
      </Grid>
      {props.callToAction && (
        <Grid container item md={6} justify="flex-end">
          {props.callToAction}
        </Grid>
      )}
    </Grid>
  );
}
