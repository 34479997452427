import {
  Avatar,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Icon,
  IconButton,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { toLocalDate2 } from '_utils/moment'

const useStyles = makeStyles((theme) => ({
  root: { minHeight: 300, minWidth: 300, textAlign: 'center' },
  card: {
    minWidth: 275,
    marginBottom: theme.spacing(1)
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7)
  }
}))

export const UserListGrid = ({ data, onEdit, onToggleUserAccess, onDelete }) => {
  document.title = 'Question - Listagem Usuários'
  const classes = useStyles()
  return (
    <Grid container spacing={2} direction="row">
      {data &&
        data.map((user, index) => {
          return (
            <Grid item lg={3} md={4} xs={12} key={index}>
              <Card className={classes.root}>
                <CardHeader title={user.title} />
                <CardContent>
                  <div style={{ textAlign: 'center', display: 'inline-block' }}>
                    <Avatar src={user.avatarUrl} className={classes.large} />
                  </div>
                  {user.fullName && (
                    <Typography variant="h5" color="inherit">
                      {user.fullName}
                    </Typography>
                  )}
                  {user.email && (
                    <Typography variant="h6" color="textSecondary">
                      {user.email}
                    </Typography>
                  )}
                  {!user.enabled ? (
                    <Chip label="Usuário Bloqueado" color="primary" size="small" />
                  ) : (
                    <Chip label="Usuário Ativo" color="secondary" size="small" />
                  )}
                  {user.updatedAt && (
                    <Typography variant="body2" color="textSecondary">
                      Atualização:
                      {user.updatedAt && toLocalDate2(user.updatedAt)}
                    </Typography>
                  )}
                </CardContent>
                <CardActions disableSpacing>
                  <Grid container justify="center">
                    <IconButton title="Editar Usuário" onClick={() => onEdit(user)}>
                      <Icon>edit</Icon>
                    </IconButton>{' '}
                    <IconButton
                      color="primary"
                      title="Bloquear/Desbloquear Usuário"
                      onClick={() => {
                        onToggleUserAccess(user)
                      }}>
                      <Icon>block</Icon>
                    </IconButton>
                    <IconButton
                      color="primary"
                      title="Remover Usuário"
                      onClick={() => {
                        onDelete(user)
                      }}>
                      <Icon>delete_outline</Icon>
                    </IconButton>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          )
        })}
      <Grid item lg={3} md={4} xs={12}>
        <Link to="/new_user" component={RouterLink}>
          <CardActionArea>
            <Card className={classes.root}>
              <Typography variant="h5" color="primary" style={{ paddingTop: '137px' }}>
                + Novo Usuário
              </Typography>
            </Card>
          </CardActionArea>
        </Link>
      </Grid>
    </Grid>
  )
}
