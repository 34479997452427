import { Box, Button, Grid, Icon, Paper, Tooltip, Typography } from '@material-ui/core'
import { CustomSimpleSwitch, CustomSlider, CustomTextField } from 'components/Layout/FormFields'
import FormHeading from 'components/Layout/FormHeading'
import { Field, Form } from 'formik'
import React from 'react'

export default function NewTestForm(props) {
  const { values, handleChange, loading, isValid, setFieldValue } = props
  return (
    <Box p={3} component={Paper}>
      <Form>
        <FormHeading>Novo Teste</FormHeading>
        <Grid item md={12} xs={12} lg={12}>
          <Field id="title" name="title" component={CustomTextField} label="Título" />
        </Grid>
        <Grid container item direction="row">
          <Grid item md={6} xs={12}>
            <Field
              name="timeControlled"
              component={CustomSimpleSwitch}
              label="Teste Temporizado?"
            />{' '}
            <Tooltip
              title="O tempo disponível para o avaliado executar a prova uma vez aberta"
              enterDelay={500}
              leaveDelay={200}>
              <Icon fontSize="small" color="disabled">
                help
              </Icon>
            </Tooltip>
          </Grid>{' '}
          {values.timeControlled && (
            <Grid item md={6} xs={12}>
              <Field
                type="number"
                onChange={(e) =>
                  e.target.value >= 0 && e.target.value < 60 * 24 ? handleChange(e) : void 0
                }
                name="testDurationInMinutes"
                component={CustomTextField}
                label="Tempo para execução (min.)"
              />
            </Grid>
          )}
          <Grid item md={12} xs={12}>
            <Field
              name="showResultsAfterFinish"
              component={CustomSimpleSwitch}
              label="Mostrar resultados ao final da execução?"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Box p={1}>
              {values.testAvailabilityInDays && values.testAvailabilityInDays && (
                <>
                  <Typography id="discrete-slider" gutterBottom>
                    Disponibilidade do teste{' '}
                    <span style={{ fontWeight: 'bolder', fontSize: '1.5em' }}>
                      {values.testAvailabilityInDays} dias.
                    </span>{' '}
                    <Tooltip
                      title="Quantos dias o teste ficará disponível para execução após envio"
                      enterDelay={500}
                      leaveDelay={200}>
                      <Icon fontSize="small" color="disabled">
                        help
                      </Icon>
                    </Tooltip>
                  </Typography>
                </>
              )}
              <Field
                component={CustomSlider}
                name="testAvailabilityInDays"
                id={'testAvailabilityInDays'}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                onChange={(e, value) => setFieldValue('testAvailabilityInDays', value)}
                step={1}
                marks
                min={1}
                max={10}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container item justify="center">
          <Button
            disabled={loading || !isValid}
            color="primary"
            type="submit"
            startIcon={<Icon>save</Icon>}>
            Salvar
          </Button>
        </Grid>
      </Form>
    </Box>
  )
}
