import React from 'react'
import { Grid, Paper, Button, Box, Icon } from '@material-ui/core'
import { Field, Formik, Form } from 'formik'
import { CustomTextField, CustomSelectInput } from 'components/Layout/FormFields'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { toast } from 'react-toastify'
import { msgs, autoCloseDelay } from '_utils/appConstants'
import UserValidationSchema from './UserValidationSchema'
import FormHeading from 'components/Layout/FormHeading'
import Loading from 'components/Loading'
import { handleApolloErrors } from '_services/apollo3'

let toastId = null
export const EditUserForm = (props) => {
  const { isSubmitting, handleChange, roles } = props
  return (
    <Box p={3} component={Paper}>
      <Form noValidate>
        <FormHeading>Edição Usuário</FormHeading>
        <Field name="fullName" component={CustomTextField} label="Nome Usuário" />
        <Field name="email" component={CustomTextField} label="Email" />
        <Field
          name="roles[0].id"
          component={CustomSelectInput}
          label="Perfil"
          options={roles}
          onChange={handleChange}
        />
        <Grid container justify="center">
          <Button type="submit" disabled={isSubmitting} startIcon={<Icon>save</Icon>}>
            Salvar
          </Button>
        </Grid>
      </Form>
    </Box>
  )
}
const GET_ROLES = gql`
  {
    allRoles {
      id
      label
      name
    }
  }
`
const ConnectedEditUserForm = ({ user, onUserUpdate }) => {
  const { data } = useQuery(GET_ROLES)
  const roles =
    data &&
    data.allRoles.map((role) => ({
      label: role.label,
      id: role.id
    }))

  const notifyProgress = () => (toastId = toast('Processando...', { autoClose: false }))
  const success = (result) => {
    toast.update(toastId, {
      render: msgs.operationSuccess,
      type: toast.TYPE.SUCCESS,
      autoClose: autoCloseDelay
    })
  }
  const error = (exception) => {
    console.error(exception)
    toast.update(toastId, {
      render: exception || msgs.operationError,
      type: toast.TYPE.ERROR,
      autoClose: autoCloseDelay
    })
  }

  return (
    <Formik
      initialValues={{
        ...user
      }}
      validationSchema={UserValidationSchema}
      onSubmit={(variables, { setSubmitting }) => {
        notifyProgress()
        onUserUpdate({ variables: { request: { ...variables } } })
          .then((result) => {
            success(result)
            setSubmitting(false)
          })
          .catch((exception) => {
            error()
            handleApolloErrors(exception)
            setSubmitting(false)
          })
      }}>
      {(formProps) =>
        formProps.isSubmitting ? <Loading /> : <EditUserForm {...formProps} roles={roles} />
      }
    </Formik>
  )
}
export default ConnectedEditUserForm
