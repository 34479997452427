import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Icon,
  Paper
} from '@material-ui/core'
import FormHeading from 'components/Layout/FormHeading'
import { ProfileContext } from 'components/Layout/Navigation/Navigation'
import gql from 'graphql-tag'
import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { autoCloseDelay, msgs } from '_utils/appConstants'
let toastId = -1
export function LGPDDataWidget(classProps) {
  const profile = useContext(ProfileContext)
  const [updateLGPD, { loading }] = useMutation(UPDATE_USER_LGPD, {})
  const notifyProgress = () =>
    (toastId = toast('Processando...', {
      autoClose: false
    }))
  const success = (result) => {
    toast.update(toastId, {
      render: msgs.operationSuccess,
      type: toast.TYPE.SUCCESS,
      autoClose: autoCloseDelay
    })
    setOption(result.data.updateUserLGPD.acceptedShareData)
  }
  const error = (exception) => {
    console.error(exception)
    toast.update(toastId, {
      render: msgs.operationError,
      type: toast.TYPE.ERROR,
      autoClose: autoCloseDelay
    })
  }

  React.useEffect(() => {
    setOption(profile.acceptedShareData)
  }, [profile])

  const [option, setOption] = React.useState(false)

  const updateState = (e) => {
    setOption(e.target.checked)
  }

  const Check = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox color="primary" defaultChecked={option} onChange={(e) => updateState(e)} />
        }
        label="Compartilhar meus dados cadastrais com parceiros para fins de marketing de produtos relacionados."
        labelPlacement="end"
      />
    )
  }
  if (!profile.admin) return ''
  return (
    <Grid item xs={12} md={6}>
      <Box p={3} component={Paper}>
        <FormHeading variant="h4">Privacidade </FormHeading>{' '}
        <Grid item xs={12} md={12}>
          <Check />
        </Grid>
        <Grid container justify="center">
          {classProps.loading ? (
            <CircularProgress />
          ) : (
            <Button
              variant="outlined"
              disabled={loading}
              onClick={(e) => {
                notifyProgress()
                updateLGPD({
                  variables: {
                    id: profile.id,
                    acceptedShareData: option
                  }
                })
                  .then((results) => {
                    results.data.updateUserLGPD ? success(results) : error(msgs.operationError)
                  })
                  .catch((exception) => {
                    error(exception)
                  })
              }}
              startIcon={<Icon>save</Icon>}>
              Salvar
            </Button>
          )}
        </Grid>
      </Box>
    </Grid>
  )
}

export const UPDATE_USER_LGPD = gql`
  mutation UPDATE_USER_LGPD($id: Long, $acceptedShareData: Boolean) {
    updateUserLGPD(id: $id, acceptedShareData: $acceptedShareData) {
      id
      acceptedShareData
    }
  }
`
export default LGPDDataWidget
