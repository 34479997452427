import React from 'react'
import { Typography, Grid, Breadcrumbs, Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import NewUserForm from './NewUserForm'
import { getHomeByProfile } from 'components/Layout/Navigation/AppToolBar'
import PageHeaderPath from 'components/PageHeaderPath'

const NewUserPage = () => {
  document.title = 'Question - Novo Usuário'
  return (
    <Grid container justify="center" alignContent="center" alignItems="center">
      <PageHeaderPath>
        <Breadcrumbs separator="›" aria-label="Breadcrumb">
          <Link to={getHomeByProfile()} component={RouterLink}>
            Home
          </Link>
          <Link to={'/users'} component={RouterLink}>
            Usuários
          </Link>
          <Typography>Novo Usuário</Typography>
        </Breadcrumbs>
      </PageHeaderPath>
      <Grid item md={4}>
        <NewUserForm />
      </Grid>
    </Grid>
  )
}

export default NewUserPage
