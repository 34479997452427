export const ROLES = {
  ROLE_OPERATOR: 'ROLE_OPERATOR',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_CANDIDATE: 'ROLE_CANDIDATE'
}
export default [
  {
    key: 'starred',
    primaryText: 'Testes',
    leftIcon: 'view_list',
    visible: true,
    to: '/tests',
    roles: [ROLES.ROLE_ADMIN, ROLES.ROLE_OPERATOR]
  },
  {
    key: 'candidates',
    primaryText: 'Avaliados',
    leftIcon: 'face',
    visible: true,
    to: '/candidates',
    roles: [ROLES.ROLE_ADMIN, ROLES.ROLE_OPERATOR]
  },
  {
    key: 'starred',
    primaryText: 'Resultados',
    leftIcon: 'find_in_page',
    visible: true,
    to: '/tests/results',
    roles: [ROLES.ROLE_ADMIN, ROLES.ROLE_OPERATOR]
  },
  {
    key: 'users',
    primaryText: 'Usuários',
    leftIcon: 'supervisor_account',
    visible: true,
    to: '/users',
    roles: [ROLES.ROLE_ADMIN]
  },
  {
    key: 'candidate-area',
    primaryText: 'Home',
    leftIcon: 'person',
    visible: true,
    to: '/candidate/home',
    roles: [ROLES.ROLE_CANDIDATE]
  },
  {
    key: 'candidate-area',
    primaryText: 'Meus Testes',
    leftIcon: 'view_list',
    visible: true,
    to: '/candidate/tests',
    roles: [ROLES.ROLE_CANDIDATE]
  },
  { key: 'divider', divider: true, visible: true },

  {
    key: 'exit',
    primaryText: 'Sair',
    leftIcon: 'highlight_off',
    visible: true,
    to: '/logout',
    roles: [ROLES.ROLE_ADMIN]
  }
]
