import { useMutation } from '@apollo/client'
import { Breadcrumbs, Container, Grid, Link, Typography } from '@material-ui/core'
import { getHomeByProfile } from 'components/Layout/Navigation/AppToolBar'
import PageHeaderPath from 'components/PageHeaderPath'
import { Formik } from 'formik'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CREATE_ASSESSMENT } from '_queries/ASSESSMENTS'
import { handleApolloErrors } from '_services/apollo'
import history from '_utils/history'
import TestValidationSchema from '../TestValidationSchema'
import NewTestForm from './NewTestForm'

function NewTestPage(pageProps) {
  document.title = 'Question - Novo Teste'
  const [createAssessment] = useMutation(CREATE_ASSESSMENT, {
    update: (cache, { data: { createAssessment } }) => {
      cache.modify({
        fields: {
          allAssessments(existingAllAssessmentsRef = [], { readField }) {
            if (
              existingAllAssessmentsRef.some((ref) => readField('id', ref) === createAssessment.id)
            ) {
              return existingAllAssessmentsRef
            }

            return [...existingAllAssessmentsRef, createAssessment]
          }
        }
      })
    },
    onCompleted: (results) => {
      toast.success('Teste criado com sucesso!')
      history.push(`/tests/edit/${results.createAssessment.id}`)
    },
    onError: (_error) => handleApolloErrors(_error)
  })
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item lg={12} xs={12} md={12}>
        <PageHeaderPath>
          <Breadcrumbs separator="›" aria-label="Breadcrumb">
            <Link to={getHomeByProfile()} component={RouterLink}>
              Home
            </Link>
            <Link to={'/tests'} component={RouterLink}>
              Testes
            </Link>
            <Typography color="textPrimary"> Criar Teste</Typography>
          </Breadcrumbs>
        </PageHeaderPath>
      </Grid>{' '}
      <Container direction="column" maxWidth="md">
        <Formik
          enableReinitialize={true}
          initialValues={{
            title: '',
            timeControlled: false,
            testDurationInMinutes: 0,
            showResultsAfterFinish: false,
            testAvailabilityInDays: 2
          }}
          validationSchema={TestValidationSchema}
          onSubmit={(values) => {
            createAssessment({
              variables: { request: { ...values } }
            })
          }}>
          {(formProps) => <NewTestForm {...formProps} />}
        </Formik>
      </Container>
    </Grid>
  )
}

export default NewTestPage
