import React from 'react'

import {
  Typography,
  Grid,
  Paper,
  Chip,
  Icon,
  List,
  Switch,
  FormControlLabel,
  Button,
  ButtonGroup,
  IconButton,
  Box
} from '@material-ui/core'
import { toTime, toLocalDate, dateFormat } from '_utils/moment'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { HTMLQuestionTitle } from 'components/Layout/HTMLQuestionTitle'
import { HtmlViwer } from 'components/Editor'
import { HTMLChoice } from 'components/Layout/HTMLChoice'
export default function TestVisualizationForm(props) {
  const { candidateAssessment } = props
  const { assessment } = candidateAssessment
  const MultiLineRenderer = (props) => {
    return (
      <Box mt={3}>
        <Typography variant="caption" title={props.question.id}>
          <HTMLQuestionTitle
            chip={
              props.showAnswer && (
                <Chip
                  size="small"
                  style={{
                    color: 'white',
                    backgroundColor: props.question.status === 'Correta' ? 'green' : 'red'
                  }}
                  label={props.question.status}></Chip>
              )
            }
            index={props.index}
            current={props.index + 1}
            size={props.size}
            showAnswers={props.showAnswers}
            text={props.question.text}
          />
        </Typography>
        <List dense={true} disablePadding={true}>
          {props.question.choices.map((choice, index) => {
            return (
              <HTMLChoice
                choice={choice}
                index={index}
                showCandidateAnswers={props.showCandidateAnswers}
                showAnswers={props.showAnswer}
              />
            )
          })}
        </List>
      </Box>
    )
  }
  const CheckBoxRenderer = (props) => {
    return (
      <Box mt={3}>
        <HTMLQuestionTitle
          chip={
            props.showAnswer && (
              <Chip
                size="small"
                style={{
                  color: 'white',
                  backgroundColor: props.question.status === 'Correta' ? 'green' : 'red'
                }}
                label={props.question.status}></Chip>
            )
          }
          current={props.index + 1}
          size={props.size}
          index={props.index}
          text={props.question.text}
        />{' '}
        <List dense={true} disablePadding={true}>
          {props.question.choices.map((choice, index) => {
            return (
              <HTMLChoice
                choice={choice}
                index={index}
                showCandidateAnswers={props.showCandidateAnswers}
                showAnswers={props.showAnswer}
              />
            )
          })}
        </List>
      </Box>
    )
  }
  const TextQuestionAnswer = (props) => {
    const { answer } = props
    const UPDATE_TEXT_ANSWER_STATUS = gql`
      mutation updateTextAnswer($answerId: Long!, $score: Float!) {
        updateTextAnswer(answerId: $answerId, score: $score) {
          id
          score
        }
      }
    `

    const [updateTextAnswer, { loading: mutationLoading, error: mutationError }] = useMutation(
      UPDATE_TEXT_ANSWER_STATUS
    )

    const ComboChip = () => (
      <>
        {props.showAnswer && (
          <Chip
            size="small"
            style={{
              color: 'white',
              backgroundColor: answer.question.status === 'Correta' ? 'green' : 'red'
            }}
            label={answer.question.status}></Chip>
        )}
        <ButtonGroup
          style={{ display: props.showCorrectionButtons ? '' : 'none' }}
          variant="outlined"
          size="small"
          aria-label="small outlined button group">
          <Button
            color="secondary"
            onClick={() =>
              updateTextAnswer({
                variables: { answerId: answer.id, score: 1.0 }
              }).then((result) => {
                window.location.reload()
              })
            }>
            Marcar Correta
          </Button>
          <Button
            color="primary"
            onClick={() =>
              updateTextAnswer({
                variables: { answerId: answer.id, score: 0.0 }
              }).then((result) => {
                window.location.reload()
              })
            }>
            Marcar Incorreta
          </Button>
        </ButtonGroup>
      </>
    )
    return (
      <Box>
        <HTMLQuestionTitle
          chip={<ComboChip />}
          current={props.index + 1}
          size={props.size}
          index={props.index}
          text={answer.question.text}
        />
        {mutationLoading && <p>Carregando...</p>}
        {mutationError && <p>Erro :( Tente novamente</p>}
        <p>R. </p>
        {answer.question && props.showCandidateAnswers && (
          <div
            style={{
              border: props.showCandidateAnswers ? '1px solid black' : ''
            }}>
            <HtmlViwer className="htmlOnly" value={answer.answerText || ''} />
          </div>
        )}
      </Box>
    )
  }
  const QuestionsRenderer = (props) => {
    const [showAnswer, setShowAnswer] = React.useState(true)
    const [showCandidateAnswer, setShowCandidateAnswer] = React.useState(true)
    const answers = props.candidateAssessment && props.candidateAssessment.answers
    return (
      answers &&
      answers.length > 0 && (
        <Grid container>
          <Grid item md={12} xs={12} style={{ textAlign: 'center' }}>
            <FormControlLabel
              control={<Switch checked={showAnswer} onChange={() => setShowAnswer(!showAnswer)} />}
              label="Gabarito"
            />
            {props.showCandidateAnswerSelector && (
              <FormControlLabel
                control={
                  <Switch
                    checked={showCandidateAnswer}
                    onChange={() => setShowCandidateAnswer(!showCandidateAnswer)}
                  />
                }
                label="Respostas Avaliado"
              />
            )}{' '}
          </Grid>
          <Grid item md={12} xs={12}>
            {answers.map((answer, index) => {
              switch (answer.question.questionType) {
                case 'singleline':
                  return (
                    <TextQuestionAnswer
                      key={index}
                      answer={answer}
                      index={index}
                      showAnswer={showAnswer}
                      showCandidateAnswers={showCandidateAnswer}
                      showCorrectionButtons={props.showCorrectionButtons}
                      size={props.size}
                    />
                  )
                case 'checkboxes':
                  return (
                    <CheckBoxRenderer
                      key={index}
                      question={answer.question}
                      index={index}
                      showAnswer={showAnswer}
                      showCandidateAnswers={showCandidateAnswer}
                      size={props.size}
                    />
                  )
                case 'multiplechoices':
                  return (
                    <MultiLineRenderer
                      key={index}
                      question={answer.question}
                      index={index}
                      showAnswer={showAnswer}
                      showCandidateAnswers={showCandidateAnswer}
                      size={props.size}
                    />
                  )
                default:
                  return ''
              }
            })}
          </Grid>
        </Grid>
      )
    )
  }
  const WidgetInfo = ({ title, value, color }) => (
    <Box
      pt={1}
      style={{
        color: color
      }}>
      <Typography variant="subtitle2">
        {' '}
        {title} {value}
      </Typography>
    </Box>
  )
  return (
    <Grid container justify="center" alignContent="center" spacing={1}>
      <Grid item md={2} xs={12}>
        <Box p={3} component={Paper}>
          <Typography variant="h6">Dados Candidato</Typography>
          <WidgetInfo title="Avaliado:" value={candidateAssessment.candidate.fullName} />
        </Box>
      </Grid>
      <Grid container item md={8} xs={12} spacing={1}>
        <Grid item md={12} xs={12}>
          <Box p={3} component={Paper}>
            <Typography variant="h4">
              {assessment.title}
              <IconButton color="primary" onClick={() => window.print()} title=" Salvar Como PDF">
                <Icon>cloud_download</Icon>
              </IconButton>
            </Typography>
            <Typography variant="body1">{assessment.subtitle}</Typography>
          </Box>{' '}
        </Grid>{' '}
        <Grid item md={12} xs={12}>
          <Box p={1} component={Paper}>
            <QuestionsRenderer
              showCandidateAnswerSelector={
                candidateAssessment.answers && candidateAssessment.answers.length > 0
              }
              showCorrectionButtons={props.showCorrectionButtons}
              candidateAssessment={candidateAssessment}
              size={assessment.questions && assessment.questions.length}
            />{' '}
          </Box>
        </Grid>
      </Grid>
      <Grid item md={2} xs={12} alignContent="flex-start" alignItems="flex-start">
        <Box p={3} component={Paper}>
          <Typography variant="h6">Dados Teste</Typography>
          <WidgetInfo
            title="Início:"
            value={
              candidateAssessment.startedAt
                ? toLocalDate(candidateAssessment.startedAt, dateFormat)
                : 'Teste ainda não iniciado'
            }
          />
          <WidgetInfo
            title="Término:"
            value={
              candidateAssessment.completedAt
                ? toLocalDate(candidateAssessment.completedAt, dateFormat)
                : 'Teste ainda não concluído'
            }
          />
          <WidgetInfo
            title="Tempo de prova:"
            value={
              candidateAssessment.elapsedTime
                ? toTime(candidateAssessment.elapsedTime)
                : 'Teste ainda não concluído'
            }
          />
          <WidgetInfo
            title="Aproveitamento:"
            value={Number(candidateAssessment.score).toLocaleString(undefined, {
              style: 'percent',
              minimumFractionDigits: 2
            })}
          />
          <hr />
          <WidgetInfo
            title="Questões:"
            value={assessment.questions && assessment.questions.length}
          />
          <WidgetInfo
            title="Respostas:"
            value={candidateAssessment.answers && candidateAssessment.answers.length}
            color="lightseagreen"
          />
          <WidgetInfo
            title="Corretas:"
            value={
              candidateAssessment.answers &&
              candidateAssessment.answers.filter(
                (a) => a.question && a.question.status === 'Correta'
              ).length
            }
            color="green"
          />
          <WidgetInfo
            title="Incorretas:"
            value={
              candidateAssessment.answers &&
              candidateAssessment.answers.filter(
                (a) => a.question && a.question.status === 'Incorreta'
              ).length
            }
            color="red"
          />
          <WidgetInfo
            title=" Parcialmente Corretas:"
            value={
              candidateAssessment.answers &&
              candidateAssessment.answers.filter(
                (a) => a.question && a.question.status === 'Parcialmente Correta'
              ).length
            }
            color="yellowgreen"
          />
          <WidgetInfo
            title="Pendente Correção:"
            value={
              candidateAssessment.answers &&
              candidateAssessment.answers.filter(
                (a) => a.question && a.question.status === 'Pendente correção manual'
              ).length
            }
            color="red"
          />
        </Box>
      </Grid>
    </Grid>
  )
}
