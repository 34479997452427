import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import React, { useContext } from 'react'
import { Link, Link as RouterLink } from 'react-router-dom'
import { formatBytes } from '_utils/tools'
import { ProfileContext } from './Layout/Navigation/Navigation'
import Loading from './Loading'

const UtilizationContainer = () => {
  const profile = useContext(ProfileContext)
  const mappedData = React.useMemo(() => {
    if (profile && profile.tenant && profile.tenant.tenantUtilization) {
      return {
        ...profile.tenant.tenantUtilization
      }
    }
    return []
  }, [profile])

  if (!profile) return <Loading />

  return (
    <Grid item md={12} xs={12}>
      <Card>
        <CardContent>
          {profile && profile.tenant && profile.tenant.trial && !profile.tenant.skipLimits && (
            <Grid item component={Box} pb={2}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                to={'/pricing'}
                size="small"
                component={RouterLink}
                startIcon={<Icon className="blob">alarm</Icon>}>
                {profile && profile.tenant && profile.tenant.remainingDays} Dias Restantes
              </Button>
            </Grid>
          )}
          <Typography variant="body1"> Utilização Plano Atual</Typography>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="right">Utilizado</TableCell>
                  <TableCell align="right">Limite</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Link to={'/tests/results'}> Compartilhamentos</Link>
                  </TableCell>
                  <TableCell align="right">{mappedData.sharedItems}</TableCell>
                  <TableCell align="right">{mappedData.sharedItemsLimit}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Link to={'/tests'}> Testes</Link>
                  </TableCell>
                  <TableCell align="right">{mappedData.tests}</TableCell>
                  <TableCell align="right">{mappedData.testsLimit}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Link to={'#'}> Espaço Usado</Link>
                  </TableCell>
                  <TableCell align="right">{formatBytes(mappedData.storageUsage || 0)}</TableCell>
                  <TableCell align="right">
                    {formatBytes(mappedData.storageUsageLimit || 0)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Link to={profile.admin ? '/users' : '#'}> Usuários</Link>
                  </TableCell>
                  <TableCell align="right">{mappedData.users}</TableCell>
                  <TableCell align="right">{mappedData.usersLimit}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Link to={'/candidates'}> Avaliados</Link>
                  </TableCell>
                  <TableCell align="right">-</TableCell>
                  <TableCell align="right">-</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>{' '}
    </Grid>
  )
}

export default UtilizationContainer
