import React from 'react'
import { Typography, Grid, Breadcrumbs, Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { getHomeByProfile } from 'components/Layout/Navigation/AppToolBar'
import PageHeaderPath from 'components/PageHeaderPath'
import ConnectedCandidateForm from './NewCandidateForm'

const NewCandidatePage = () => {
  document.title = 'Question - Novo Avaliado'
  return (
    <Grid container justify="center">
      <PageHeaderPath>
        <Breadcrumbs separator="›" aria-label="Breadcrumb">
          <Link to={getHomeByProfile()} component={RouterLink}>
            Home
          </Link>
          <Link to={'/candidates'} component={RouterLink}>
            Avaliados
          </Link>
          <Typography>Novo Avaliado</Typography>
        </Breadcrumbs>
      </PageHeaderPath>
      <Grid item md={3}>
        <ConnectedCandidateForm />
      </Grid>
    </Grid>
  )
}

export default NewCandidatePage
