import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { userService } from '_services/user.service'
import Copyright from '../Footer/Copyright'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const Redir = (props) => {
    if (props.location.pathname.indexOf('/subscription/selection') !== -1) {
      return (
        <Redirect
          to={'/signup?forwardUrl=' + props.location.pathname + props.location.search}
          from={props.location.pathname + props.location.search}
        />
      )
    } else {
      return (
        <Redirect
          to={'/login?forwardUrl=' + props.location.pathname + props.location.search}
          from={props.location.pathname + props.location.search}
        />
      )
    }
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        userService.isLogged() ? (
          <>
            <Component {...props} /> <Copyright />
          </>
        ) : (
          props.location && <Redir {...props} />
        )
      }
    />
  )
}
