import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Typography, Grid, Breadcrumbs, Chip, Link, CircularProgress } from '@material-ui/core'
import history from '_utils/history'
import MaterialTable from 'material-table'
import { getHomeByProfile } from 'components/Layout/Navigation/AppToolBar'
import { tableLocalization, pageSize } from '_utils/appConstants'
import PageHeaderPath from 'components/PageHeaderPath'
import { GET_CANDIDATE_APPLICATIONS } from '_queries/CANDIDATES'
import { useQuery } from '@apollo/client'
function MyTestsPage(props) {
  document.title = 'Question - Meus Testes'
  const { data, loading } = useQuery(GET_CANDIDATE_APPLICATIONS)
  return (
    <Grid container direction="row" justify="center" alignContent="center" alignItems="center">
      <PageHeaderPath>
        <Breadcrumbs separator="›" aria-label="Breadcrumb">
          <Link to={getHomeByProfile()} component={RouterLink}>
            Home
          </Link>
          <Typography color="textPrimary"> Meus Testes</Typography>
        </Breadcrumbs>
      </PageHeaderPath>
      <Grid item lg={10} xs={12}>
        {loading ? (
          <Grid
            container
            direction="row"
            justify="center"
            alignContent="center"
            alignItems="center">
            <CircularProgress />
          </Grid>
        ) : (
          <MaterialTable
            options={{
              pageSize: pageSize,
              debounceInterval: 500
            }}
            localization={tableLocalization}
            columns={[
              {
                title: 'Código Execução',
                field: 'id'
              },
              {
                title: 'Código Teste',
                field: 'assessment.id'
              },
              { title: 'Teste', field: 'assessment.title' },

              {
                title: 'Ações',
                field: 'actions',
                sorting: false,
                render: (rowData) =>
                  rowData.config.executionEnabled ? (
                    <Chip
                      onClick={() => {
                        history.push('/candidate/tests/execute/' + rowData.id)
                      }}
                      label="Acessar Teste"
                    />
                  ) : (
                    <span>
                      Teste Bloqueado
                      {rowData.expired && <Chip onClick={() => {}} label="Solicitar Re-teste" />}
                    </span>
                  )
              }
            ]}
            data={(data && data.myAssessments) || []}
            title="Testes Atribuídos a você"
          />
        )}
      </Grid>
    </Grid>
  )
}

export default MyTestsPage
