import gql from 'graphql-tag'

export const GET_ME = gql`
  query me {
    me {
      id
      fullName
      email
      avatarUrl
      acceptedShareData
      acceptedTACandPP
      admin
      tenant {
        id
        trial
        remainingDays
        organization
        document
        documentType
        contactPhone
        skipLimits
        owner {
          fullName
          email
        }
        subscription {
          uuid
          plan {
            id
            name
            tier
            externalPlanId
          }
        }
        tenantUtilization {
          users
          usersLimit
          sharedItems
          sharedItemsLimit
          tests
          testsLimit
          storageUsage
          storageUsageLimit
        }
      }
      roles {
        name
        label
      }
    }
  }
`
export const UPDATE_PROFILE = gql`
  mutation UPDATE_PROFILE($request: UpdateProfileRequestInput!) {
    updateUserProfile(request: $request) {
      id
      fullName
      email
      tenant {
        id
        organization
        documentType
        document
        owner {
          fullName
          email
        }
        contactPhone
      }
    }
  }
`
