import { useMutation, useQuery } from '@apollo/client'
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Container,
  Grid,
  Icon,
  Link,
  Typography
} from '@material-ui/core'
import HelpButton from 'components/HelpButton'
import { CustomSearchField } from 'components/Layout/FormFields'
import { getHomeByProfile } from 'components/Layout/Navigation/AppToolBar'
import Loading from 'components/Loading'
import EmptyMessage from 'components/Messaging/EmptyMessage'
import PageHeaderPath from 'components/PageHeaderPath'
import SharingDialog from 'pages/Tests/SharingDialog'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { DELETE_ASSESSMENT, DUPLICATE_ASSESSMENT, GET_ASSESSMENTS } from '_queries/ASSESSMENTS'
import { handleApolloErrors } from '_services/apollo'
import { msgs } from '_utils/appConstants'
import config from '_utils/config'
import TestListAccordeon from './List/TestListAccordeon'
import { TestListGrid } from './List/TestListGrid'

function TestsPage() {
  document.title = 'Question - Teste'
  const { data, loading } = useQuery(GET_ASSESSMENTS, {
    name: 'allAssessments',
    onError: (error) => handleApolloErrors(error)
  })
  const [deleteAssessment] = useMutation(DELETE_ASSESSMENT, {
    name: 'deleteAssessment',
    onError: (error) => handleApolloErrors(error),
    onCompleted: (results) => toast.success(msgs.operationSuccess),
    update: (cache, { data: { deleteAssessment } }) => {
      cache.modify({
        fields: {
          allAssessments(existingAllAssessmentsRef = [], { readField }) {
            return existingAllAssessmentsRef.filter(
              (assessmentRef) => deleteAssessment !== readField('id', assessmentRef)
            )
          }
        }
      })
    }
  })
  const [duplicateAssessment] = useMutation(DUPLICATE_ASSESSMENT, {
    name: 'duplicateAssessment',
    onError: (error) => handleApolloErrors(error),
    onCompleted: (results) => toast.success(msgs.operationSuccess),
    update: (cache, { data: { duplicateAssessment } }) => {
      cache.modify({
        fields: {
          allAssessments(existingAllAssessmentsRef = [], { readField }) {
            if (
              existingAllAssessmentsRef.some(
                (ref) => readField('id', ref) === duplicateAssessment.id
              )
            ) {
              return existingAllAssessmentsRef
            }

            return [...existingAllAssessmentsRef, duplicateAssessment]
          }
        }
      })
    }
  })
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [shareWith, setAssessment] = React.useState(null)
  const [filter, setFilter] = React.useState('')
  const [viewMode, setViewMode] = React.useState(localStorage.getItem('testViewMode') || 'list')

  const [_data, setData] = React.useState((data && data.allAssessments) || [])
  React.useEffect(() => {
    if (filter) {
      setData(
        data && data.allAssessments.filter((item) => JSON.stringify(item).indexOf(filter) !== -1)
      )
    } else {
      setData(data && data.allAssessments)
    }
    if (viewMode) {
      localStorage.setItem('testViewMode', viewMode)
    }
  }, [filter, data, viewMode])
  return loading ? (
    <Loading />
  ) : (
    <Grid item lg={12} xs={12}>
      <PageHeaderPath>
        <Breadcrumbs separator="›" aria-label="Breadcrumb">
          <Link to={getHomeByProfile()} component={RouterLink}>
            Home
          </Link>
          <Typography>Testes</Typography>
          {/* <Link to="/manage/tests/new" component={RouterLink}>
              <Button size="small" disableElevation startIcon={<Icon>add</Icon>}>
                Criar Teste
              </Button>
            </Link>{' '} */}
        </Breadcrumbs>{' '}
        <HelpButton link={config.tutorials.TUTORIAL_TEST_PAGE} />
      </PageHeaderPath>{' '}
      <Container maxWidth="lg">
        <Grid container justify="center">
          {!filter && _data && _data.length === 0 ? (
            <EmptyMessage
              id="empty_test_message"
              title="Nenhum teste encontrado"
              subtitle="Começe criando um novo teste através do botão abaixo"
              action={
                <Link to="/manage/tests/new" component={RouterLink} id="new_test_btn">
                  <Button startIcon={<Icon>add</Icon>}>Novo Teste</Button>
                </Link>
              }
            />
          ) : (
            <Grid container md={12} xs={12} direction="row-reverse" alignItems="center">
              <Grid container item md={4} justify="flex-end" component={Box} pl={3}>
                <ButtonGroup disableElevation variant="outlined" color="primary">
                  <Button
                    startIcon={<Icon>view_list</Icon>}
                    variant={viewMode === 'list' && 'contained'}
                    onClick={() => setViewMode('list')}>
                    Lista
                  </Button>
                  <Button
                    startIcon={<Icon>grid_view</Icon>}
                    variant={viewMode === 'grid' && 'contained'}
                    onClick={() => setViewMode('grid')}>
                    Grid
                  </Button>{' '}
                </ButtonGroup>
              </Grid>
              <Grid item xs={12} md={8}>
                <CustomSearchField
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  onClear={() => setFilter('')}
                />
              </Grid>
              {_data && viewMode === 'list' && (
                <TestListAccordeon
                  items={_data}
                  filtering={!!filter}
                  onArchive={(assessment) => handleArchive(assessment, deleteAssessment)}
                  duplicateAssessment={(assessmentId) => duplicateAssessment(assessmentId)}
                  onShare={(assessment) => {
                    setAssessment(assessment)
                    setDialogOpen(true)
                  }}
                />
              )}{' '}
              {_data && viewMode === 'grid' && (
                <TestListGrid
                  items={_data}
                  filtering={!!filter}
                  onArchive={(assessment) => handleArchive(assessment, deleteAssessment)}
                  duplicateAssessment={(assessmentId) => duplicateAssessment(assessmentId)}
                  onShare={(assessment) => {
                    setAssessment(assessment)
                    setDialogOpen(true)
                  }}
                />
              )}
              <SharingDialog
                dialogOpen={dialogOpen}
                onDialog={(state) => setDialogOpen(state)}
                assessment={shareWith}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Grid>
  )
}
function handleArchive(assessment, callback) {
  Swal.fire({
    title: 'Tem certeza que deseja arquivar este teste?',
    text:
      'Os resultados associados a este teste não aparecerão mais na tela de resultados, também não será possível compartilhar após o arquivamento',
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#FE5000',
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Sim, arquivar teste!'
  }).then((result) => {
    if (result.value) {
      callback({ variables: { id: assessment.id } })
    }
  })
}
export default TestsPage
