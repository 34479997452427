import React from 'react'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import Typography from '@material-ui/core/Typography'
import { toLocalDate2 } from '_utils/moment'

export default function OppositeContentTimeline({ events }) {
  return events ? (
    <React.Fragment>
      <Timeline align="left">
        {events.map(({ createdAt, description }, index) => {
          return (
            <TimelineItem key={index} style={{ minHeight: 'inherit' }}>
              <TimelineOppositeContent style={{ flex: 'inherit' }}>
                <Typography color="textSecondary"> {toLocalDate2(createdAt)}</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: description
                    }}></span>
                </Typography>
              </TimelineContent>
            </TimelineItem>
          )
        })}
      </Timeline>
    </React.Fragment>
  ) : (
    ''
  )
}
