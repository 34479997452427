import { useQuery } from '@apollo/client'
import { Grid } from '@material-ui/core'
import Loading from 'components/Loading'
import EmptyMessage from 'components/Messaging/EmptyMessage'
import gql from 'graphql-tag'
import React from 'react'
import TestVisualizationForm from './TestVisualizationForm'

function SimpleTestResultVisualizationPage(props) {
  document.title = 'Question - Visualização de Resultado'
  const { data, loading } = useQuery(GET_CANDIDATE_ASSESSMENT, {
    fetchPolicy: 'no-cache',
    variables: {
      id: props.match.params.id
    }
  })
  return loading ? (
    <Loading />
  ) : data && data.candidateResult ? (
    <Grid direction="row" container alignItems="baseline" justify="center">
      <TestVisualizationForm candidateAssessment={data && data.candidateResult} />
    </Grid>
  ) : (
    <Grid direction="row" container alignItems="baseline" justify="center">
      <EmptyMessage title="Não encontrado" />
    </Grid>
  )
}

const GET_CANDIDATE_ASSESSMENT = gql`
  query candidateResult($id: Long!) {
    candidateResult(id: $id) {
      id
      completedAt
      startedAt
      elapsedTime
      score
      answers {
        id
        score
        ... on TextAnswer {
          answerText
        }
        question {
          text
          questionType
          status
          choices {
            id
            text
            score
            candidateResponse
          }
        }
      }
      assessment {
        title
        subtitle
        questions {
          id
        }
      }
      candidate {
        fullName
      }
    }
  }
`
export default SimpleTestResultVisualizationPage
