import { Grid } from '@material-ui/core'
import EmptyMessage from 'components/Messaging/EmptyMessage'
import React from 'react'
import { Link } from 'react-router-dom'
class NotFoundPage extends React.Component {
  render() {
    return (
      <Grid container justify="center" alignContent="center" style={{ height: '100vh' }}>
        <EmptyMessage
          title="Ooops!"
          subtitle="A página que você está procurando não pode ser encontrada"
          action={<Link to="/login">Voltar para Login</Link>}
        />
      </Grid>
    )
  }
}
export default NotFoundPage
