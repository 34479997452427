import React from 'react'
import { Typography, Grid, Link, Breadcrumbs } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import gql from 'graphql-tag'
import { getHomeByProfile } from 'components/Layout/Navigation/AppToolBar'
import PageHeaderPath from 'components/PageHeaderPath'
import { ViewCandidateForm } from './ViewCandidateForm'
import { useQuery } from '@apollo/client'
import Loading from 'components/Loading'

function ViewCandidatePage(props) {
  document.title = 'Question - Visualização de Avaliado'
  const { data, loading } = useQuery(GET_CANDIDATE, {
    fetchPolicy: 'no-cache',
    variables: {
      id: props.match.params.id
    }
  })
  return loading ? (
    <Loading />
  ) : (
    <Grid container direction="row" justify="center" alignItems="center">
      <PageHeaderPath>
        <Breadcrumbs separator="›" aria-label="Breadcrumb">
          <Link to={getHomeByProfile()} component={RouterLink}>
            Home
          </Link>
          <Link to={'/candidates'} component={RouterLink}>
            Avaliados
          </Link>
          <Typography color="textPrimary"> Visualização</Typography>
        </Breadcrumbs>
      </PageHeaderPath>
      <Grid item lg={12} xs={12}>
        <ViewCandidateForm candidate={data && data.candidate} />
      </Grid>
    </Grid>
  )
}

const GET_CANDIDATE = gql`
  query GET_CANDIDATE($id: Long!) {
    candidate(id: $id) {
      id
      fullName
      createdAt
      email
      mobile
      candidateAssessments {
        id
        assessment {
          title
        }
        score
        executionLength
        startedAt
        executionCount
        completedAt
      }
    }
  }
`

export default ViewCandidatePage
