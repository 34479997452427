import React from 'react'
import { Grid } from '@material-ui/core'
import EmptyMessage from 'components/Messaging/EmptyMessage'
import { Link } from 'react-router-dom'
import * as userActions from '_actions/user.actions'

export default function LogoutPage() {
  userActions.logout()

  return (
    <Grid container justify="center" alignContent="center">
      <EmptyMessage
        title="Sucesso!"
        subtitle="Sua sessão foi finalizada, você pode fechar esta aba ou retornar a página de login"
        action={<Link to="/login">Voltar para Login</Link>}
      />
    </Grid>
  )
}
