import React from 'react'
import NavigationWrapper from 'components/Layout/Navigation/Navigation'

export default function withNavBar(WrappedComponent, appBarProps) {
  return class extends React.Component {
    render() {
      return (
        <NavigationWrapper {...appBarProps}>
          <WrappedComponent />
        </NavigationWrapper>
      )
    }
  }
}
