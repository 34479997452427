import {
  Button,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { HtmlEditor } from 'components/Editor'
import { CustomSelectInput } from 'components/Layout/FormFields'
import { HTMLQuestionTitle } from 'components/Layout/HTMLQuestionTitle'
import Loading from 'components/Loading'
import { Field, FieldArray, Form, Formik } from 'formik'
import React from 'react'
import history from '_utils/history'
import { CheckBoxQuestion, MultipleChoiceQuestion } from './QuestionTypes/QuestionTypes'
export const letters = 'abcdefghijklmnopqrstuvxzw'

const buttonGroupStyle = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}))
let currentPanel = ''
const questionTypesList = [
  {
    label: 'Múltipla Escolha - Apenas uma opção correta',
    id: 'multiplechoices'
  },
  {
    label: 'Caixas de Seleção - Mais de uma resposta',
    id: 'checkboxes'
  },
  {
    label: 'Resposta em texto simples',
    id: 'singleline'
  }
]

export const FriendList = () => (
  <div>
    <h1>Question List</h1>
    <Formik
      initialValues={{ questions: [] }}
      render={(formikProps) => <FieldArray name="questions" component={MyDynamicForm} />}
    />
  </div>
)

export const MyDynamicForm = ({ move, swap, push, insert, unshift, pop, form, remove }) => {
  const { questions } = form.values
  return (
    <Form>
      {questions.map((q, index) => {
        return (
          <Grid item md={12} sm={12} style={{ marginBottom: '0.5rem' }} key={index}>
            <Accordion>
              <Grid item container>
                <Grid item md={1}>
                  <IconButton title="Editar">
                    <Icon>edit</Icon>
                  </IconButton>
                  <IconButton
                    title="Remover"
                    color="primary"
                    onClick={() => {
                      remove(index)
                    }}>
                    <Icon>delete_outline</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            </Accordion>
          </Grid>
        )
      })}

      <Button
        data-tut="reactour__new_question_btn"
        color="default"
        type="button"
        startIcon={<Icon>add</Icon>}
        onClick={() => {
          push({
            questionType: 'multiplechoices',
            choices: [{ score: 1 }, {}, {}]
          })
        }}>
        Adicionar
      </Button>
    </Form>
  )
}
function QuestionArrayComponent(props) {
  const { values, handleChange, isSubmitting, setFieldValue, handleSubmit } = props
  const [expanded, setExpanded] = React.useState(null)
  const [dragging, setDragging] = React.useState(false)
  const [draggingOver, setDraggingOver] = React.useState(-1)
  const setExpan = (arg) => {
    if (currentPanel === arg) {
      setExpanded(null)
      currentPanel = null
    } else {
      currentPanel = arg
      setExpanded(arg)
    }
  }
  const classes = buttonGroupStyle()
  const onDragStart = (ev, index) => {
    setDragging(true)
    ev.dataTransfer.setData('id', index)
  }
  const onDragOver = (ev, target) => {
    ev.preventDefault()
    let originalId = ev.dataTransfer.getData('id')
    if (parseInt(originalId) !== target) {
      setDraggingOver(target)
    }
  }

  const onDragEnd = (e) => {
    setDragging(false)
    setDraggingOver(-1)
  }
  const onDrop = (ev, indexB, move, questions) => {
    let id = ev.dataTransfer.getData('id')
    if (id !== indexB) {
      move(id, indexB)
    }
  }
  React.useEffect(() => {}, [dragging, draggingOver])
  return (
    <Form onSubmit={handleSubmit}>
      <Typography variant="h5" gutterBottom>
        Perguntas
      </Typography>
      <FieldArray
        name="questions"
        render={(fieldArrayProps) => (
          <Grid item md={12} style={{ backgroundColor: dragging ? 'lightgray' : '' }}>
            {values &&
              values.questions.length > 0 &&
              values.questions.map((question, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    md={12}
                    sm={12}
                    style={{
                      marginBottom: '0.5rem',
                      borderStyle: draggingOver === index ? 'dashed' : ''
                    }}>
                    <Accordion
                      expanded={expanded === 'panel' + index}
                      onDrop={(e) => onDrop(e, index, fieldArrayProps.move, values.questions)}
                      onDragEnd={(e) => onDragEnd(e)}
                      onDragOver={(e) => onDragOver(e, index)}>
                      <Grid item container>
                        <Grid item md={1}>
                          {expanded !== 'panel' + index && (
                            <IconButton
                              title="Mover Questão"
                              onDragStart={(e) => onDragStart(e, index)}
                              draggable={expanded !== 'panel' + index}>
                              <Icon>drag_indicator</Icon>
                            </IconButton>
                          )}
                          <IconButton
                            title="Editar"
                            onClick={() => setExpan('panel' + index || false)}>
                            <Icon>edit</Icon>
                          </IconButton>
                          <IconButton
                            title="Remover"
                            color="primary"
                            onClick={() => {
                              fieldArrayProps.remove(index)
                              props.onQuestionRemoved(question)
                            }}>
                            <Icon>delete_outline</Icon>
                          </IconButton>
                        </Grid>
                        <Grid item md={11}>
                          <AccordionSummary
                            onClick={() => setExpan('panel' + index || false)}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={'panel' + index + '-content'}
                            id={'panel' + index + '-header'}>
                            <Grid item container alignItems="center" md={12}>
                              <HTMLQuestionTitle
                                current={index + 1}
                                text={expanded !== 'panel' + index && question.text}
                                size={values.questions.length}
                              />
                            </Grid>
                          </AccordionSummary>
                        </Grid>
                      </Grid>
                      <AccordionDetails>
                        <Grid container direction="row" spacing={1}>
                          <Grid item md={12} sm={12} xs={12}>
                            <Field
                              data-tut="reactour__new_question_text"
                              autofocus={true}
                              name={`questions[${index}].text`}
                              value={question.text}
                              onChange={(name, value) => {
                                setFieldValue(name, value)
                              }}
                              tenantId={
                                values.tenantId ||
                                (JSON.parse(sessionStorage.getItem('me')).tenant &&
                                  JSON.parse(sessionStorage.getItem('me')).tenant.id)
                              }
                              toolbar={true}
                              placeholder={
                                'Escreva aqui uma das perguntas de seu teste, você pode incluir imagens, vídeos inclusive áudio através da opção inserir arquivo'
                              }
                              component={HtmlEditor}
                            />
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Field
                              data-tut="reactour__question_type"
                              component={CustomSelectInput}
                              name={`questions[${index}].questionType`}
                              label="Tipo de resposta"
                              onChange={(e) => {
                                question.choices &&
                                  question.choices.forEach((c, _index) => {
                                    c.score = 0
                                  })
                                handleChange(e)
                              }}
                              options={questionTypesList}
                            />
                          </Grid>
                          {question.questionType === 'multiplechoices' &&
                            MultipleChoiceQuestion(index, question, setFieldValue)}
                          {question.questionType === 'checkboxes' && (
                            <CheckBoxQuestion
                              index={index}
                              question={question}
                              setFieldValue={setFieldValue}
                            />
                          )}
                          {question.questionType === 'singleline' && 'Resposta do Avaliado'}
                        </Grid>
                      </AccordionDetails>
                      <Divider />
                    </Accordion>
                  </Grid>
                )
              })}
            <Button
              data-tut="reactour__new_question_btn"
              color="default"
              type="button"
              startIcon={<Icon>add</Icon>}
              onClick={() => {
                fieldArrayProps.push({
                  questionType: 'multiplechoices',
                  choices: [{ score: 1 }, {}, {}]
                })
                setExpan('panel' + values.questions.length)
              }}>
              Adicionar Pergunta
            </Button>
          </Grid>
        )}
      />
      <Grid
        item
        md={12}
        style={{
          textAlign: 'center',
          margin: '1rem'
        }}>
        {isSubmitting || props.loading ? (
          <Loading />
        ) : (
          <Grid container justify="center">
            <div className={classes.root}>
              <Button
                id="exit_btn"
                color="default"
                startIcon={<Icon>arrow_back</Icon>}
                type="button"
                onClick={() => {
                  history.push('/tests')
                }}>
                Voltar
              </Button>

              {values.questions.length > 0 && (
                <Button
                  id="save_questions_btn"
                  color="primary"
                  type="submit"
                  startIcon={<Icon>save</Icon>}>
                  Salvar Questionário
                </Button>
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </Form>
  )
}

export default QuestionArrayComponent
