import gql from 'graphql-tag'
export const SHARING_LAST_30D = gql`
  query {
    sharingLast30Days {
      created
      quantity
    }
  }
`


