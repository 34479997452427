import { toast } from "react-toastify";
export const alertActions = {
  error,
  success,
};
var position = toast.POSITION.TOP_CENTER;
function error(message) {
  return (dispatch) => {
    setTimeout(() => {
      toast.error(message, {
        position,
      });
    }, 300);
  };
}
function success(message) {
  return (dispatch) => {
    setTimeout(() => {
      toast.success(message, {
        position,
      });
    }, 300);
  };
}
