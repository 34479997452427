import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MobileStepper from '@material-ui/core/MobileStepper'
import { Fab, Icon, Button } from '@material-ui/core'
const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: 'white'
  },
  fabs: {
    color: 'white'
  }
})

export const ProgressMobileStepper = (props) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(props.activeStep)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    props.handleChange && props.handleChange(activeStep + 2)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    props.handleChange && props.handleChange(activeStep)
  }

  return (
    <MobileStepper
      variant={props.lastStep ? 'text' : 'progress'}
      steps={props.steps}
      position="static"
      activeStep={activeStep}
      className={classes.root}
      nextButton={
        props.lastStep ? (
          <Button
            className={classes.fabs}
            size="large"
            type="submit"
            startIcon={<Icon>save</Icon>}>
            Concluir
          </Button>
        ) : (
          <Fab
            title="Próximo"
            onClick={handleNext}
            disabled={activeStep === props.steps - 1 || !props.enableNext}
            color="secondary"
            aria-label="add"
            className={classes.fabs}>
            <Icon>forward</Icon>
          </Fab>
        )
      }
      backButton={
        <Fab
          title="Voltar"
          onClick={handleBack}
          disabled={activeStep === 0 || !props.enablePrevious}
          color="secondary"
          aria-label="add"
          className={classes.fabs}>
          <Icon style={{ transform: 'rotate(180deg)' }}>forward</Icon>
        </Fab>
      }
    />
  )
}

export default ProgressMobileStepper
