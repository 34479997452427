import { useMutation, useQuery } from '@apollo/client'
import { Breadcrumbs, Grid, Link, Typography } from '@material-ui/core'
import { getHomeByProfile } from 'components/Layout/Navigation/AppToolBar'
import Loading from 'components/Loading'
import PageHeaderPath from 'components/PageHeaderPath'
import gql from 'graphql-tag'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { handleApolloErrors } from '_services/apollo'
import EditUserForm from './EditUserForm'

export function EditUserPage(props) {
  document.title = 'Question - Edição Usuário'
  const [updateUser] = useMutation(UPDATE_USER, {
    name: 'updateUser'
  })
  const { data, loading } = useQuery(GET_USER, {
    variables: {
      id: props.match.params.id
    },
    onError: (error) => {
      handleApolloErrors(error)
      window.location.href = '/users'
    }
  })
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <PageHeaderPath>
        <Breadcrumbs separator="›" aria-label="Breadcrumb">
          <Link to={getHomeByProfile()} component={RouterLink}>
            Home
          </Link>
          <Link to={'/users'} component={RouterLink}>
            Usuários
          </Link>
          <Typography color="textPrimary"> Edição</Typography>
        </Breadcrumbs>
      </PageHeaderPath>
      <Grid item md={4}>
        {loading ? <Loading /> : <EditUserForm user={data?.user} onUserUpdate={updateUser} />}
      </Grid>
    </Grid>
  )
}

const GET_USER = gql`
  query GET_USER($id: Long!) {
    user(id: $id) {
      id
      fullName
      email
      roles {
        id
        name
        label
      }
    }
  }
`

const UPDATE_USER = gql`
  mutation UPDATE_USER($request: UserEditRequestInput!) {
    updateUser2(request: $request) {
      id
      fullName
      email
      roles {
        label
      }
    }
  }
`

export default EditUserPage
