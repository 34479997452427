import { Box, Card, Icon, Tooltip, Typography } from '@material-ui/core'
import Loading from 'components/Loading'
import useEventsHook from 'hooks/useEventsHook'
import React from 'react'
import OppositeContentTimeline from './OpposedContentTimeline'

export default function EventsContainer() {
  const eventsNumber = 15
  const { loading, error, data } = useEventsHook(eventsNumber)
  if (loading) return <Loading />
  if (error) return `Erro! ${error.message}`
  return (
    // <Grid item lg={4} md={6}>
    <Card component={Box} p={3}>
      <Typography variant="body1">
        Últimos {eventsNumber} eventos
        <Tooltip title="Eventos que aconteceram em sua conta de forma geral.">
          <Icon fontSize="small" color="disabled">
            help
          </Icon>
        </Tooltip>
      </Typography>
      <OppositeContentTimeline events={data && data.lastEvents} />
    </Card>
    // </Grid>
  )
}
