import pt_br_moment from 'moment/locale/pt-br'
import moment from 'moment-timezone'
import mm from 'moment'

const languages = new Map([['pt-br', pt_br_moment]])
export const dateFormat = 'DD MMM HH:mm:ss'
moment.locale(window.navigator.language, languages.get(window.navigator.language))
export default moment

export function toMoment(dateTime) {
  return moment(dateTime)
}
// function extractDtTzFromTS(timestamp) {
//   let dt = timestamp.split('[')[0]
//   return { tz, dt }
// }
export function toLocalDate(timestamp, format) {
  return moment.utc(timestamp, 'YYYY-MM-DD hh:mm:ss A').format(format || 'DD MMM HH:mm')
}

export function toLocalDate2(timestamp) {
  var options = { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }
  var date = new Date(timestamp)
  return date.toLocaleDateString('pt-br', options)
}

export function toLocalDate3(timestamp, format) {
  var m = moment.utc(timestamp, 'YYYY-MM-DD hh:mm:ss A')
  var userTimeZoneOffset = new Date().getTimezoneOffset()
  var localOffset = moment(m.clone().utcOffset(-userTimeZoneOffset))
  return localOffset.format(format || dateFormat)
}

// export function toLocalDateObj(timestamp, format) {
//   const { dt } = extractDtTzFromTS(timestamp)
//   return moment(dt, format).tz(tz)
// }
export function toTime(milliseconds) {
  return mm.utc(milliseconds).format('HH:mm:ss')
}
export function toTimeFormat(time) {
  return moment.duration(time, 'seconds').format('HH:mm')
}
export function durationBetweenTimes(start, end) {
  return moment.duration(toTimeFormat(end).diff(toTimeFormat(start)))
}

export function fromNow(dateTime) {
  return moment(dateTime).local().fromNow()
}
