import { Box, Typography } from '@material-ui/core'
import FormHeading from 'components/Layout/FormHeading'
import EmptyMessage from 'components/Messaging/EmptyMessage'
import MaterialTable from 'material-table'
import React from 'react'
import { masks, tableLocalization } from '_utils/appConstants'
import history from '_utils/history'
import moment, { toLocalDate } from '_utils/moment'

export const ViewCandidateForm = ({ candidate }) => {
  return candidate ? (
    <Box>
      <Box p={3}>
        <FormHeading>Detalhes do Avaliado </FormHeading>
        <Box style={{ textAlign: 'center' }}>
          <Typography variant="h5"> {candidate.fullName}</Typography>
          {candidate.fullName !== candidate.email && (
            <Typography variant="subtitle"> {candidate.email}</Typography>
          )}
          <Typography variant="subtitle1"> {candidate.mobile}</Typography>
          <Typography variant="subtitle1">
            Criação: {candidate.createdAt && toLocalDate(candidate.createdAt)}
          </Typography>
        </Box>
        {!candidate.candidateAssessments || candidate.candidateAssessments.length === 0 ? (
          <EmptyMessage>Nenhum Teste Associado</EmptyMessage>
        ) : (
          <MaterialTable
            localization={tableLocalization}
            title={'Testes Associados'}
            options={{
              grouping: true,
              sorting: true,
              exportButton: true,
              padding: 'dense',
              debounceInterval: 500
            }}
            columns={[
              {
                title: 'Execução',
                field: 'id',
                type: 'numeric',
                grouping: false
              },
              {
                tooltip: (rowData) => rowData.assessment.title,
                title: 'Teste',
                field: 'assessment.title',
                type: 'string'
              },
              {
                title: 'Pontuação',
                field: 'score',
                type: 'numeric',
                grouping: false,
                render: (rowData) =>
                  Number(rowData.score).toLocaleString(undefined, {
                    style: 'percent',
                    minimumFractionDigits: 2
                  })
              },
              {
                title: 'Início',
                field: 'startedAt',
                grouping: false,
                type: 'datetime',
                render: (rowData) => rowData.startedAt && toLocalDate(rowData.startedAt)
              },
              {
                title: 'Conclusão',
                field: 'completedAt',
                grouping: false,
                type: 'datetime',
                render: (rowData) => rowData.completedAt && toLocalDate(rowData.completedAt)
              },
              {
                title: 'Tempo',
                type: 'datetime',
                field: 'executionLength',
                grouping: true,
                render: (rowData) => {
                  return moment.utc(rowData.executionLength).format(masks.time)
                }
              },
              {
                title: 'Tentativas',
                field: 'executionCount',
                type: 'numeric',
                grouping: true
              }
            ]}
            actions={[
              (rowData) => ({
                icon: 'visibility',
                tooltip: 'Visualizar Resultado',
                onClick: (event) => {
                  history.push('/tests/results/' + rowData.id)
                },
                hidden: rowData.completedAt === null
              })
            ]}
            data={candidate.candidateAssessments}
          />
        )}
      </Box>
    </Box>
  ) : (
    <span>Não foi possível encontrar o candidato</span>
  )
}
