import React from "react";
import { Typography, Grid, Container } from "@material-ui/core";
import DashboardItem from "components/DashboardItem";

class CandidateHomePage extends React.Component {
  render() {
    return (
      <Container container justify="center" maxWidth="md">
        <Grid item lg={12}>
          <Typography
            component="h5"
            variant="h5"
            style={{ textAlign: "center", margin: `2rem` }}
          >
            Olá, o que você quer fazer hoje?
          </Typography>
          <Grid container lg={12} direction="row" justify="center" spacing={3}>
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <DashboardItem
                to="/candidate/tests"
                title="Meus Testes"
                svgIcon="view_list"
                description="Visualize as vagas e testes relacionados a sua aplicação"
              />
            </Grid>
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <DashboardItem
                to="/profile"
                title="Perfil"
                svgIcon="person"
                description="visualize e edite seus dados"
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default CandidateHomePage;
