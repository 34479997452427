import { userConstants } from "_constants/userConstants";

export function registration(state = {}, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { ...state, loading: true };
    case userConstants.REGISTER_SUCCESS:
      return { ...state, loading: false };
    case userConstants.REGISTER_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}
