import { useMutation, useQuery } from '@apollo/client'
import { Box, Breadcrumbs, Button, ButtonGroup, Grid, Icon, Link, Typography } from '@material-ui/core'
import HelpButton from 'components/HelpButton'
import { getHomeByProfile } from 'components/Layout/Navigation/AppToolBar'
import Loading from 'components/Loading'
import EmptyMessage from 'components/Messaging/EmptyMessage'
import PageHeaderPath from 'components/PageHeaderPath'
import gql from 'graphql-tag'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { autoCloseDelay, msgs } from '_utils/appConstants'
import config from '_utils/config'
import history from '_utils/history'
import { UserListGrid } from './List/UserListGrid'
import UsersTable from './List/UserListTable'

let toastId = null
const notifyProgress = () => (toastId = toast('Processando...', { autoClose: false }))
const success = (result) => {
  console.debug(result)
  toast.update(toastId, {
    render: msgs.operationSuccess,
    type: toast.TYPE.SUCCESS,
    autoClose: autoCloseDelay
  })
}
function error(_exception) {
  console.error(_exception)
  if (_exception.toString().indexOf(':') !== -1) {
    var pieces = _exception.toString().split(':')
    toast.update(toastId, {
      render: pieces[pieces.length - 1],
      type: toast.TYPE.ERROR,
      autoClose: autoCloseDelay
    })
  } else {
    toast.update(toastId, {
      render: msgs.operationError,
      type: toast.TYPE.ERROR,
      autoClose: autoCloseDelay
    })
  }
}
function UsersPage() {
  const [viewMode, setViewMode] = React.useState(localStorage.getItem('usersViewMode') || 'table')

  const { data, loading } = useQuery(GET_USERS)
  const mappedData = React.useMemo(() => {
    if (data) {
      return data.allUsers.map(({ __typename, ...item }) => item)
    }
    return []
  }, [data])
  const emptyResults = data && data.length === 0
  const [deleteUser] = useMutation(DELETE_USER, {
    name: 'deleteUser',
    update: (cache, { data: { deleteUser } }) => {
      cache.modify({
        fields: {
          allUsers(ref, { readField }) {
            return ref.filter((item) => {
              return deleteUser.id !== readField('id', item)
            })
          }
        }
      })
    }
  })
  const [toggleUserAccess] = useMutation(TOGGLE_USER_ACCESS, {
    name: 'toggleUserAccess',
    refetchQueries: [{ query: GET_USERS }]
  })

  React.useEffect(() => {
    if (viewMode) {
      localStorage.setItem('usersViewMode', viewMode)
    }
  }, [viewMode])

  if (loading) {
    return <Loading />
  }
  if (emptyResults) {
    return (
      <Grid container justify="center">
        <EmptyMessage
          title="Nenhum usuário encontrado"
          subtitle="Começe registrando um novo!"
          action={
            <Link to="/new_user" component={RouterLink}>
              <Button startIcon={<Icon>add</Icon>}>Novo Usuário</Button>
            </Link>
          }
        />
      </Grid>
    )
  }

  return (
    <Grid container justify="center">
      <PageHeaderPath>
        <Breadcrumbs separator="›" aria-label="Breadcrumb">
          <Link to={getHomeByProfile()} component={RouterLink}>
            Home
          </Link>
          <Typography>Usuários</Typography>{' '}
          <Link to="/new_user" component={RouterLink}>
            <Button size="small" disableElevation startIcon={<Icon>add</Icon>}>
              Novo Usuário
            </Button>
          </Link>
        </Breadcrumbs>{' '}
        <HelpButton link={config.tutorials.TUTORIAL_USER_PAGE} />
      </PageHeaderPath>
      <Grid container justify="flex-end" md={12}>
        <Box pb={2}>
          <ButtonGroup disableElevation variant="outlined" color="primary">
            <Button
              startIcon={<Icon>table_view</Icon>}
              variant={viewMode === 'table' && 'contained'}
              onClick={() => setViewMode('table')}>
              Tabela
            </Button>
            <Button
              startIcon={<Icon>grid_view</Icon>}
              variant={viewMode === 'grid' && 'contained'}
              onClick={() => setViewMode('grid')}>
              Grid
            </Button>{' '}
          </ButtonGroup>
        </Box>
      </Grid>
      <Grid item lg={12} xs={12}>
        {viewMode === 'table' && (
          <UsersTable
            data={mappedData}
            loading={loading}
            onToggleUserAccess={toggleUserAccess}
            onDelete={deleteUser}
          />
        )}{' '}
        {viewMode === 'grid' && (
          <UserListGrid
            data={mappedData}
            loading={loading}
            onEdit={(user) => history.push(`/users/edit/${user.id}`)}
            onToggleUserAccess={(user) =>
              toggleUserAccess({
                variables: { id: user.id }
              })
            }
            onDelete={(user) => {
              Swal.fire({
                title: 'Tem certeza que deseja remover este usuário?',
                text: 'Não será possível desfazer está ação!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#FE5000',
                cancelButtonColor: '#979696',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim, remover usuário!'
              }).then((result) => {
                if (result.value) {
                  notifyProgress()
                  deleteUser({
                    variables: { id: user.id }
                  })
                    .then((results) => {
                      results.data.deleteUser
                        ? success(msgs.operationSuccess)
                        : error(msgs.operationError)
                    })
                    .catch((exception) => {
                      error(exception)
                    })
                }
              })
            }}
          />
        )}
      </Grid>
    </Grid>
  )
}

const GET_USERS = gql`
  query GET_USERS {
    allUsers {
      id
      fullName
      email
      enabled
      avatarUrl
      createdAt
      updatedAt
      lastLoginAt
      roles {
        label
      }
      tenant {
        organization
      }
    }
  }
`

const DELETE_USER = gql`
  mutation DELETE_USER($id: Long!) {
    deleteUser(id: $id) {
      id
      deleted
    }
  }
`
const TOGGLE_USER_ACCESS = gql`
  mutation TOGGLE_USER_ACCESS($id: Long!) {
    toggleUserAccess(id: $id) {
      enabled
    }
  }
`

export default UsersPage
