import React from 'react'
import { Typography, Grid, Paper, Box } from '@material-ui/core'
import { HTMLQuestionTitle } from 'components/Layout/HTMLQuestionTitle'
import { HTMLChoice } from 'components/Layout/HTMLChoice'
import moment from '_utils/moment'
import { HtmlViwer } from 'components/Editor'
import { support_format_webp } from '_utils/tools'
export default function TestVisualizationForm({ assessment }) {
  return (
    <Grid item md={10} xs={12}>
      <Paper style={{ padding: '2rem', textAlign: 'center' }}>
        <img
          style={{ width: '6rem' }}
          src={support_format_webp() ? '/images/logo.webp' : '/images/logo.png'}
          alt="logo"
        />
        <Typography variant="h4" style={{ padding: '0rem' }}>
          {assessment.title}
        </Typography>
        <HtmlViwer className="htmlOnly" value={assessment.instructions || ''} />
        <Grid item md={12}>
          <Box
            style={{
              fontSize: '1.5em',
              fontWeight: 'bold'
            }}
            className="primary">
            {assessment.testDurationInMinutes > 0 && (
              <React.Fragment>
                {moment.utc(assessment.testDurationInMinutes * 60 * 1000).format('HH:mm:ss')}
              </React.Fragment>
            )}
          </Box>
        </Grid>
      </Paper>
      <br />
      <Paper style={{ padding: '1rem' }}>
        <br />
        {assessment && assessment.questions && assessment.questions.length > 0 ? (
          assessment.questions.map((question, index) => {
            return (
              <div style={{ paddingBottom: '2rem' }} key={question.id}>
                <HTMLQuestionTitle
                  current={index + 1}
                  text={question.text}
                  size={assessment.questions.length}
                />
                {question.questionType === 'singleline' ? (
                  <Typography>R.{' Resposta do Avaliado '}</Typography>
                ) : (
                  question.choices.map((choice, choiceIndex) => {
                    return (
                      <HTMLChoice
                        key={choice.id}
                        choice={choice}
                        index={choiceIndex}
                        showAnswers={true}
                      />
                    )
                  })
                )}
              </div>
            )
          })
        ) : (
          <Grid container item md={12} justify="center">
            <Typography>Nenhuma questão cadastrada.</Typography>
          </Grid>
        )}
      </Paper>
    </Grid>
  )
}
