import { userConstants } from '_constants/userConstants'
import { userService } from '_services/user.service'
import history from '_utils/history'
import { alertActions } from '_actions/alert.actions'
import { ROLES } from 'components/Layout/Navigation/NavItens'
var Tawk_API = window.Tawk_API || {}
export const logout = () => {
  if (Tawk_API && Tawk_API.endChat) {
    Tawk_API.endChat()
  }
  userService.logout()
  history.push('/login')
}
export const login = (values, forwardUrl) => (dispatch) => {
  sessionStorage.removeItem('access_token')
  sessionStorage.removeItem('me')
  return userService.login(values).then(
    (response) => {
      if (response.access_token) {
        sessionStorage.setItem('access_token', response.access_token)

        if (forwardUrl) {
          history.push(forwardUrl)
        } else {
          userService
            .getSelf()
            .then(
              (_response) => {
                if (_response.roles) {
                  if (_response.roles.some((r) => r.name === ROLES.ROLE_CANDIDATE)) {
                    history.push('/candidate/home')
                  } else {
                    history.push('/home')
                  }
                }
              },
              (error) => {
                failure(error)
              }
            )
            .catch((error) => console.error(error))
        }
        dispatch(success(response))
      } else {
        dispatch(failure(response.message))
      }
    },
    (error) => {
      dispatch(failure(error))
    }
  )

  function success(response) {
    response.fullName
      ? dispatch(alertActions.success('Bem vindo ' + response.fullName.split(' ')[0]))
      : void 0

    return { type: userConstants.LOGIN_SUCCESS, response }
  }
  function failure(error) {
    if (
      (error && error.message === 'Failed to fetch') ||
      error.message.startsWith('NetworkError')
    ) {
      console.error(error)
      dispatch(alertActions.error('Servidor em manutenção'))
    } else {
      dispatch(alertActions.error((error && error.message) || 'Erro ao efetuar operação'))
    }

    return { type: userConstants.LOGIN_FAILURE, error }
  }
}

export const signup = (body) => (dispatch) => {
  request()
  userService.signup(body).then(
    (response) => {
      dispatch(success('Usuário cadastrado com sucesso'))
      history.push('/login')
    },
    (error) => {
      dispatch(failure(error))
    }
  )
  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user }
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user }
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error }
  }
  return Promise.resolve()
}
