import config from '_utils/config'
import { getHeaders } from '_utils/headers'
export const userService = {
  login,
  isLogged,
  logout,
  signup,
  requestPasswordRecovery,
  getAll,
  getSelf
}

function isLogged() {
  const query = new URLSearchParams(window.location.search)
  const token = query.get('token')
  if (token) {
    sessionStorage.setItem('access_token', token)
  }
  return sessionStorage.getItem('access_token') !== null
}

function login(body) {
  sessionStorage.removeItem('access_token')
  sessionStorage.removeItem('me')
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(body)
  }
  return fetch(`${config.backend.BACKEND_URL}/login`, requestOptions).then(handleResponse)
}

function logout() {
  sessionStorage.removeItem('access_token')
  sessionStorage.removeItem('me')
}

function requestPasswordRecovery(email) {
  const requestOptions = {
    method: 'POST',
    headers: { ...getHeaders() },
    body: JSON.stringify({ email })
  }

  return fetch(`${config.backend.BACKEND_URL}/recover-password`, requestOptions).then(
    handleResponse
  )
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: { ...getHeaders() }
  }

  return fetch(`${config.backend.BACKEND_URL}/users`, requestOptions).then(handleResponse)
}

function getSelf() {
  const requestOptions = {
    method: 'GET',
    headers: { ...getHeaders() }
  }

  return fetch(`${config.backend.BACKEND_URL}/userinfo`, requestOptions).then(handleResponse)
}

function signup(user) {
  const requestOptions = {
    method: 'POST',
    headers: { ...getHeaders() },
    body: JSON.stringify(user)
  }

  return fetch(`${config.backend.BACKEND_URL}/sign-up`, requestOptions).then(handleResponse)
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        logout()
      }
      return Promise.reject(data)
    }

    return data
  })
}
