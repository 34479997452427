import React, { useState } from 'react'
import {
  TextField,
  MenuItem,
  Select,
  OutlinedInput,
  InputLabel,
  FormControl,
  FormHelperText,
  Switch,
  FormGroup,
  FormControlLabel,
  Chip,
  Checkbox,
  Radio,
  RadioGroup,
  Slider,
  Paper,
  IconButton,
  Icon,
  InputBase,
  makeStyles,
  Input,
  Typography
} from '@material-ui/core'
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import InputMask from 'react-input-mask'
import { useTheme } from '@material-ui/core/styles'
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField'
import { letters } from 'pages/Tests/Edit/TestQuestionsComponent'
export const TimerSelect = (props) => {
  var hours = Math.floor(props.value / 60)
  var minutes = Math.floor(props.value - (hours * 3600) / 60)
  var d = new Date()
  d.setHours(hours)
  d.setMinutes(minutes)
  const [selectedDate, handleDateChange] = useState(props.value ? d : null)
  const handleChanged = (e) => {
    handleDateChange(e)
    props.onValueChanged({ name: props.name, value: e })
  }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker
        style={{ width: '100%' }}
        {...props}
        clearable
        ampm={false}
        name={props.name}
        label={props.label}
        value={selectedDate}
        onChange={handleChanged}
      />
    </MuiPickersUtilsProvider>
  )
}

export function CustomSearchField(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      padding: '2px 4px',
      marginBottom: '1rem',
      marginTop: '1rem'
    },
    iconButton: {
      padding: 10
    },
    input: {
      flex: 1,
      fontSize: '1.2rem',
      marginLeft: theme.spacing(1)
    }
  }))
  const classes = useStyles()
  return (
    <Paper component="form" className={classes.root}>
      <IconButton className={classes.iconButton} disabled={true}>
        <Icon>search</Icon>
      </IconButton>
      <InputBase
        autoFocus
        className={classes.input}
        placeholder={props.placeholder || 'Filtrar'}
        onChange={props.onChange}
        value={props.value}
      />
      {props.value && (
        <IconButton className={classes.iconButton} onClick={props.onClear}>
          <Icon>clear</Icon>
        </IconButton>
      )}
    </Paper>
  )
}

export const SimpleSwitch = (props) => {
  const [checked, setChecked] = React.useState(props.value)
  const toggleChecked = (e) => {
    setChecked((prev) => !prev)
    props.onChange(e)
  }
  return (
    <FormGroup>
      <FormControlLabel
        label={props.label}
        control={<Switch {...props} checked={checked} onChange={toggleChecked} />}
      />
    </FormGroup>
  )
}
export const MultipleMultipleCustomSelectInput = (props) => {
  const [selectedItems, setItems] = React.useState(props.value || [])
  const [options] = React.useState(props.items || [])

  function handleDelete(id) {
    props.onChange(selectedItems.filter((si) => si.id !== id))
    setItems(selectedItems.filter((si) => si.id !== id))
  }
  function handleChangeMultiple(event) {
    const { options: _options } = event.target
    const values = []
    for (let i = 0, l = _options.length; i < l; i += 1) {
      if (_options[i].selected) {
        values.push(JSON.parse(_options[i].dataset.item))
      }
    }
    setItems(values, props.onChange(values))
  }

  return (
    <div>
      <InputLabel>{props.label}</InputLabel>
      <Select
        styles={{ width: '200px' }}
        multiple
        native
        value={selectedItems.map((m) => m[props.displayFieldLabel])}
        onChange={handleChangeMultiple}
        inputProps={{
          id: 'select-multiple-native'
        }}>
        {options.map((option) => (
          <option
            data-item={JSON.stringify(option)}
            id={option[props.displayFieldKey]}
            key={option[props.displayFieldKey]}
            value={option[props.displayFieldLabel]}>
            {props.displayFieldLabel.indexOf(',') !== -1
              ? props.displayFieldLabel.split(',').map((item) => '<' + option[item] + '>')
              : option[props.displayFieldLabel]}
          </option>
        ))}
      </Select>
      <br /> <br />
      {selectedItems.map((value) => (
        <Chip
          id={value[props.displayFieldKey]}
          key={value[props.displayFieldKey]}
          label={
            props.displayFieldLabel.indexOf(',') !== -1
              ? props.displayFieldLabel.split(',').map((item) => '<' + value[item] + '>')
              : value[props.displayFieldLabel]
          }
          onDelete={(e) => handleDelete(value[props.displayFieldKey])}
        />
      ))}
    </div>
  )
}
export const CustomTextAreaField = ({ field, form: { touched, errors }, ...props }) => (
  <TextField
    id={field.id}
    name={field.name}
    onChange={field.onChange}
    label={props.label}
    multiline
    rows={props.rows}
    fullWidth={props.fullWidth ? props.fullWidth : true}
    error={touched[field.name] && errors[field.name]}
    helperText={errors[field.name]}
    value={field.value || ''}></TextField>
)

export const CustomCurrencyTextField = ({ field, form: { touched, errors }, ...props }) => {
  const theme = useTheme()
  return (
    <CurrencyTextField
      {...field}
      {...props}
      onChange={(event, value) => props.onChange(field.name, value)}
      fullWidth={props.fullWidth ? props.fullWidth : true}
      error={Boolean(touched[field.name] && errors[field.name]) || false}
      helperText={touched[field.name] && errors[field.name]}
      currencySymbol="R$"
      decimalCharacter=","
      digitGroupSeparator="."
      outputFormat={props.outputFormat}
      variant={theme.props.MuiSelect.variant}
    />
  )
}

export const CustomTextField = ({ field, form: { touched, errors }, ...props }) => {
  return props.mask ? (
    <InputMask {...field} {...props} mask={props.mask}>
      {() => (
        <TextField
          {...field}
          {...props}
          fullWidth={props.fullWidth ? props.fullWidth : true}
          error={Boolean(touched[field.name] && errors[field.name]) || false}
          helperText={touched[field.name] && errors[field.name]}
        />
      )}
    </InputMask>
  ) : (
    <TextField
      {...field}
      {...props}
      value={field.value || ''}
      fullWidth={props.fullWidth ? props.fullWidth : true}
      error={Boolean(touched[field.name] && errors[field.name]) || false}
      helperText={touched[field.name] && errors[field.name]}></TextField>
  )
}
export const CustomTextArea = ({ field, form: { touched, errors }, ...props }) => (
  <TextField
    id={field.id}
    label={props.label}
    placeholder={props.placeholder}
    {...field}
    multiline
    value={field.value || ''}
    fullWidth={props.fullWidth}
    error={(touched[field.name] && errors[field.name]) || false}
    helperText={touched[field.name] && errors[field.name]}
  />
)
export const CustomCheckBoxField = ({ field, form: { touched, errors, status }, ...props }) => {
  console.log(field)
  return (
    <FormControl required error={Boolean(errors[field.name])} component="fieldset">
      <FormGroup>
        <FormControlLabel control={<Checkbox {...field} {...props} />} label={props.label} />
      </FormGroup>
      <FormHelperText error>{errors[field.name]}</FormHelperText>
    </FormControl>
  )
}

export const CustomRadioField = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <FormControlLabel
      label={props.label}
      control={
        <Radio
          {...field}
          {...props}
          checked={field.value === 'true' || field.value ? true : false}
          fullwidth={props.fullWidth ? props.fullWidth : 'true'}
          error={Boolean(touched[field.name] && errors[field.name]) || 'false'}
          helpertext={errors[field.name] || ''}
        />
      }
    />
  )
}
export const CustomCheckBoxField2 = ({ field, form: { touched, errors }, ...props }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      '&:hover': {
        backgroundColor: '#F5F5F5'
      },
      marginTop: '1rem'
    },
    checked: {}
  }))
  const classes = useStyles()

  return (
    <RadioGroup>
      {props.options &&
        props.options.map((choice, index) => {
          return (
            <FormControlLabel
              classes={classes}
              key={(choice && choice.id) || index}
              name={`${field.name}.choices[${index}]`}
              value={choice.id}
              control={<Checkbox checked={choice.checked || false} onChange={props.onChange} />}
              label={letters[index] + ') ' + choice.text}
            />
          )
        })}
    </RadioGroup>
  )
}

export const CustomRadioField2 = ({ field, form: { touched, errors }, ...props }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      '&:hover': {
        backgroundColor: '#F5F5F5'
      },
      marginTop: '1rem'
    },

    checked: {}
  }))
  const classes = useStyles()
  return (
    <RadioGroup onChange={props.onChange} classes={classes.button}>
      {props.options &&
        props.options.map((choice, index) => {
          return (
            <FormControlLabel
              classes={classes}
              key={index}
              name={`${field.name}.choices[${index}]`}
              value={choice.id}
              control={<Radio checked={choice.checked} />}
              label={letters[index] + ') ' + choice.text}
            />
          )
        })}
    </RadioGroup>
  )
}
export const CustomSimpleSwitch = ({ field, form: { touched, errors }, ...props }) => (
  <FormControlLabel
    control={<Switch {...field} {...props} checked={field.value || false} />}
    label={props.label}
  />
)

export const CustomSlider = ({ field, form: { touched, errors }, ...props }) => {
  const useStyles = makeStyles({
    root: {}
  })
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography id="discrete-slider" gutterBottom>
        {props.label}
      </Typography>
      <Slider {...props} defaultValue={field.value} />{' '}
    </div>
  )
}

export const CustomSelectInput = ({ field, form, ...props }) => {
  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(2)
  const { name, value, onChange } = field
  const { errors } = form
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [name, value])
  const theme = useTheme()
  return (
    <FormControl fullWidth={props.fullWidth ? props.fullWidth : true}>
      <InputLabel
        ref={inputLabel}
        htmlFor={name}
        error={!!errors[name]}
        variant={theme.props.MuiSelect.variant}>
        {props.label}
      </InputLabel>
      <Select
        {...props}
        error={!!errors[name]}
        onChange={(e) => {
          form.setFieldTouched(name + '', true)
          onChange(e)
        }}
        inputProps={{ name, value }}
        input={
          theme.props.MuiSelect.variant === 'outlined' ? (
            <OutlinedInput labelWidth={labelWidth || 2} />
          ) : (
            <Input />
          )
        }>
        {props.options &&
          props.options.map((item) => (
            <MenuItem key={item.id} value={item.id} disabled={item.active === false}>
              {item[props.labelkey || 'label']}
            </MenuItem>
          ))}
      </Select>
      {Boolean(errors && errors[name]) && (
        <FormHelperText error>{String(errors[name])}</FormHelperText>
      )}
    </FormControl>
  )
}
