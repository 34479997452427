const tileSize = 6
const pageSize = 15
const pageSizeOptions = [5, 10, 15, 20, 25, 50, 100, 200]
const autoCloseDelay = 5000
const tableLocalization = {
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: 'linhas',
    firstTooltip: 'Primeira Página',
    previousTooltip: 'Página Anterior',
    nextTooltip: 'Próxima Página',
    lastTooltip: 'Última Página'
  },
  toolbar: {
    nRowsSelected: '{0} entrada(s) selecionadas',
    searchPlaceholder: 'Procurar',
    exportName: 'Exportar como .csv',
    exportTitle: 'Exportar'
  },
  header: {
    actions: 'Ações'
  },
  grouping: {
    placeholder: 'Arraste as colunas aqui para agrupar'
  },
  body: {
    emptyDataSourceMessage: 'Sem dados',
    caption: 'Teste',
    deleteTooltip: 'Deletar',
    editTooltip: 'Editar',
    editRow: {
      deleteText: 'Tem certeza que deseja remover?',
      cancelTooltip: 'Cancelar',
      saveTooltip: 'Confirmar',
      addTooltip: 'Adicionar'
    }
  }
}

const masks = {
  cpf: '999.999.999-99',
  cnpj: '99.999.999/9999-99',
  mobile: '(99) 99999-9999',
  cardNumber: '9999 9999 9999 9999',
  time: 'HH:mm:ss',
  currency: 'R$ 999,99',
  cep: '99.999-999'
}
const msgs = {
  operationError: 'Erro ao realizar operação!',
  operationSuccess: 'Operação Realizada!'
}
const labels = {
  MONTHLY: 'Mensalmente',
  QUARTERLY: 'Trimestralmente',
  YEARLY: 'Anualmente'
}
export {
  pageSizeOptions,
  tileSize,
  tableLocalization,
  pageSize,
  masks,
  msgs,
  autoCloseDelay,
  labels
}
