import { useMutation } from '@apollo/client'
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  Paper,
  Typography
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import FormHeading from 'components/Layout/FormHeading'
import { CustomTextField } from 'components/Layout/FormFields'
import { Field, Form, Formik } from 'formik'
import gql from 'graphql-tag'
import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { UPDATE_PROFILE } from '_queries/USER'
import { autoCloseDelay, msgs } from '_utils/appConstants'
import config from '_utils/config'
import { ProfileContext } from 'components/Layout/Navigation/Navigation'
let toastId = -1
export function SummaryDataWidget() {
  const notifyProgress = () =>
    (toastId = toast('Processando...', {
      autoClose: false
    }))
  const success = (result) => {
    console.debug(result)
    toast.update(toastId, {
      render: msgs.operationSuccess,
      type: toast.TYPE.SUCCESS,
      autoClose: autoCloseDelay
    })
  }
  const error = (exception) => {
    console.error(exception)
    let errorMsg = msgs.operationError
    if (exception) {
      var pieces = exception.message.split(':')
      errorMsg = pieces[pieces.length - 1]
    }
    toast.update(toastId, {
      render: errorMsg,
      type: toast.TYPE.ERROR,
      autoClose: autoCloseDelay
    })
  }
  const profile = useContext(ProfileContext)
  const [showPassword, setShowPassword] = React.useState(true)
  const [avatarUrl, setAvatarUrl] = React.useState(profile && profile.avatarUrl)

  const [updateUserAvatar] = useMutation(UPDATE_USER_AVATAR, {
    name: 'updateUserAvatar'
  })
  const [updateUserProfile] = useMutation(UPDATE_PROFILE, {
    name: 'updateUserProfile',
    options: (props) => ({
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        sessionStorage.setItem('me', JSON.stringify(data.updateUserProfile))
      }
    })
  })
  const uploadFile = (e, callback) => {
    let data = new FormData()
    data.append('file', e.target.files[0])
    data.append('upload_preset', 'default')
    data.append('folder', 'question/T' + (profile && profile.tenant.id))
    fetch(config.storage.CLOUD_STORAGE_API, {
      method: 'POST',
      body: data
    })
      .then((response) => {
        return response.json()
      })
      .then((file) => {
        setAvatarUrl(file.secure_url)
        profile.avatarUrl = file.secure_url
        // setProfile(profile)
        updateUserAvatar({
          variables: {
            id: profile.id,
            avatarUrl: file.secure_url
          }
        })
      })
  }

  const Schema = Yup.object().shape({
    password: Yup.string().min(6, "O campo 'Senha' deve ter no mínimo 6 caracteres.").nullable()
  })

  return (
    <Grid item md={6} xs={12}>
      <Box p={3} component={Paper}>
        <FormHeading variant="h4">Meu Perfil </FormHeading>{' '}
        <Formik
          enableReinitialize={false}
          validationSchema={Schema}
          initialValues={{ ...profile, password: '' }}
          onSubmit={(values, actions, isSubmitting) => {
            notifyProgress()
            updateUserProfile({
              variables: {
                request: {
                  id: values.id,
                  fullName: values.fullName,
                  password: values.password,
                  email: values.email,
                  tenant: {
                    id: values.tenant.id,
                    organization: values.tenant.organization
                  }
                }
              }
            })
              .then((results) => {
                results.data.updateUserProfile ? success(results) : error(msgs.operationError)
              })
              .catch((exception) => {
                error(exception)
              })
            actions.setSubmitting(false)
          }}>
          {(formikProps) =>
            profile && (
              <Form onSubmit={formikProps.handleSubmit} noValidate>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignContent="center"
                  alignItems="center">
                  <Grid item xs={12}>
                    <input
                      accept="image/*"
                      style={{
                        display: 'none'
                      }}
                      id="contained-button-file"
                      name="avatarUrl"
                      onChange={(e) => uploadFile(e)}
                      multiple
                      type="file"
                    />
                    <Avatar
                      htmlFor="contained-button-file"
                      name={profile.fullName}
                      round={'true'}
                      onClick={() => document.getElementById('contained-button-file').click()}
                      id="avatar-image"
                      style={{ cursor: 'pointer', margin: 10, width: 100, height: 100 }}
                      src={avatarUrl}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">{profile.email}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {profile.roles && <Chip label={profile.roles && profile.roles[0].label} />}
                  </Grid>
                  <Field
                    component={CustomTextField}
                    fullWidth={true}
                    label="Nome Completo"
                    type={'text'}
                    name="fullName"
                  />{' '}
                  <Field
                    name="password"
                    fullWidth={true}
                    autoComplete="off"
                    component={CustomTextField}
                    type={showPassword ? 'password' : 'text'}
                    label="Nova Senha"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid container justify="center">
                  {formikProps.loading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type="submit"
                      variant="outlined"
                      disabled={formikProps.isSubmitting || !formikProps.isValid}
                      startIcon={<Icon>save</Icon>}>
                      Salvar
                    </Button>
                  )}
                </Grid>
              </Form>
            )
          }
        </Formik>
      </Box>
    </Grid>
  )
}

export const UPDATE_USER_AVATAR = gql`
  mutation UPDATE_USER_AVATAR($id: Long, $avatarUrl: String) {
    updateUserAvatar(id: $id, avatarUrl: $avatarUrl) {
      id
      avatarUrl
    }
  }
`
export default SummaryDataWidget
