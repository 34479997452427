export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  CHANGE_PASSWORD_SOLICITATION_REQUEST: "CHANGE_PASSWORD_SOLICITATION_REQUEST",
  CHANGE_PASSWORD_SOLICITATION_SUCCESS: "CHANGE_PASSWORD_SOLICITATION_SUCCESS",
  CHANGE_PASSWORD_SOLICITATION_FAILURE: "CHANGE_PASSWORD_SOLICITATION_FAILURE",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  LOGOUT_REQUEST: "LOGOUT_REQUEST",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  GETSELF_REQUEST: "USERS_GETSELF_REQUEST",
  GETSELF_SUCCESS: "USERS_GETSELF_SUCCESS",
  GETSELF_FAILURE: "USERS_GETSELF_FAILURE",

  DELETE_REQUEST: "USERS_DELETE_REQUEST",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",

  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
};
