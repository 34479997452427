import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Icon,
  IconButton,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core'
import { HtmlViwer } from 'components/Editor'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import history from '_utils/history'
import { toLocalDate } from '_utils/moment'

const useStyles = makeStyles((theme) => ({
  root: { minHeight: 310, minWidth: 300 },
  card: {
    minWidth: 300,
    marginBottom: theme.spacing(1)
  }
}))

export const TestListGrid = ({ items, onShare, onArchive, duplicateAssessment }) => {
  document.title = 'Question - Listagem Testes'
  const classes = useStyles()
  return (
    <Grid container spacing={2} direction="row">
      {items &&
        items.map((assessment, index) => {
          return (
            <Grid key={index} item md={4} xs={12}>
              <Card className={classes.root}>
                <CardHeader
                  title={assessment.title}
                  subheader={
                    <>
                      {assessment.createdAt && (
                        <Typography variant="caption" color="textSecondary">
                          Criação: {assessment.createdAt && toLocalDate(assessment.createdAt)}
                        </Typography>
                      )}{' '}
                      {assessment.updatedAt && (
                        <Typography variant="caption" color="textSecondary">
                          Atualização: {assessment.updatedAt && toLocalDate(assessment.updatedAt)}
                        </Typography>
                      )}
                    </>
                  }></CardHeader>

                <CardContent>
                  {/* <Typography variant="caption" color="textSecondary">
                    Código: {assessment.id} 
                  </Typography> */}
                  <Typography variant="body2" color="textSecondary">
                    Título: {assessment.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Questões: {assessment.questions.length}
                  </Typography>{' '}
                  <Typography variant="body2" color="textSecondary" component="p">
                    Tempo Controlado: {assessment.timeControlled ? 'Sim' : 'Não'}
                  </Typography>{' '}
                  <Typography variant="body2" color="textSecondary" component="p">
                    Mostrar resultado após conclusão:
                    {assessment.showResultsAfterFinish ? 'Sim' : 'Não'}
                  </Typography>{' '}
                  <Grid item md={12}>
                    {assessment.subtitle && (
                      <>
                        <Typography variant="h6" component="h5">
                          Descrição
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          <Grid></Grid>
                          <HtmlViwer className="htmlOnly" value={assessment.subtitle || ''} />
                        </Typography>{' '}
                      </>
                    )}
                  </Grid>
                </CardContent>
                <CardActions disableSpacing>
                  <Grid container justify="center" direction="column" alignContent="center">
                    <Grid item md={12}>
                      <Link to={'/tests/visualization/' + assessment.id} component={RouterLink}>
                        <IconButton title="Visualizar Teste">
                          <Icon>visibility</Icon>
                        </IconButton>
                      </Link>
                      <IconButton
                        title="Editar Teste"
                        onClick={() => history.push(`/tests/edit/${assessment.id}`)}>
                        <Icon>edit</Icon>
                      </IconButton>{' '}
                      <IconButton
                        title="Resultados Associados"
                        onClick={() =>
                          history.push(`/tests/results?assessmentId=${assessment.id}`)
                        }>
                        <Icon>find_in_page</Icon>
                      </IconButton>
                      <IconButton
                        title="Duplicar Teste"
                        onClick={() => {
                          duplicateAssessment({
                            variables: { assessmentId: assessment.id }
                          })
                        }}>
                        <Icon>file_copy</Icon>
                      </IconButton>
                      <IconButton
                        color="primary"
                        title="Arquivar"
                        onClick={() => {
                          onArchive(assessment)
                        }}>
                        <Icon>delete_outline</Icon>
                      </IconButton>{' '}
                    </Grid>{' '}
                    <Grid item md={12}>
                      <Button
                        size="small"
                        fullWidth
                        disableElevation
                        startIcon={<Icon>launch</Icon>}
                        onClick={() => {
                          onShare(assessment)
                        }}>
                        Aplicar Teste
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          )
        })}
      <Grid item md={4} xs={12}>
        <Link to="/manage/tests/new" component={RouterLink}>
          <CardActionArea>
            <Card className={classes.root}>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ marginTop: '32%' }}>
                <Grid item xs={6}>
                  <Typography variant="h5" color="primary">
                    + Novo Teste
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </CardActionArea>
        </Link>
      </Grid>
    </Grid>
  )
}
