import { useMutation, useQuery } from '@apollo/client'
import { Grid } from '@material-ui/core'
import Loading from 'components/Loading'
import { Formik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import 'video-react/dist/video-react.css'
import { RETRIEVE_CANDIDATE_ASSESSMENT, SAVE_TEST_RESULTS } from '_queries/TEST_EXECUTION'
import { handleApolloErrors } from '_services/apollo'
import { msgs } from '_utils/appConstants'
import history from '_utils/history'
import { ExecutionForm } from './TestExecutionForm'
import TestFeedbackPoll from './TestFeedbackPoll'
import ThankYouMessage from './ThankYouMessage'

function TestExecutionPage(props) {
  document.title = 'Question - Execução de Testes - Executando'
  const { data, loading } = useQuery(RETRIEVE_CANDIDATE_ASSESSMENT, {
    name: 'getCandidateAssessment',
    variables: {
      id: props.candidateAssessmentId,
      code: props.code
    },
    fetchPolicy: 'no-cache',
    onError: (error) => handleApolloErrors(error)
  })
  const [saveCandidateAnswers] = useMutation(SAVE_TEST_RESULTS, {
    onCompleted: (result) => {
      result.saveCandidateAnswers.id
        ? Swal.fire({
            title: 'Suas respostas foram enviadas!',
            text: 'Seu teste será encerrado',
            type: 'success',
            showCancelButton: false,
            confirmButtonColor: '#FE5000',
            confirmButtonText: 'Entendi'
          }).then(() => {
            if (result.saveCandidateAnswers.assessment.showResultsAfterFinish) {
              history.push('/tests/simple_results/' + result.saveCandidateAnswers.id)
            } else {
              setShowThankYou(true)
            }
            return
          })
        : void 0
    },
    onError: (error) => {
      if (error) {
        var pieces = error.message.split(':')
        toast.error(pieces[pieces.length - 1])
      } else {
        toast.error(msgs.operationError)
      }
    }
  })
  const [showThankYouMessage, setShowThankYou] = React.useState(false)
  const [timeUp, setTimeUp] = React.useState(false)
  const [submitEnabled, setSubmissionEnabled] = React.useState(false)
  const [candidateAssessment, setCandidateAssessment] = React.useState(null)
  React.useEffect(() => {
    if (data && data.retrieveCandidateAssessment) {
      setCandidateAssessment(data.retrieveCandidateAssessment)
    }
  }, [data])

  return loading ? (
    <Loading />
  ) : (
    candidateAssessment && (
      <Grid direction="column" container alignItems="center" justify="center">
        {showThankYouMessage && <ThankYouMessage />}
        {showThankYouMessage && (
          <TestFeedbackPoll candidateAssessmentId={candidateAssessment.id} />
        )}

        {candidateAssessment.config.executionEnabled &&
          !showThankYouMessage &&
          candidateAssessment.startedAt &&
          candidateAssessment.assessment && (
            <Formik
              enableReinitialize={true}
              initialValues={{
                title: candidateAssessment.assessment.title,
                instructions: candidateAssessment.assessment.instructions,
                questions: candidateAssessment.assessment.questions,
                activeStep: 0
              }}
              onSubmit={(values) => {
                saveCandidateAnswers({
                  variables: {
                    input: {
                      candidateAssessmentId: candidateAssessment.id,
                      questions: values.questions.map((q) => {
                        return {
                          id: q.id,
                          answerText: q.answerText,
                          choices:
                            q.choices &&
                            q.choices.map((c) => {
                              return {
                                id: c.id,
                                checked: c.checked
                              }
                            })
                        }
                      })
                    }
                  }
                })
              }}
              validate={(values) => {
                setSubmissionEnabled(
                  values.questions
                    .filter((question) => question.required)
                    .map((question) => {
                      if (question.questionType === 'singleline') {
                        return !!question.answerText
                      } else {
                        return question.choices.some((c) => c.checked)
                      }
                    })
                    .every((ok) => ok)
                )
              }}>
              {(_props) => {
                return _props.isSubmitting ? (
                  <Grid
                    item
                    container
                    direction="column"
                    justify="center"
                    alignContent="center"
                    alignItems="center">
                    <Loading />
                  </Grid>
                ) : (
                  <ExecutionForm
                    {..._props}
                    candidateAssessment={candidateAssessment}
                    onTimeUp={(_questions) => {
                      setTimeUp(true)
                    }}
                    isTimedOut={timeUp}
                    isLoading={_props.isSubmitting}
                    isSubmitEnabled={submitEnabled}
                  />
                )
              }}
            </Formik>
          )}
      </Grid>
    )
  )
}

export default TestExecutionPage
