import React from 'react'
import { Typography, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import { support_format_webp } from '_utils/tools'
import Slide from '@material-ui/core/Slide'
const EmptyMessage = ({ image, title, subtitle, action, children }) => {
  return (
    <Slide direction="down" in={true} mountOnEnter unmountOnExit>
      <Box style={{ textAlign: 'center' }} m={2} p={2}>
        {!image ? (
          <img
            alt="empty ind"
            width="200vw"
            src={support_format_webp() ? '/images/logo.webp' : '/images/logo.png'}></img>
        ) : (
          <img alt="empty ind" width="200vw" src={image}></img>
        )}
        <br />
        <Typography variant="h4">{title || children}</Typography>
        <Typography variant="h6" color="textSecondary">
          {subtitle}
        </Typography>
        <br />
        {action}
      </Box>
    </Slide>
  )
}
EmptyMessage.propTypes = {
  title: PropTypes.string
}
export default EmptyMessage
