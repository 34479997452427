import { useMutation } from '@apollo/client'
import { Divider, List, ListItem, ListItemText, Paper } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ConnectedPaymentForm from 'pages/Checkout/PaymentForm'
import React from 'react'
import { toast } from 'react-toastify'
import { CREATE_SUBSCRIPTION } from '_queries/SUBSCRIPTION'
import { handleApolloErrors } from '_services/apollo'
import { autoCloseDelay, labels, msgs } from '_utils/appConstants'
import config from '_utils/config'
import history from '_utils/history'
import { formatBytes } from '_utils/tools'
const useStyles = makeStyles((theme) => ({
  section: { marginTop: theme.spacing(1), marginBottom: theme.spacing(3) }
}))
let toastId
const notifyProgress = () => (toastId = toast('Processando...', { autoClose: false }))
const success = (result) => {
  toast.update(toastId, {
    render: msgs.operationSuccess,
    type: toast.TYPE.SUCCESS,
    autoClose: autoCloseDelay
  })
}
const error = (exception) => {
  console.error(exception)
  toast.update(toastId, {
    render: msgs.operationError,
    type: toast.TYPE.ERROR,
    autoClose: autoCloseDelay
  })
}
function Subscription(props) {
  document.title = 'Question - Nova Assinatura'
  const query = new URLSearchParams(props.location.search)
  const planId = query.get('planId')
  const [mode] = React.useState(query.get('mode'))
  const [plan, setPlan] = React.useState(null)
  const [createSubscription, { loading }] = useMutation(CREATE_SUBSCRIPTION, {
    name: 'createSubscription',
    fetchPolicy: 'no-cache',
    onError: (_error) => handleApolloErrors(_error),
    onCompleted: (results) => success(msgs.operationSuccess)
  })
  const classes = useStyles()
  React.useEffect(() => {
    fetch(config.backend.BACKEND_URL + `/plans/` + planId, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json'
      })
    })
      .then((res) => res.json())
      .then((_plan) => setPlan(_plan))
      .catch((error) => console.error(error))
  }, [planId])
  return (
    plan && (
      <Container maxWidth="md">
        <Box className={classes.section} p={3}>
          <Grid container>
            <Grid
              container
              direction="row"
              justify="center"
              alignContent="center"
              alignItems="center"
              component={Paper}>
              <Grid item md={6} style={{ textAlign: 'center' }}>
                <Typography variant="h6">Plano</Typography>
                <Typography variant="h4" color="primary">
                  {plan.name}
                </Typography>
                <div style={{ display: 'block' }}>
                  <span style={{ fontSize: '2em' }}>
                    R$
                    {mode === 'MONTHLY' &&
                      plan &&
                      plan.prices &&
                      (plan.prices[mode.toLowerCase()] / 100).toFixed(2).replace('.', ',')}
                    {mode === 'QUARTERLY' &&
                      plan &&
                      plan.prices &&
                      (plan.prices[mode.toLowerCase()] / 100 / 3).toFixed(2).replace('.', ',')}
                    {mode === 'YEARLY' &&
                      plan &&
                      plan.prices &&
                      (plan.prices[mode.toLowerCase()] / 100 / 12).toFixed(2).replace('.', ',')}
                  </span>
                  /Mês
                </div>
                <Typography variant="caption" color="textSecondary">
                  {'Cobrado ' + labels[mode]}
                </Typography>
              </Grid>
              <Grid item md={6}>
                {plan.limits && (
                  <List dense>
                    {plan.limits.applications && (
                      <>
                        <ListItem button>
                          <ListItemText
                            style={{ textAlign: 'center' }}
                            primary={plan.limits.assessments}
                            secondary="Testes ativos"
                          />
                        </ListItem>
                        <Divider light />
                      </>
                    )}
                    {plan.limits.applications && (
                      <>
                        <ListItem button>
                          <ListItemText
                            style={{ textAlign: 'center' }}
                            primary={plan.limits.applications}
                            secondary="Aplicações de testes mensais"
                          />
                        </ListItem>
                        <Divider light />
                      </>
                    )}
                    {plan.limits.applications && (
                      <>
                        <ListItem button>
                          <ListItemText
                            style={{ textAlign: 'center' }}
                            primary={formatBytes(plan.limits.uploads)}
                            secondary="Espaço de armazenagem de mídias de testes"
                          />
                        </ListItem>
                        <Divider light />
                      </>
                    )}
                    {plan.limits.applications && (
                      <>
                        <ListItem button>
                          <ListItemText
                            style={{ textAlign: 'center' }}
                            primary={plan.limits.subUsers}
                            secondary="Total de Usuários"
                          />
                        </ListItem>{' '}
                      </>
                    )}{' '}
                    <>
                      <ListItem button>
                        <ListItemText
                          style={{ textAlign: 'center' }}
                          primary={'Ilimitado'}
                          secondary="Total de Avaliados"
                        />
                      </ListItem>{' '}
                    </>
                  </List>
                )}
              </Grid>
            </Grid>
            <Grid item md={12} className={classes.section} component={Paper}>
              <Box p={3}>
                <ConnectedPaymentForm
                  classes={props.classes}
                  loading={loading}
                  onCreditCardPay={(creditCardData, billingAddressData) => {
                    notifyProgress()
                    createSubscription({
                      variables: {
                        planId: plan.id,
                        creditCard: creditCardData,
                        billingAddress: billingAddressData,
                        paymentMethod: 'credit_card',
                        billingMode: mode
                      }
                    })
                      .then((result) => {
                        console.debug(result)
                        if (
                          result &&
                          result.data &&
                          result.data.createSubscription &&
                          result.data.createSubscription.uuid
                        ) {
                          success(msgs.operationSuccess)
                          history.push('/subscribed')
                        } else {
                          error(msgs.operationError)
                        }
                      })
                      .catch((exception) => error(exception))
                  }}
                  onBoletoPay={(billingAddressData) => {
                    notifyProgress()
                    createSubscription({
                      variables: {
                        planId: plan.id,
                        billingAddress: billingAddressData,
                        paymentMethod: 'boleto',
                        billingMode: mode
                      }
                    })
                      .then((result) => {
                        if (
                          result &&
                          result.data &&
                          result.data.createSubscription &&
                          result.data.createSubscription.uuid
                        ) {
                          history.push({
                            pathname: '/subscribed',
                            state: { boletoUrl: result.data.createSubscription.boletoUrl }
                          })
                          success(msgs.operationSuccess)
                        } else {
                          error(msgs.operationError)
                        }
                      })
                      .catch((exception) => error(exception))
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    )
  )
}
export default Subscription
