import { Breadcrumbs, Container, Grid, Link, Typography } from '@material-ui/core'
import { getHomeByProfile } from 'components/Layout/Navigation/AppToolBar'
import { ProfileContext } from 'components/Layout/Navigation/Navigation'
import Loading from 'components/Loading'
import PageHeaderPath from 'components/PageHeaderPath'
import React, { useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import DetailsForm from './DetailsDataWidget'
import { LGPDDataWidget } from './LGPDDataWidget'
import PlanDataWidget from './PlanDataWidget'
import SummaryForm from './SummaryDataWidget'
function ProfilePage() {
  const profile = useContext(ProfileContext)
  document.title = 'Question - Perfil'
  return profile ? (
    <Container maxWidth="xl">
      <PageHeaderPath>
        <Breadcrumbs separator="›" aria-label="Breadcrumb">
          <Link to={getHomeByProfile()} component={RouterLink}>
            Home
          </Link>
          <Typography color="textPrimary"> Perfil</Typography>
        </Breadcrumbs>
      </PageHeaderPath>
      <Grid container item spacing={1}>
        <SummaryForm />
        <DetailsForm />
        <PlanDataWidget />
        <LGPDDataWidget />
      </Grid>
    </Container>
  ) : (
    <Loading />
  )
}

export default ProfilePage
