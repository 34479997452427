import { deepOrange, green } from '@material-ui/core/colors'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
const formsMargin = 'normal'
const overridings = {
  name: 'Default Theme',
  typography: {
    fontFamily: [
      'Nunito',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  overrides: {
    MuiPaper: {
      root: {}
    },
    MuiHelperText: {
      root: {}
    },
    MuiTextField: {
      root: {}
    },
    MuiSelect: {
      root: {}
    },
    MuiOutlinedInput: {
      root: {}
    },
    MuiButton: {
      label: { pointerEvents: `none` }
    },

    MuiTableCell: {
      root: {
        padding: '0px 0.5rem 0.5rem 0.5rem'
      },
      head: {
        fontWeight: 'bold'
      }
    }
  },
  palette: {
    primary: deepOrange,
    secondary: {
      main: green[500]
    }
  },

  props: {
    MuiFormControl: {
      variant: 'outlined',
      margin: formsMargin,
      fullWidth: true
    },
    MuiSelect: {
      variant: 'outlined'
    },
    MuiTextField: {
      variant: 'outlined'
    },
    MuiButton: {
      variant: 'contained',
      color: 'primary'
    }
  }
}

export default responsiveFontSizes(createMuiTheme(overridings))
