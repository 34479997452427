import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Icon,
  Typography,
} from "@material-ui/core";
import React from "react";
function AlertDialog(props) {
  return (
    <Dialog
      fullWidth={props.fullWidth || true}
      maxWidth={props.maxWidth || "lg"}
      fullScreen={true}
      open={props.open || false}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AppBar style={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <Icon>close</Icon>
          </IconButton>
          <Typography variant="h6">{props.title}</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <div>{props.content || props.children}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="default" autoFocus>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default AlertDialog;
