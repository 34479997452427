import { ApolloProvider } from '@apollo/client'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import App from 'App'
import { ProfileContext } from 'components/Layout/Navigation/Navigation'
import 'fontsource-nunito'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { unregister as unregisterServiceWorker } from 'registerServiceWorker'
import 'styles/index.css'
import { client } from '_services/apollo3'
import configureStore from '_store/configureStore'

const store = configureStore()
var AppView = () => {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ProfileContext.Consumer>
          {() => {
            return (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <App />
              </MuiPickersUtilsProvider>
            )
          }}
        </ProfileContext.Consumer>
      </Provider>
    </ApolloProvider>
  )
}

ReactDOM.render(<AppView />, document.getElementById('root'))

unregisterServiceWorker()

if (module.hot) {
  module.hot.accept('App', () => {
    ReactDOM.render(<AppView />, document.getElementById('root'))
  })
}
