import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup
} from '@material-ui/core'
import Link from '@material-ui/core/Link'
import FormHeading from 'components/Layout/FormHeading'
import { CustomCheckBoxField, CustomTextField } from 'components/Layout/FormFields'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import { userService } from '_services/user.service'
import { masks, msgs } from '_utils/appConstants'
import config from '_utils/config'
import history from '_utils/history'
import SignUpValidationSchema from './SignUpValidationSchema'
const PFFields = () => {
  return (
    <Grid item xs={12}>
      <Field name="email" label="Email" component={CustomTextField} />
      <Field name="fullName" label="Nome Completo" component={CustomTextField} />
      <Field name="document" label="CPF" component={CustomTextField} mask={masks.cpf} />
    </Grid>
  )
}

const PJFields = () => {
  return (
    <Grid item xs={12}>
      <Field name="email" label="Email do Responsável" component={CustomTextField} />
      <Field name="fullName" label="Nome do Responsável" component={CustomTextField} />
      <Field name="document" label="CNPJ" component={CustomTextField} mask={masks.cnpj} />
      <Field
        name="organization"
        component={CustomTextField}
        label="Razão Social ou Nome Fantasia"
      />
    </Grid>
  )
}
export const SignUpForm = (props) => {
  const { handleSubmit, isSubmitting, handleChange, forwardUrl, isValid } = props
  return (
    <Form
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      noValidate
      className="signup-form">
      <Box p={3}>
        <Grid container alignItems="center" justify="center" direction="column">
          <FormHeading variant="h4">Inscreva-se grátis e comece a usar.</FormHeading>
        </Grid>
        <Grid container justify="flex-start" direction="column">
          <FormControl component="fieldset">
            <FormLabel component="legend">Tipo Conta</FormLabel>
            <RadioGroup
              autoFocus
              row
              aria-label="documentType"
              name="documentType"
              defaultValue="CPF"
              onChange={handleChange}>
              <FormControlLabel
                value="CPF"
                control={<Radio color="primary" />}
                label="Pessoa Física"
              />
              <FormControlLabel
                value="CNPJ"
                control={<Radio color="primary" />}
                label="Pessoa Jurídica"
              />
            </RadioGroup>
          </FormControl>{' '}
          {props.values.documentType === 'CPF' ? <PFFields /> : <PJFields />}
          <Field
            name="password"
            label="Senha de acesso"
            type="password"
            component={CustomTextField}
          />
          <Field
            name="passwordConfirmation"
            label="Confirme sua senha"
            type="password"
            component={CustomTextField}
          />
          <Field label="Telefone" name="phone" component={CustomTextField} mask={masks.mobile} />
          <Field
            label="Compartilhar meus dados cadastrais com parceiros para fins de marketing de produtos relacionados."
            name="acceptedShareData"
            component={CustomCheckBoxField}
          />
          <Field
            label={
              <div>
                Eu aceito os{' '}
                <a
                  href={config.frontend.LANDINGPAGE_URL + '/terms-and-conditions'}
                  target="_blank"
                  rel="noopener noreferrer">
                  Termos e Condições
                </a>{' '}
                e a
                <a
                  href={config.frontend.LANDINGPAGE_URL + '/privacy-policy'}
                  target="_blank"
                  rel="noopener noreferrer">
                  {' '}
                  Política de Privacidade
                </a>{' '}
              </div>
            }
            name="acceptedTACandPP"
            component={CustomCheckBoxField}
          />
        </Grid>

        <Grid container alignItems="center" justify="center" direction="column">
          {isSubmitting ? (
            <CircularProgress />
          ) : (
            <Button id="signup-btn" type="submit" size="large" disabled={!isValid}>
              Enviar
            </Button>
          )}
          <Grid item md={12} xs={12}>
            <br />
            <Link href={forwardUrl ? '/login?forwardUrl=' + forwardUrl : '/login'}>
              Já possuo uma conta
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Form>
  )
}

const ConnectedSignUpForm = ({ forwardUrl }) => {
  return (
    <Formik
      enableReinitialize={true}
      validationSchema={SignUpValidationSchema}
      isInitialValid={false}
      initialValues={{
        organization: '',
        fullName: '',
        document: '',
        phone: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        documentType: 'CPF',
        acceptedTACandPP: false,
        acceptedShareData: false
      }}
      onSubmit={(values, actions) => {
        userService.signup(values).then(
          (response) => {
            actions.setSubmitting(false)
            toast.success('Conta criada com sucesso')
            if (response.Authorization) {
              sessionStorage.setItem('access_token', response.Authorization)
            }
            setTimeout(() => {
              if (forwardUrl) {
                history.push(forwardUrl)
              } else {
                history.push(response.url)
              }
            }, 1000)
          },
          (error) => {
            actions.setSubmitting(false)
            toast.error(error.message || msgs.operationError)
          }
        )
      }}>
      {(formProps) => <SignUpForm {...formProps} forwardUrl={forwardUrl} />}
    </Formik>
  )
}

export default ConnectedSignUpForm
