import gql from 'graphql-tag'
export const UPDATE_ASSESSMENT = gql`
  mutation UPDATE_ASSESSMENT($request: AssessmentUpdateRequestInput!) {
    updateAssessment(request: $request) {
      id
      title
      subtitle
      instructions
      testDurationInMinutes
      testAvailabilityInDays
      timeControlled
      showResultsAfterFinish
      # corrector {
      #   id
      # }
    }
  }
`
export const GET_TAGS = gql`
  query GET_TAGS {
    tags {
      id
      name
    }
  }
`

export const DUPLICATE_ASSESSMENT = gql`
  mutation DUPLICATE_ASSESSMENT($assessmentId: Long!) {
    duplicateAssessment(assessmentId: $assessmentId) {
      id
      title
      subtitle
      instructions
      timeControlled
      updatedAt
      createdAt
      questions {
        id
      }
      showResultsAfterFinish
    }
  }
`
export const UNSHARE_ASSESSMENT = gql`
  mutation UNSHARE_ASSESSMENT($assessmentId: Long!, $email: String!) {
    unShareWithEmail(assessmentId: $assessmentId, email: $email) {
      id
    }
  }
`
export const DELETE_ASSESSMENT = gql`
  mutation DELETE_ASSESSMENT($id: Long!) {
    deleteAssessment(id: $id)
  }
`
export const SHARE_ASSESSMENT = gql`
  mutation SHARE_ASSESSMENT(
    $assessmentId: Long!
    $emails: [String]!
    $tags: [String]
    $config: CandidateAssessmentConfigDTOInput
  ) {
    shareAssessmentByEmail(
      assessmentId: $assessmentId
      emails: $emails
      tags: $tags
      config: $config
    ) {
      id
    }
  }
`
export const CREATE_ASSESSMENT = gql`
  mutation CREATE_ASSESSMENT($request: AssessmentCreationRequestInput!) {
    createAssessment(request: $request) {
      id
      title
      subtitle
      instructions
      updatedAt
      createdAt
      questions {
        id
      }
    }
  }
`
export const GET_ASSESSMENTS = gql`
  query GET_ASSESSMENTS {
    allAssessments {
      id
      title
      subtitle
      instructions
      timeControlled
      updatedAt
      createdAt
      questions {
        id
      }
      showResultsAfterFinish
    }
  }
`
export const GET_ASSESSMENT = gql`
  query GET_ASSESSMENT($id: Long!) {
    assessment(id: $id) {
      id
      title
      subtitle
      instructions
      testDurationInMinutes
      showResultsAfterFinish
      timeControlled
      testAvailabilityInDays
      questions {
        id
        text
        questionType
        questionOrder
        choices {
          id
          text
          score
        }
      }
    }
  }
`
