import { Box, Button, Grid, Icon, Typography } from '@material-ui/core'
import { HtmlEditor } from 'components/Editor'
import { CustomSimpleSwitch, CustomSlider, CustomTextField } from 'components/Layout/FormFields'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import { GET_ASSESSMENT, UPDATE_ASSESSMENT } from '_queries/ASSESSMENTS'
import { handleApolloErrors } from '_services/apollo'
import { msgs } from '_utils/appConstants'
import { useMutation } from '@apollo/client'
export default function EditTestForm({ assessment, tenantId }) {
  const [updateAssessment] = useMutation(UPDATE_ASSESSMENT, {
    name: 'updateAssessment',
    refetchQueries: [
      {
        query: GET_ASSESSMENT,
        variables: {
          id: assessment.id
        }
      }
    ],
    onError: (error) => handleApolloErrors(error),
    onCompleted: (results) => toast.success(msgs.operationSuccess)
  })
  return (
    <Formik
      initialValues={{ assessment: assessment }}
      onSubmit={(values, { setSubmitting }) => {
        values.assessment['questions'] ? delete values.assessment['questions'] : void 0
        updateAssessment({
          variables: {
            request: {
              ...values.assessment
            }
          }
        }).then(() => setSubmitting(false))
      }}>
      {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={3} direction="row" justify="center">
              <Grid container item md={6} direction="row" spacing={3}>
                <Grid item md={12} xs={12}>
                  <Field name="assessment.title" component={CustomTextField} label="Título" />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography>Descrição que o avaliado irá visualizar</Typography>
                  <Field
                    name="assessment.instructions"
                    multiline
                    rowsMax="10"
                    rows={6}
                    value={values.assessment.instructions}
                    onChange={(name, value) => {
                      setFieldValue(name, value)
                    }}
                    tenantId={
                      tenantId ||
                      (JSON.parse(sessionStorage.getItem('me')).tenant &&
                        JSON.parse(sessionStorage.getItem('me')).tenant.id)
                    }
                    toolbar={true}
                    placeholder={
                      'Escreva aqui o texto que irá aparecer para o avaliado durante a execução do teste'
                    }
                    component={HtmlEditor}
                    label="Instruções"
                  />
                </Grid>
              </Grid>
              <Grid item container md={6} direction="row">
                <Grid item md={12} xs={12}>
                  <Field
                    name="assessment.subtitle"
                    multiline
                    rows={4}
                    rowsMax={6}
                    component={CustomTextField}
                    label="Descrição de uso interno (não será visível para o avaliado)"
                  />
                </Grid>
                <Grid item md={values.assessment.timeControlled ? 6 : 12} xs={12}>
                  <Field
                    name="assessment.timeControlled"
                    component={CustomSimpleSwitch}
                    label="Teste Temporizado?"
                  />
                </Grid>
                <Grid item md={5} xs={12}>
                  {values.assessment.timeControlled && (
                    <Field
                      onChange={(e) =>
                        e.target.value >= 0 && e.target.value < 60 * 24 ? handleChange(e) : void 0
                      }
                      name="assessment.testDurationInMinutes"
                      component={CustomTextField}
                      label="Duração do teste em minutos"
                      type={'number'}
                    />
                  )}
                </Grid>
                <Grid item md={12} xs={12}>
                  <Field
                    name="assessment.showResultsAfterFinish"
                    component={CustomSimpleSwitch}
                    label="Mostrar Resultados ao final do teste?"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Box p={1}>
                    <Typography id="discrete-slider" gutterBottom>
                      Disponibilidade do teste{' '}
                      <span style={{ fontWeight: 'bolder' }}>
                        {values.assessment.testAvailabilityInDays}
                        dias.
                      </span>
                    </Typography>
                    <Field
                      component={CustomSlider}
                      name="assessment.testAvailabilityInDays"
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      onChange={(e, value) =>
                        setFieldValue('assessment.testAvailabilityInDays', value)
                      }
                      step={1}
                      marks
                      min={1}
                      max={10}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center" component={Box} p={3}>
              <Button
                color="primary"
                type="submit"
                startIcon={<Icon>save</Icon>}
                disabled={isSubmitting}>
                Salvar Configuração
              </Button>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
