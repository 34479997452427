import { useQuery } from '@apollo/client'
import { Box, Breadcrumbs, Grid, Link, Typography } from '@material-ui/core'
import { getHomeByProfile } from 'components/Layout/Navigation/AppToolBar'
import Loading from 'components/Loading'
import PageHeaderPath from 'components/PageHeaderPath'
import gql from 'graphql-tag'
import TestVisualizationForm from 'pages/Candidate/TestView/TestVisualizationForm'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { handleApolloErrors } from '_services/apollo'

function SingleResultPage(props) {
  const { data, loading } = useQuery(GET_CANDIDATE_ASSESSMENT, {
    fetchPolicy: 'no-cache',
    variables: {
      id: props.match.params.id
    },
    onError: (error) => handleApolloErrors(error)
  })
  return (
    <Box>
      <PageHeaderPath>
        <Breadcrumbs separator="›" aria-label="Breadcrumb">
          <Link to={getHomeByProfile()} component={RouterLink}>
            Home
          </Link>
          <Link to={'/tests/results'} component={RouterLink}>
            Resultados
          </Link>
          <Typography color="textPrimary">Visualização</Typography>
        </Breadcrumbs>
      </PageHeaderPath>
      <Grid container justify="center" alignContent="center" alignItems="center">
        {loading ? (
          <Loading />
        ) : data && data.candidateAssessment ? (
          <TestVisualizationForm
            candidateAssessment={data && data.candidateAssessment}
            showCorrectionButtons={true}
          />
        ) : (
          <Typography color="textPrimary"> Resultado Não Encontrado</Typography>
        )}
      </Grid>
    </Box>
  )
}

const GET_CANDIDATE_ASSESSMENT = gql`
  query GET_ASSESSMENT($id: Long!) {
    candidateAssessment(id: $id) {
      id
      completedAt
      startedAt
      score
      elapsedTime
      answers {
        id
        score
        ... on TextAnswer {
          answerText
        }
        question {
          text
          questionType
          status
          choices {
            id
            text
            score
            candidateResponse
          }
        }
      }
      assessment {
        title
        subtitle
        questions {
          id
          choices {
            score
          }
        }
      }

      candidate {
        fullName
      }
    }
  }
`
export default SingleResultPage
