import { useQuery } from '@apollo/client'
import {
  Container,
  Divider,
  Drawer,

  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import AppToolBar from 'components/Layout/Navigation/AppToolBar'
import NavItens, { ROLES } from 'components/Layout/Navigation/NavItens'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import * as userActions from '_actions/user.actions'
import { GET_ME } from '_queries/USER'
import { support_format_webp } from '_utils/tools'

export const ProfileContext = React.createContext('profile')

function NavigationWrapper(props) {
  const handleLogout = () => {
    userActions.logout()
    sessionStorage.removeItem('me')
    sessionStorage.removeItem('access_token')
  }
  const { data, loading } = useQuery(GET_ME, {
    // fetchPolicy: 'cache-and-network',
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      sessionStorage.setItem('me', JSON.stringify(data.me))
    }
  })
  const TopBar = (topBarProps) => {
    const [menuOpen, setVisible] = React.useState(null)
    // eslint-disable-next-line
    function handleVisibility() {
      setVisible(!menuOpen)
    }

    return (
      <ProfileContext.Consumer>
        {(profile) => (
          <AppToolBar
            showMenu={topBarProps.showMenu}
            showProfile={topBarProps.showProfile}
            showLogo={topBarProps.showLogo}
            showButtons={topBarProps.showButtons}
            loading={topBarProps.loading}
            profile={profile}
            position="static"
            handleVisibility={handleVisibility}
            onLogout={topBarProps.handleLogout}
            showUpgradeButton={
              profile &&
              profile.tenant &&
              profile.tenant.trial &&
              profile.roles.some((r) => ROLES.ROLE_ADMIN === r.name)
            }>
            <Draw open={menuOpen} onClose={handleVisibility} profile={profile} />
          </AppToolBar>
        )}
      </ProfileContext.Consumer>
    )
  }

  const Draw = ({ open, onClick, onKeyDown, onClose, profile }) => {
    const userRoles = profile && profile.roles && profile.roles[0]
    const mainRole = (userRoles && userRoles.name) || ''
    const sideList = (side) => (
      <div
        role="presentation"
        onClick={onClick}
        onKeyDown={onKeyDown}
        style={{ padding: '2rem', width: 'auto' }}>
        <img
          className="logo"
          src={support_format_webp() ? '/images/logo.webp' : '/images/logo.png'}
          alt="logo"
        />

        <List>
          {userRoles &&
            NavItens.filter(
              (n) => n.visible === true && n.roles && n.roles.indexOf(mainRole) !== -1
            ).map((item, index) =>
              item.divider ? (
                <Divider key={index} />
              ) : (
                <Link key={index} to={item.to} component={RouterLink}>
                  <ListItem button key={index}>
                    <ListItemIcon>
                      <Icon>{item.leftIcon}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={item.primaryText} />
                  </ListItem>
                </Link>
              )
            )}
        </List>
      </div>
    )
    return (
      <Drawer open={open || false} onClose={onClose} variant="temporary">
        {sideList('left')}
      </Drawer>
    )
  }
  return (
    <React.Fragment>
      <ProfileContext.Provider value={data && data.me}>
        <TopBar handleLogout={handleLogout} {...props} loading={loading} />
        <Container maxWidth={'xl'}>{props.children}</Container>
      </ProfileContext.Provider>
    </React.Fragment>
  )
}

export default NavigationWrapper
