import { useMutation } from '@apollo/client'
import { Button, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import React from 'react'
import { toast } from 'react-toastify'
import { SAVE_TEST_FEEDBACK } from '_queries/TEST_EXECUTION'
import { msgs } from '_utils/appConstants'
import Slide from '@material-ui/core/Slide'
const labels = {
  0.5: 'Muito Ruim',
  1: 'Ruim',
  1.5: 'Muito Fraca',
  2: 'Fraca',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Boa',
  4: 'Muito Boa',
  4.5: 'Excelente',
  5: 'Fantástica'
}

const useStyles = makeStyles({
  root: {}
})

export default function TestFeedbackPoll({ candidateAssessmentId }) {
  const [rating, setValue] = React.useState(3)
  const [comments, setComments] = React.useState(null)
  const [hover, setHover] = React.useState(-1)
  const [feedbackGive, setFeedbackGiven] = React.useState(false)
  const classes = useStyles()
  const [saveCandidateFeedback] = useMutation(SAVE_TEST_FEEDBACK, {
    onCompleted: (result) => {
      toast.success('Obrigado pelo seu feedback!')
      setFeedbackGiven(true)
    },
    onError: (error) => {
      if (error) {
        var pieces = error.message.split(':')
        toast.error(pieces[pieces.length - 1])
      } else {
        toast.error(msgs.operationError)
      }
    }
  })

  function saveFeedback(rating, comment, _candidateAssessmentId) {
    saveCandidateFeedback({
      variables: {
        input: {
          candidateAssessmentId: _candidateAssessmentId,
          rating: rating,
          comments: comment
        }
      }
    })
  }
  return !feedbackGive ? (
    <Grid
      container
      style={{
        padding: '2rem',
        textAlign: 'center'
      }}
      justify="center"
      alignContent="center">
      {' '}
      <Slide direction="up" in={true} mountOnEnter unmountOnExit>
        <div className={classes.root}>
          <Typography variant="h6">Como foi sua experiência na realização do teste?</Typography>
          <Rating
            size={'large'}
            name="hover-feedback"
            value={rating}
            precision={0.5}
            onChange={(event, newValue) => {
              setValue(newValue)
            }}
            onChangeActive={(event, newHover) => {
              setHover(newHover)
            }}
          />
          <Typography variant="body2">
            {rating !== null && labels[hover !== -1 ? hover : rating]}
          </Typography>
          <TextField
            fullWidth
            multiline={true}
            onChange={(e) => setComments(e.target.value)}
            rows={3}
            placeholder="Se preferir, Deixe um comentário complementando sua avaliação"
          />
          <Button onClick={(e) => saveFeedback(rating, comments, candidateAssessmentId)}>
            Enviar
          </Button>
        </div>
      </Slide>
    </Grid>
  ) : (
    'Feedback enviado!'
  )
}
