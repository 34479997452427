import { useLazyQuery } from '@apollo/client'
import {
  Box,
  Button,
  Chip,
  Grid,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import FormHeading from 'components/Layout/FormHeading'
import { ProfileContext } from 'components/Layout/Navigation/Navigation'
import Loading from 'components/Loading'
import UtilizationContainer from 'components/UtilizationWidget'
import gql from 'graphql-tag'
import React, { useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import config from '_utils/config'
import { toLocalDate2 } from '_utils/moment'
import { currencyFormatter } from '_utils/tools'
function DenseTable(props) {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="right">Fatura</TableCell>
            <TableCell align="right">Forma</TableCell>
            <TableCell align="right">Situação</TableCell>
            <TableCell align="right">Valor</TableCell>
            <TableCell align="right">Vencimento</TableCell>
            <TableCell align="right">Pago em</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.transactions.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="right">
                {row.id && row.invoiceUrl ? (
                  <a href={row.invoiceUrl} target="_blank" rel="noopener noreferrer">
                    {row.id}
                  </a>
                ) : (
                  row.id
                )}
              </TableCell>
              <TableCell align="right">
                {row.paymentMethod === 'CREDIT_CARD' ? (
                  'Cartão de Crédito'
                ) : row.paymentMethod === 'BOLETO' ? (
                  <a href={row.boletoUrl} target="_blank" rel="noopener noreferrer">
                    Boleto
                  </a>
                ) : (
                  'Boleto'
                )}
              </TableCell>
              <TableCell
                align="right"
                style={{ color: row.status === 'OVERDUE' ? 'red' : 'inherit' }}>
                {row.statusDescription || ''}
              </TableCell>
              <TableCell
                align="right"
                style={{ color: row.status === 'OVERDUE' ? 'red' : 'inherit' }}>
                {currencyFormatter.format(row.amount / 100)}
              </TableCell>
              <TableCell
                align="right"
                style={{ color: row.status === 'OVERDUE' ? 'red' : 'inherit' }}>
                {toLocalDate2(row.dueDate)}
              </TableCell>
              <TableCell align="right">
                {row.paymentDate && toLocalDate2(row.paymentDate)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
function LiteTable(props) {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="right">Forma</TableCell>
            {/* <TableCell align="right">Situação</TableCell> */}
            <TableCell align="right">Valor</TableCell>
            <TableCell align="right">Vencimento</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.transactions.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="right">
                {row.paymentMethod === 'CREDIT_CARD' ? (
                  'Cartão de Crédito'
                ) : row.paymentMethod === 'BOLETO' ? (
                  <a href={row.boletoUrl} target="_blank" rel="noopener noreferrer">
                    Boleto - {row.statusDescription || ''}
                  </a>
                ) : (
                  'Boleto'
                )}
              </TableCell>
              {/* <TableCell
                align="right"
                style={{ color: row.status === 'OVERDUE' ? 'red' : 'inherit' }}>
                {row.statusDescription || ''}
              </TableCell> */}
              <TableCell
                align="right"
                style={{ color: row.status === 'OVERDUE' ? 'red' : 'inherit' }}>
                {currencyFormatter.format(row.amount / 100)}
              </TableCell>
              <TableCell
                align="right"
                style={{ color: row.status === 'OVERDUE' ? 'red' : 'inherit' }}>
                {toLocalDate2(row.dueDate)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function MyPlanDataWidget() {
  const profile = useContext(ProfileContext)
  const [subscription, setSubscription] = React.useState(null)
  const [getSubInfo, { loading, data }] = useLazyQuery(GET_SUB_INFO, {
    variables: { subId: profile.tenant.subscription && profile.tenant.subscription.uuid },
    skip: profile.tenant.subscription == null
  })

  React.useEffect(() => {
    if (profile.tenant.subscription && profile.tenant.subscription.uuid) {
      getSubInfo()
    }
    if (data) {
      setSubscription(data.subInfo)
    }
  }, [profile.tenant.subscription, getSubInfo, data])

  if (loading) return <Loading />
  if (!profile.admin) return ''
  return subscription ? (
    <Grid item md={6}>
      <Box p={3} component={Paper}>
        <Grid container justify="center" direction="row">
          <Grid item xs={12}>
            <FormHeading variant="h4">Meu Plano </FormHeading>
          </Grid>
        </Grid>
        <Grid item container justify="center" xs={12}>
          <Typography variant="h4">{profile.tenant.subscription.plan.name}</Typography>
        </Grid>
        <Grid item container justify="center" xs={12}>
          <Chip size="small" color="primary" variant="outlined" label={subscription.status}></Chip>
        </Grid>
        <Grid item>
          {subscription.status !== 'CANCELLED' && (
            <>
              {subscription.transactions && subscription.transactions.length > 0 && (
                <Grid container component={Box} pt={1} justify="center">
                  <Hidden mdDown>
                    <DenseTable transactions={subscription.transactions} />
                  </Hidden>
                  <Hidden mdUp>
                    <LiteTable transactions={subscription.transactions} />
                  </Hidden>
                </Grid>
              )}
              <Grid container component={Box} pt={1} justify="center">
                <Typography variant="caption" color="textSecondary">
                  Para cancelar sua assinatura entre em contato com o suporte através do
                  {' ' + config.functional.SUPPORT_EMAIL + ' '}
                  Informe este código durante o cancelamento {subscription.uuid}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>{' '}
        <UtilizationContainer />
      </Box>
    </Grid>
  ) : (
    <Grid item md={6}>
      <Grid p={3} component={Paper} container justify="center">
        <Box p={3}>
          <FormHeading>
            Plano Atual{' '}
            {profile && profile.tenant && profile.tenant.subscription ? (
              <span style={{ textAlign: 'center', color: 'primary' }}>
                {profile.tenant.subscription.plan.name}
                {profile.tenant.skipLimits && ' VIP'}
              </span>
            ) : (
              <span style={{ textAlign: 'center' }}>
                Trial {profile && profile.tenant && profile.tenant.skipLimits && ' VIP'}
              </span>
            )}
          </FormHeading>
          <Button to={'/pricing'} color="primary" component={RouterLink}>
            Escolher um Plano
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
export const GET_SUB_INFO = gql`
  query subInfo($subId: String!) {
    subInfo(subId: $subId) {
      uuid
      cardBrand
      cardLastDigits
      paymentMethod
      periodStart
      periodEnd
      status
      transactions {
        id
        status
        statusName
        statusDescription
        amount
        dueDate
        paymentDate
        boletoUrl
        invoiceUrl
        paymentMethod
      }
    }
  }
`
export const GET_PLAN_INFO = gql`
  query planInfo($planId: Long!) {
    planInfo(planId: $planId) {
      id
      name
    }
  }
`
export default MyPlanDataWidget
