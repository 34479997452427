import React from 'react'
import { Grid, Paper, Button, Box, Icon } from '@material-ui/core'
import { useMutation } from '@apollo/client'
import { Form, Field, Formik } from 'formik'
import { CustomTextField } from 'components/Layout/FormFields'
import { masks } from '_utils/appConstants'
import CandidateValidationSchema from '../CandidateValidationSchema'
import { msgs, autoCloseDelay } from '_utils/appConstants'
import { toast } from 'react-toastify'
import gql from 'graphql-tag'
import { handleApolloErrors } from '_services/apollo'
import FormHeading from 'components/Layout/FormHeading'
import Loading from 'components/Loading'
export const EditCandidateForm = (props) => {
  const { handleSubmit, isSubmitting, loading } = props

  return (
    <Box p={3} component={Paper}>
      <FormHeading>Atualização Avaliado</FormHeading>
      <Form onSubmit={handleSubmit} noValidate>
        <Field name="fullName" component={CustomTextField} label="Nome Avaliado" />
        <Field name="email" component={CustomTextField} label="Email" />
        <Field name="mobile" component={CustomTextField} label="Celular" mask={masks.mobile} />
        <Box container justify="center" component={Grid} p={2}>
          {loading ? (
            <Loading />
          ) : (
            <Button type="submit" disabled={isSubmitting} startIcon={<Icon>save</Icon>}>
              Salvar
            </Button>
          )}
        </Box>
      </Form>
    </Box>
  )
}

let toastId
const notifyProgress = () => (toastId = toast('Processando...', { autoClose: false }))
const success = (result) => {
  console.debug(result)
  toast.update(toastId, {
    render: msgs.operationSuccess,
    type: toast.TYPE.SUCCESS,
    autoClose: autoCloseDelay
  })
}
const error = (exception) => {
  console.error(exception)
  toast.update(toastId, {
    render: msgs.operationError,
    type: toast.TYPE.ERROR,
    autoClose: autoCloseDelay
  })
}
const ConnectedEditCandidateForm = (props) => {
  const [updateCandidate] = useMutation(UPDATE_CANDIDATE, {
    name: 'updateCandidate',
    onError: (_error) => handleApolloErrors(_error)
  })
  return (
    <Formik
      initialValues={{
        ...props.candidate
      }}
      validationSchema={CandidateValidationSchema}
      onSubmit={(variables, { setSubmitting }) => {
        notifyProgress()
        updateCandidate({ variables })
          .then((result) => {
            setSubmitting(false)
            success(result)
          })
          .catch((exception) => {
            setSubmitting(false)
            error(exception)
          })
      }}>
      {(formProps) => <EditCandidateForm {...formProps} />}
    </Formik>
  )
}
const UPDATE_CANDIDATE = gql`
  mutation UPDATE_CANDIDATE($id: Long!, $email: String!, $fullName: String, $mobile: String) {
    updateCandidate(id: $id, fullName: $fullName, email: $email, mobile: $mobile) {
      id
      fullName
      email
      mobile
    }
  }
`
export default ConnectedEditCandidateForm
