import { useMutation } from '@apollo/client'
import { Box, Button, Grid, Icon, Paper } from '@material-ui/core'
import { CustomTextField } from 'components/Layout/FormFields'
import FormHeading from 'components/Layout/FormHeading'
import Loading from 'components/Loading'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import { CREATE_CANDIDATE } from '_queries/CANDIDATES'
import { handleApolloErrors } from '_services/apollo'
import { msgs } from '_utils/appConstants'
import history from '_utils/history'
import CandidateValidationSchema from '../CandidateValidationSchema'

export function NewCandidateForm(props) {
  return (
    <Box p={3} component={Paper}>
      <Form onSubmit={props.handleSubmit} noValidate>
        <FormHeading>Novo Avaliado</FormHeading>
        <Field autoFocus name="fullName" component={CustomTextField} label="Nome Completo*" />
        <Field name="email" component={CustomTextField} label="Email*" />
        <Box container justify="center" component={Grid} p={2}>
          {props.loading ? (
            <Loading />
          ) : (
            <Button type="submit" disabled={props.isSubmitting} startIcon={<Icon>save</Icon>}>
              Salvar
            </Button>
          )}
        </Box>
      </Form>
    </Box>
  )
}

const ConnectedCandidateForm = () => {
  const [createCandidate] = useMutation(CREATE_CANDIDATE, {
    onError: (error) => handleApolloErrors(error),
    onCompleted: () => {
      toast.success(msgs.operationSuccess)
      history.push('/candidates')
    }
  })

  return (
    <Formik
      enableReinitialize={false}
      validationSchema={CandidateValidationSchema}
      initialValues={{ email: '', fullName: '' }}
      onSubmit={(values, { setSubmitting }) => {
        const variables = {
          email: values.email,
          fullName: values.fullName
        }
        createCandidate({ variables }).then(() => setSubmitting(false))
      }}>
      {(formProps) => <NewCandidateForm {...formProps} />}
    </Formik>
  )
}

export default ConnectedCandidateForm
