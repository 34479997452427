import { useMutation } from '@apollo/client'
import { Formik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import { CREATE_OR_UPDATE_QUESTIONS, DELETE_QUESTION } from '_queries/QUESTIONS'
import { handleApolloErrors } from '_services/apollo'
import { msgs } from '_utils/appConstants'
import QuestionArrayComponent from './TestQuestionsComponent'

export default function EditTestQuestionsForm({ assessment }) {
  const [createOrUpdateQuestions] = useMutation(CREATE_OR_UPDATE_QUESTIONS, {
    name: 'createOrUpdateQuestions',
    onError: (error) => handleApolloErrors(error),
    onCompleted: (results) => toast.success(msgs.operationSuccess)
  })
  const [deleteQuestion] = useMutation(DELETE_QUESTION, {
    name: 'deleteQuestion',
    options: (props) => {
      return {
        onError: (error) => handleApolloErrors(error),
        onCompleted: (results) => toast.success(msgs.operationSuccess)
      }
    }
  })
  return (
    <Formik
      initialValues={{ ...assessment }}
      onSubmit={(values, { setSubmitting }) => {
        let newQuestions = values.questions.map((q, i) => {
          let question = { ...q }
          question.questionOrder = i
          question.required = true
          question.deleted = false
          question.choices =
            q.choices &&
            q.choices
              .filter((c) => c.text !== null)
              .map((c) => {
                let newChoice = { ...c }
                c.score ? (newChoice.score = 1) : (newChoice.score = 0)
                return newChoice
              })
          return question
        })
        createOrUpdateQuestions({
          variables: {
            assessmentId: assessment.id,
            questions: newQuestions
          }
        }).then((r) => setSubmitting(false))
      }}>
      {(props) => {
        return (
          <QuestionArrayComponent
            {...props}
            questions={assessment.questions}
            onQuestionRemoved={(question) => {
              if (question.id) {
                deleteQuestion({
                  variables: { id: question.id }
                })
              }
            }}
          />
        )
      }}
    </Formik>
  )
}
