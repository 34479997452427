import React from 'react'
import { CustomTextField } from 'components/Layout/FormFields'
import { Link } from 'react-router-dom'
import { Grid, Button } from '@material-ui/core'
import { Field, Form } from 'formik'
import FormHeading from 'components/Layout/FormHeading'
import Loading from 'components/Loading'
import { support_format_webp } from '_utils/tools'

export const ForgotPasswordForm = (props) => {
  document.title = 'Question - Equeci minha senha'
  const { handleSubmit, isSubmitting } = props
  return (
    <Grid container justify="center">
      <Form onSubmit={handleSubmit} noValidate style={{ textAlign: 'center' }}>
        <Grid item md={12}>
          <Grid item>
            <img
              width="150px"
              src={support_format_webp() ? '/images/logo.webp' : '/images/logo.png'}
              alt="logo"
            />
          </Grid>
          <Grid item xs={12} justify="center">
            <FormHeading>Solicitar Nova Senha</FormHeading>
            <Field autoFocus name="email" component={CustomTextField} label="Email" />
            {isSubmitting ? (
              <Loading />
            ) : (
              <Button type="submit" disabled={isSubmitting} size="large">
                Enviar Solicitação
              </Button>
            )}
          </Grid>
          <br />
          <Link to={'/login'}>Voltar para login</Link>
        </Grid>
      </Form>
    </Grid>
  )
}

export default ForgotPasswordForm
