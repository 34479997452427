import config from '_utils/config'
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { toast } from 'react-toastify'
import { msgs } from '_utils/appConstants'
const httpLink = new HttpLink({ uri: config.backend.BACKEND_GRAPHQL_URL })
function omitDeepArrayWalk(arr, key) {
  return arr.map((val) => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key)
    else if (typeof val === 'object') return omitDeep(val, key)
    return val
  })
}
function omitDeep(obj, key) {
  const keys = Object.keys(obj)
  const newObj = {}
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i]
      if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key)
      else if (typeof val === 'object' && val !== null) newObj[i] = omitDeep(val, key)
      else newObj[i] = val
    }
  })
  return newObj
}
const authLink = new ApolloLink((operation, forward) => {
  const token = sessionStorage.getItem('access_token')
  const query = new URLSearchParams(window.location.search)
  const token2 = query.get('token')
  operation.setContext({
    headers: {
      Authorization: `Bearer ${token || token2}`
    }
  })
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, '__typename')
  }
  return forward(operation)
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink), // Chain it with the HttpLink
  cache: new InMemoryCache({
    
    addTypename: true
  })
})

export const handleApolloErrors = (error) => {
  var pieces = error.message.split(':')
  let errorMsg = pieces[pieces.length - 1]
  if (!errorMsg) {
    toast.error(msgs.operationError)
  } else {
    toast.error(errorMsg)
  }
  console.error(errorMsg)
  return errorMsg
}
