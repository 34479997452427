import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Paper, CircularProgress, Button, Box } from '@material-ui/core'
import { Form, Field, Formik } from 'formik'
import { CustomTextField } from 'components/Layout/FormFields'
import FormHeading from 'components/Layout/FormHeading'
import PasswordUpdateValidationSchema from './PasswordUpdateValidationSchema'
import config from '_utils/config'
import { getHeaders } from '_utils/headers'
import { toast } from 'react-toastify'
import { support_format_webp } from '_utils/tools'

export const PasswordUpdateForm = (props) => {
  const { handleSubmit, isSubmitting } = props
  return (
    <Grid item>
      <Box p={3} component={Paper}>
        <Grid container justify="center" direction="column" alignContent="center">
          <img
            className={'logo'}
            src={support_format_webp() ? '/images/logo.webp' : '/images/logo.png'}
            alt="logo"
          />
          <FormHeading variant="h4">Atualização de Senha</FormHeading>
        </Grid>
        <Form onSubmit={handleSubmit} noValidate>
          <Field name="password" label="Senha" type="password" component={CustomTextField} />
          <Field
            name="password_confirmation"
            label="Confirme sua senha"
            type="password"
            component={CustomTextField}
          />

          <Grid container justify="center" direction="column" alignContent="center">
            <br />
            {isSubmitting ? (
              <CircularProgress />
            ) : (
              <Button type="submit" disabled={isSubmitting}>
                Enviar
              </Button>
            )}
            <br />
            <Link to={'/login'}>Já possuo uma conta</Link>
          </Grid>
        </Form>
      </Box>
    </Grid>
  )
}

const ConnectedPasswordUpdateForm = ({ token, email }) => {
  document.title = 'Question - Atualização de senha'
  return (
    <Formik
      enableReinitialize={true}
      validationSchema={PasswordUpdateValidationSchema}
      initialValues={{
        password: ''
      }}
      onSubmit={(values, { setSubmitting }) => {
        const { password } = values
        fetch(config.backend.BACKEND_URL + `/update-password?token=${token}`, {
          method: 'POST',
          headers: getHeaders(),
          body: JSON.stringify({ password, email })
        })
          .then((response) => {
            return response.json()
          })
          .then((body) => {
            setSubmitting(false)
            toast.info(body.message)
            setTimeout(() => {
              window.location.replace('/login')
            }, 5000)
          })
          .catch((e) => {
            setSubmitting(false)
            toast.error(e.message)
          })
      }}>
      {(formProps) => <PasswordUpdateForm {...formProps} />}
    </Formik>
  )
}
export default ConnectedPasswordUpdateForm
