import { Box, Button, ButtonGroup, Icon } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { CustomTextField } from 'components/Layout/FormFields'
import Loading from 'components/Loading'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { masks } from '_utils/appConstants'
const boletoDueDate = 2
const BoletoWidget = (props) => {
  return (
    <Grid container item xs={12} component={Box} p={2} justify="center">
      <Typography variant="caption" align="center" color="textSecondary">
        Observação: Um boleto será gerado para pagamento em até {boletoDueDate} dias após a
        emissão, a liberação dos benefícios da assinatura se dará somente após o reconhecimento do
        pagamento.
      </Typography>
    </Grid>
  )
}
const CreditCardWidget = (props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Field
          name="card_number"
          component={CustomTextField}
          label="Número do Cartão"
          mask={masks.cardNumber}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          name="card_expiration_date"
          component={CustomTextField}
          label="Validade MM/YY"
          required
          mask={'99/99'}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field name="card_cvv" component={CustomTextField} label="CVV" mask={'999'} required />
      </Grid>
      <Grid item xs={12} md={12}>
        <Field name="card_holder_name" component={CustomTextField} label="Nome no Cartão" />
      </Grid>
    </Grid>
  )
}
let PaymentForm = (props) => {
  const { handleSubmit, setFieldValue, values } = props
  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Grid container justify="center" component={Box} direction="column">
        <Typography variant="h6" gutterBottom>
          Forma de pagamento
        </Typography>
        <Grid container justify="center">
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            <Button
              id="credit-card-btn"
              variant={values.mode === 'credit_card' ? 'contained' : 'outlined'}
              onClick={(e) => setFieldValue('mode', 'credit_card')}>
              Cartão de Crédito
            </Button>
            <Button
              id="boleto-btn"
              variant={values.mode === 'boleto' ? 'contained' : 'outlined'}
              onClick={(e) => setFieldValue('mode', 'boleto')}>
              Boleto
            </Button>
          </ButtonGroup>{' '}
        </Grid>
        {values.mode === 'credit_card' && <CreditCardWidget />}
        {values.mode === 'boleto' && <BoletoWidget />}
        {values.mode && (
          <Grid>
            <br />
            <Typography variant="h6" gutterBottom>
              Endereço de Cobrança
            </Typography>
            <Grid item xs={12}>
              <Field name="streetAddress" component={CustomTextField} label="Endereço" required />
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Field name="addressNumber" component={CustomTextField} label="Número" required />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="postalCode"
                  component={CustomTextField}
                  label="CEP"
                  required
                  mask={masks.cep}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {values.mode && (
        <Grid container direction="row" justify="center" alignItems="center">
          <Button
            id="confirm-subscription-btn"
            color="primary"
            size="large"
            type="submit"
            startIcon={<Icon>check</Icon>}>
            Confirmar
          </Button>
        </Grid>
      )}
      <div style={{ textAlign: 'center', display: 'grid', paddingTop: '1rem' }}>
        Pagamentos processados por:
        <a href="https://www.asaas.com/">
          <img width="100px" src="/images/payment-logo-mini.png" alt="payment img" />
        </a>
      </div>
    </Form>
  )
}

export const CreditCardValidationSchema = Yup.object().shape({
  streetAddress: Yup.string().required('Campo Obrigatório'),
  addressNumber: Yup.string().required('Campo Obrigatório'),
  postalCode: Yup.string().required('Campo Obrigatório'),
  card_number: Yup.string().min(16, 'Número Cartão Inválido').required('Campo Obrigatório'),
  card_cvv: Yup.string().required('Campo Obrigatório'),
  card_holder_name: Yup.string().required('Campo Obrigatório'),
  card_expiration_date: Yup.string().required('Campo Obrigatório')
})
export const BoletoValidationSchema = Yup.object().shape({
  streetAddress: Yup.string().required('Campo Obrigatório'),
  addressNumber: Yup.string().required('Campo Obrigatório'),
  postalCode: Yup.string().required('Campo Obrigatório')
})

const ConnectedPaymentForm = ({ onCreditCardPay, onBoletoPay, loading }) => {
  const [paymentForm, setMode] = React.useState('')
  const creditCard = {
    card_number: '',
    card_holder_name: '',
    card_expiration_date: '',
    card_cvv: ''
  }
  const billingAddress = {
    streetAddress: '',
    addressNumber: '',
    postalCode: ''
  }
  return (
    <Formik
      initialValues={{
        mode: '',
        ...creditCard,
        ...billingAddress
      }}
      validationSchema={
        paymentForm === 'credit_card' ? CreditCardValidationSchema : BoletoValidationSchema
      }
      validate={(values) => setMode(values.mode)}
      onSubmit={(variables, { setSubmitting }) => {
        let billingAddressData = {
          streetAddress: variables.streetAddress,
          addressNumber: variables.addressNumber,
          postalCode: variables.postalCode
        }
        switch (variables.mode) {
          case 'boleto':
            onBoletoPay(billingAddressData)
            break
          case 'credit_card':
            let creditCardData = {
              cardHolderName: variables.card_holder_name,
              cardNumber: variables.card_number,
              cardExpirationDate: variables.card_expiration_date,
              cardCvv: variables.card_cvv
            }
            onCreditCardPay(creditCardData, billingAddressData)
            setSubmitting(false)
            break
          default:
            break
        }
      }}>
      {(formProps) => (loading ? <Loading /> : <PaymentForm {...formProps} />)}
    </Formik>
  )
}

export default ConnectedPaymentForm
