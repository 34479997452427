import React from "react";
import { Icon, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { letters } from "pages/Tests/Edit/TestQuestionsComponent";
export function HTMLChoice({
  choice,
  index,
  showCandidateAnswers,
  showAnswers,
}) {
  return (
    <ListItem
      key={choice.id}
      disableGutters={true}
      style={{ paddingTop: "0px", paddingBottom: "0px", marginTop: "0.6em" }}
    >
      {choice.score > 0 && showAnswers ? (
        <ListItemIcon style={{ minWidth: "auto" }}>
          <Icon style={{ color: "green", paddingLeft: "0px" }}>
            check_circle_outline
          </Icon>
        </ListItemIcon>
      ) : (
        <span style={{ height: "1em" }}></span>
      )}
      <ListItemText
        style={{
          marginLeft: showAnswers && choice.score === 0 ? "1.6em" : "0px",
          border:
            choice.candidateResponse && showCandidateAnswers
              ? "1px solid black"
              : "",
          marginTop: "0px",
          marginBottom: "0px",
          backgroundColor:
            choice.score > 0 && showAnswers ? "rgba(0, 255, 0, 0.3)" : "",
        }}
        primary={letters[index] + ") " + choice.text}
      />
    </ListItem>
  );
}
