import { Button, Icon } from '@material-ui/core'
import React from 'react'
import config from '_utils/config'

export default ({ link }) =>
  link && config.tutorials.TUTORIAL_BUTTONS_ENABLED ? (
    <Button
      variant="contained"
      color="inherit"
      size="small"
      style={{ marginLeft: '1rem' }}
      onClick={() => window.open(link, 'blank')}
      startIcon={<Icon>videocam</Icon>}>
      Tutorial
    </Button>
  ) : null
