// import { useMutation } from '@apollo/client'
import { Box, Button, Card, Container, Divider, Grid, Typography } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { HtmlEditor, HtmlViwer } from 'components/Editor'
import { CustomCheckBoxField2, CustomRadioField2 } from 'components/Layout/FormFields'
import { HTMLQuestionTitle } from 'components/Layout/HTMLQuestionTitle'
import SimpleDialog from 'components/Messaging/SimpleDialog'
import ProgressMobileStepper from 'components/ProgressMobileStepper'
import { Field, Form } from 'formik'
// import gql from 'graphql-tag'
import React from 'react'

// import Swal from 'sweetalert2'
// import history from '_utils/history'
// import moment from '_utils/moment'
import TestExecutionTimer from './TestExecutionTimer'

export const ExecutionForm = ({
  isSubmitEnabled,
  setFieldValue,
  onTimeUp,
  candidateAssessment,
  // handleSubmit,
  // isTimedOut,
  values
}) => {
  const { questions } = values

  // const REQUEST_NEW_TEST_TO_OPERATOR = gql`
  //   mutation requestNewTestToOperator($candidateTestId: Long!) {
  //     requestNewTestToOperator(candidateTestId: $candidateTestId)
  //   }
  // `
  // const [requestNewTestToOperator] = useMutation(REQUEST_NEW_TEST_TO_OPERATOR)
  const [hideInstructions, setHideInstructions] = React.useState(true)

  const QuestionRenderer = ({ question, activeStep, index }) => {
    function QHTML() {
      return (
        <Grid key={question.id}>
          <HTMLQuestionTitle
            text={question.text}
            current={values.activeStep + 1}
            size={questions.length}
          />
          <Divider middle />
          {question.questionType === 'singleline' && (
            <Field
              name={`questions[${values.activeStep}].answerText`}
              multiline
              rowsMax="10"
              rows={6}
              value={question.answerText}
              onChange={(name, value) => {
                value ? setFieldValue(name, value) : setFieldValue(name, null)
              }}
              tenantId={candidateAssessment.tenant && candidateAssessment.tenant.id}
              toolbar={true}
              component={HtmlEditor}
              tip={'Clique fora da caixa de texto para confirmar sua resposta'}
              label="Instruções"
            />
          )}
          {question.questionType === 'multiplechoices' && (
            <Field
              id={question.id}
              name={`questions[${values.activeStep}]`}
              component={CustomRadioField2}
              options={question.choices.map((choice) => {
                return {
                  id: choice.id,
                  text: choice.text,
                  checked: choice.checked
                }
              })}
              onChange={(e) => {
                var _index = question.choices.map((op) => op.id).indexOf(parseInt(e.target.value))
                question.choices.forEach((op) => (op.checked = false))

                setFieldValue(`questions[${values.activeStep}].choices`, question.choices)
                var option = question.choices[_index]
                setFieldValue(e.target.name, {
                  ...option,
                  checked: true
                })
              }}></Field>
          )}
          {question.questionType === 'checkboxes' && (
            <Field
              id={question.id}
              name={`questions[${values.activeStep}]`}
              component={CustomCheckBoxField2}
              options={question.choices.map((choice) => {
                return {
                  id: choice.id,
                  text: choice.text,
                  checked: choice.checked
                }
              })}
              onChange={(e) => {
                var _index = question.choices.map((op) => op.id).indexOf(parseInt(e.target.value))
                var option = question.choices[_index]
                setFieldValue(e.target.name, {
                  ...option,
                  checked: !option.checked
                })
              }}></Field>
          )}
        </Grid>
      )
    }
    return activeStep === index && <QHTML />
  }
  return (
    <Container maxWidth="lg">
      <Slide direction="down" in={true} mountOnEnter unmountOnExit>
        <Grid
          container
          item
          md={12}
          direction="column"
          alignContent="center"
          alignItems="center"
          component={Box}
          p={3}>
          <Grid item md={12} xs={12}>
            <img style={{ height: '30px' }} src="/images/logo.webp" alt="logo" />
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography variant="caption" display="inline">
              <b>Teste: </b>
              {values.title} <b>Ref:CAID</b> {candidateAssessment.id} <b>Avaliado:</b>{' '}
              {candidateAssessment.candidate.email}
            </Typography>
          </Grid>
          {values.instructions && (
            <>
              <Button
                size="small"
                variant="outlined"
                onClick={() => setHideInstructions(!hideInstructions)}>
                Ver Instruções
              </Button>
              <SimpleDialog
                title="Instruções do Teste"
                open={!hideInstructions}
                content={<HtmlViwer value={values.instructions} />}
                onClose={() => setHideInstructions(true)}
              />
            </>
          )}
        </Grid>
      </Slide>
      {/* {isTimedOut ? (
        <Grid
          container
          justify="center"
          item
          alignContent="center"
          alignItems="center"
          direction="column">
          <Typography variant="h6">Que pena! Seu teste expirou</Typography>{' '}
          <Typography variant="h5">Sua respostas não forão submetidas!</Typography>{' '}
          <Grid container justify="center">
            <form onSubmit={handleSubmit}>
              <Button
                type="button"
                onClick={() =>
                  requestNewTestToOperator({
                    variables: {
                      candidateTestId: candidateAssessment.id
                    }
                  }).then(() => {
                    Swal.fire({
                      title: 'Solicitação Enviada!',
                      type: 'info',
                      showCancelButton: false,
                      confirmButtonColor: '#FE5000',
                      confirmButtonText: 'Entendi'
                    }).then(() => {
                      history.push('/')
                      return
                    })
                  })
                }>
                Solicitar Novo Teste
              </Button>
            </form>
          </Grid>
          <br />
        </Grid>
      ) : ( */}
        <Grid container item direction="column">
          <Grid container item md={12} justify="center">
            <TestExecutionTimer
              onTimeUp={onTimeUp}
              timeControlled={candidateAssessment.assessment.timeControlled}
              dueDateAt={candidateAssessment.config.dueDateAt}
              questions={values.questions}
            />
          </Grid>
          <Form>
            <Card component={Box} p={2} square>
              <Grid container item md={12} style={{ width: '100%' }}>
                <Grid item md={12} xs={12}>
                  {questions.map((question, index) => {
                    return (
                      <QuestionRenderer
                        key={question.id}
                        question={question}
                        index={index}
                        activeStep={values.activeStep}
                      />
                    )
                  })}
                </Grid>
                <Grid container alignContent="left" direction="column" alignItems="center">
                  <Grid container item md={6}>
                    <ProgressMobileStepper
                      activeStep={values.activeStep || 0}
                      steps={values.questions.length}
                      lastStep={
                        values.activeStep + 1 === values.questions.length && isSubmitEnabled
                      }
                      handleChange={(e) => {
                        setFieldValue('activeStep', e - 1)
                      }}
                      enableNext={
                        (values.questions[values.activeStep] &&
                          values.questions[values.activeStep].choices &&
                          values.questions[values.activeStep].choices.filter((p) => p.checked)
                            .length > 0) ||
                        values.questions[values.activeStep].answerText
                      }
                      enablePrevious={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Form>
        </Grid>
      {/* )} */}
    </Container>
  )
}

export default ExecutionForm
