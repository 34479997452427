import gql from 'graphql-tag'
export const GET_CANDIDATES = gql`
  query GET_CANDIDATES {
    allCandidates {
      id
      fullName
      # cpf
      email
      createdAt
      updatedAt
    }
  }
`
export const CREATE_CANDIDATE = gql`
  mutation createCandidate($email: String!, $fullName: String!) {
    createCandidate(email: $email, fullName: $fullName) {
      id
    }
  }
`
export const GET_CANDIDATE_APPLICATIONS = gql`
  query GET_CANDIDATE_APPLICATIONS {
    myAssessments {
      id
      score
      executionEnabled
      mediaTested
      expired
      assessment {
        id
        title
      }
    }
  }
`

export const GET_CANDIDATES2 = gql`
  query pagedAllCandidates($paging: PagingInput, $search: String) {
    pagedAllCandidates(paging: $paging, search: $search) {
      pageable {
        pageNumber
        pageSize
        hasNext
        hasPrevious
      }
      total
      totalPages
      content {
        id
        fullName
        # cpf
        mobile
        email
        createdAt
        updatedAt
      }
    }
  }
`
