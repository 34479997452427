import { useQuery } from '@apollo/client'
import { Box, Breadcrumbs, Button, Grid, Icon, Link, Paper, Typography } from '@material-ui/core'
import HelpButton from 'components/HelpButton'
import { getHomeByProfile } from 'components/Layout/Navigation/AppToolBar'
import Loading from 'components/Loading'
import PageHeaderPath from 'components/PageHeaderPath'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { GET_ASSESSMENT } from '_queries/ASSESSMENTS'
import { handleApolloErrors } from '_services/apollo'
import config from '_utils/config'
import history from '_utils/history'
import EditTestForm from './EditTestForm'
import EditTestQuestionsForm from './EditTestQuestionsForm'

function EditTestPage(pageProps) {
  document.title = 'Question - Edição de Teste'
  const { data, loading } = useQuery(GET_ASSESSMENT, {
    name: 'getAssessment',
    fetchPolicy: 'network-only',
    partialRefetch: true,
    variables: {
      id: pageProps.match.params.id
    },
    onError: (error) => handleApolloErrors(error)
  })

  return loading ? (
    <Loading />
  ) : (
    <Grid container direction="row" justify="center" alignItems="center">
      <PageHeaderPath>
        <Breadcrumbs separator="›" aria-label="Breadcrumb">
          <Link to={getHomeByProfile()} component={RouterLink}>
            Home
          </Link>
          <Link to={'/tests'} component={RouterLink}>
            Testes
          </Link>
          <Typography color="textPrimary">Edição Teste</Typography>
        </Breadcrumbs>
        <HelpButton link={config.tutorials.TUTORIAL_EDIT_TEST_PAGE} />
      </PageHeaderPath>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Grid item lg={12} xs={12} md={12}>
            <Box p={3} component={Paper}>
              <Grid container justify="center">
                <Button
                  id="view_test_btn"
                  variant="outlined"
                  onClick={() => {
                    history.push('/tests/visualization/' + data.assessment.id)
                  }}
                  color="primary"
                  startIcon={<Icon>visibility</Icon>}>
                  Visualizar Teste
                </Button>{' '}
              </Grid>
              <EditTestForm assessment={data.assessment} />
            </Box>
          </Grid>
          <Grid item lg={12} xs={12} md={12}>
            <Box mt={3}>
              <EditTestQuestionsForm assessment={data.assessment} />
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default EditTestPage
