import gql from "graphql-tag";

export const DELETE_QUESTION = gql`
  mutation DELETE_QUESTION($id: Long!) {
    deleteQuestion(id: $id)
  }
`;
export const CREATE_OR_UPDATE_QUESTIONS = gql`
  mutation CREATE_OR_UPDATE_QUESTIONS(
    $assessmentId: Long!
    $questions: [QuestionInput]
  ) {
    createOrUpdateQuestions(
      assessmentId: $assessmentId
      questions: $questions
    ) {
      id
      title
      subtitle
      instructions
      questions {
        id
        text
        questionType
        choices {
          id
          text
          score
        }
      }
    }
  }
`;
export const CREATE_OR_UPDATE_QUESTION = gql`
  mutation CREATE_OR_UPDATE_QUESTION(
    $assessmentId: Long!
    $id: Long
    $text: String
    $questionType: String
    $choices: [ChoiceInput]
  ) {
    createOrUpdateQuestion(
      assessmentId: $assessmentId
      id: $id
      text: $text
      questionType: $questionType
      choices: $choices
    ) {
      id
      text
      questionType
      choices {
        id
        text
        score
      }
    }
  }
`;
