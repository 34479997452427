import React from 'react'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
export function HTMLQuestionTitle({ chip, current, text, size }) {
  return (
    <div>
      <Typography variant="caption" style={{ fontWeight: 'bold' }}>
        Questão {current} de {size} {chip}
      </Typography>
      {text && (
        <div
          dangerouslySetInnerHTML={{
            __html: text + '' || ''
          }}></div>
      )}
    </div>
  )
}
HTMLQuestionTitle.propTypes = {
  current: PropTypes.number.isRequired
}
