import { Box, Button, Grid, Icon, IconButton, Link } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionActions from '@material-ui/core/AccordionActions'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import history from '_utils/history'
import { toLocalDate } from '_utils/moment'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  secondaryHeading: {
    color: theme.palette.text.secondary
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20
  },
  details: {
    alignItems: 'center'
  },
  column: {
    flexBasis: '33.33%'
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))

function TestListAccordeon({ items, filtering, onShare, onArchive, duplicateAssessment }) {
  document.title = 'Question - Listagem Testes'
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {items &&
        items.length > 0 &&
        items.map((item) => (
          <Accordion TransitionProps={{ unmountOnExit: true }} key={item.id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={'panel1c-content-' + item.id}
              id={'panel1c-header-' + item.id}>
              <Grid container justify="center" alignContent="center" alignItems="center">
                <Grid item xs={12} md={5}>
                  <Typography variant="h6">{item.title}</Typography>
                  <Typography variant="caption">Cod. {item.id}</Typography>
                </Grid>{' '}
                <Grid item xs={12} md={5}>
                  <div className={classes.column}>
                    <Typography className={classes.secondaryHeading} variant="body1"></Typography>{' '}
                  </div>{' '}
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    size="small"
                    variant="outlined"
                    disableElevation
                    startIcon={<Icon>launch</Icon>}
                    onClick={() => {
                      onShare(item)
                    }}>
                    Aplicar Teste
                  </Button>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <Grid container>
                <Grid item xs={12} md={4} className={clsx(classes.helper)}>
                  <div style={{ display: 'flex' }}>
                    Título: <Typography variant="caption">{item.title}</Typography>
                  </div>
                  <div style={{ display: 'flex' }}>
                    Questões: <Typography variant="caption">{item.questions.length}</Typography>
                  </div>
                  <div style={{ display: 'flex' }}>
                    Tempo Controlado:
                    <Typography variant="caption">
                      {item.timeControlled ? 'Sim' : 'Não'}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex' }}>
                    Mostrar resultado após conclusão:
                    <Typography variant="caption">
                      {item.showResultsAfterFinish ? 'Sim' : 'Não'}
                    </Typography>
                  </div>
                </Grid>
                {item.subtitle && (
                  <Grid item xs={12} md={4} className={clsx(classes.helper)}>
                    <div> Descrição Interna: </div>
                    <Typography variant="caption">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.subtitle
                        }}></div>
                    </Typography>
                  </Grid>
                )}
                {item.instructions && (
                  <Grid item xs={12} md={4} className={clsx(classes.helper)}>
                    <div> Instruções Avaliado: </div>
                    <Typography variant="caption">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.instructions
                        }}></div>
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
            <Divider />
            <AccordionActions>
              <Grid container item xs={12} md={12} direction="row" component={Box} pl={1}>
                <Grid item xs={6} md={6}>
                  <div style={{ display: 'flex 1 auto' }}>
                    <Typography variant="caption">
                      Criação:{toLocalDate(item.createdAt)}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex 1 auto' }}>
                    <Typography variant="caption">
                      Atualizado: {item.updatedAt && toLocalDate(item.updatedAt)}
                    </Typography>
                  </div>
                </Grid>
                <Grid container item xs={12} md={6} justify="flex-end">
                  <Link to={'/tests/visualization/' + item.id} component={RouterLink}>
                    <IconButton title="Visualizar Teste">
                      <Icon>visibility</Icon>
                    </IconButton>
                  </Link>
                  <IconButton
                    disabled={item.questions.length === 0}
                    title="Aplicar Teste"
                    onClick={() => {
                      onShare(item)
                    }}>
                    <Icon>launch</Icon>
                  </IconButton>
                  <IconButton
                    title="Editar Teste"
                    onClick={() => history.push(`/tests/edit/${item.id}`)}>
                    <Icon>edit</Icon>
                  </IconButton>
                  <IconButton
                    title="Resultados Associados"
                    onClick={() => history.push(`/tests/results?assessmentId=${item.id}`)}>
                    <Icon>find_in_page</Icon>
                  </IconButton>
                  <IconButton
                    title="Duplicar Teste"
                    onClick={() => {
                      duplicateAssessment({
                        variables: { assessmentId: item.id }
                      })
                    }}>
                    <Icon>file_copy</Icon>
                  </IconButton>
                  <IconButton
                    color="primary"
                    title="Arquivar Teste"
                    onClick={() => {
                      onArchive(item)
                    }}>
                    <Icon>delete_outline</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            </AccordionActions>
          </Accordion>
        ))}
      <Grid container item md={12} justify="center" component={Box} p={3}>
        <Link to="/manage/tests/new" component={RouterLink}>
          <Button size="large" startIcon={<Icon>add</Icon>}>
            Novo Teste
          </Button>
        </Link>
      </Grid>
    </div>
  )
}

export default TestListAccordeon
