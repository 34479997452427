import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText'
import Slide from '@material-ui/core/Slide'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  }
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})
SimpleDialog.defaultProps = {
  showClose: true
}
export default function SimpleDialog({
  title,
  content,
  open,
  onClose,
  showDialogActions,
  disableBackdropClick,
  disableEscapeKeyDown,
  showClose,
  maxWidth
}) {
  const [_open, setOpen] = React.useState(open || false)
  const handleClose = () => {
    setOpen(false)
    onClose()
  }
  React.useEffect(() => {
    if (open) {
      setOpen(open)
    }
  }, [open])
  return (
    <Dialog
      open={_open}
      fullWidth={true}
      maxWidth={maxWidth}
      TransitionComponent={Transition}
      keepMounted
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      {showClose ? (
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
      ) : (
        <DialogTitle id="customized-dialog-title">{title}</DialogTitle>
      )}
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-slide-description"></DialogContentText> */}
        {content}
      </DialogContent>
      {showDialogActions === true && (
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outline">
            Fechar
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
