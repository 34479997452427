import { Grid, Icon, InputAdornment, Typography } from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import React from 'react'
import { toMoment } from '_utils/moment'

export default function SharingConfig({ config, onConfigChange }) {
  // The first commit of Material-UI
  const [_config, setConfig] = React.useState(config || {})

  const handleDateChange = (date) => {
    setConfig({ hardDueDate: toMoment(date).format() })
  }
  React.useEffect(() => {
    onConfigChange(_config)
  }, [_config, onConfigChange])
  return (
    <Grid item xs={12} md={4}>
      <Typography variant="h6">Configure seu compartilhamento</Typography>
      <DateTimePicker
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon color="disabled">date_range</Icon>
            </InputAdornment>
          )
        }}
        placeholder="Data limite personalizada para este teste (Opcional)"
        format="dd/MMM/yyyy 'às' HH:mm"
        value={_config.hardDueDate}
        onChange={handleDateChange}
        showTodayButton
      />
    </Grid>
  )
}
