import { Avatar, Box, Button, Grid, Hidden, Icon, Link } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { fade, makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MailIcon from '@material-ui/icons/Mail'
import MenuIcon from '@material-ui/icons/Menu'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { ROLES } from 'components/Layout/Navigation/NavItens'
import SimpleDialog from 'components/Messaging/SimpleDialog'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { logout } from '_actions/user.actions'
import config from '_utils/config'
import history from '_utils/history'
import { support_format_webp } from '_utils/tools'

var Tawk_API = window.Tawk_API
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 2
  },
  menuButton: {
    marginRight: theme.spacing(0)
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}))
export const getHomeByProfile = () => {
  if (sessionStorage.getItem('me') === 'undefined') {
    logout()
  }

  return '/home'
}

function isAccountAdmin(element, index, array) {
  return element.name === ROLES.ROLE_ADMIN
}

export const isCurrentProfileAccountAdmin = () => {
  const profile = JSON.parse(sessionStorage.getItem('me'))
  return profile && profile.roles.some(isAccountAdmin)
}

const DIALOG_CHECK_INTERVAL = 1000 * 60
const AppToolBar = (props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const [profile] = React.useState(props.profile)
  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  const [isDialogOpen, setDialogOpen] = React.useState(false)
  React.useEffect(() => {
    if (Tawk_API && Tawk_API.setAttributes && profile) {
      // Tawk_API.setAttributes(
      //   {
      //     name: profile.fullName,
      //     email: profile.email,
      //     organization: profile.tenant.organization,
      //     subscription: profile.tenant.subscription && profile.tenant.subscription.plan.name
      //   },
      //   function (error) {
      //     console.error(Tawk_API, error)
      //   }
      // )
    }
    function evalTrialEnded() {
      return (
        (profile &&
          profile.tenant &&
          profile.tenant.trial &&
          !profile.tenant.skipLimits &&
          profile.tenant.remainingDays === 0 &&
          window.location.pathname !== '/pricing' &&
          window.location.pathname.indexOf('/selection') === -1 &&
          window.location.pathname.indexOf('/subscribed') === -1) ||
        false
      )
    }
    async function checkDialog() {
      setTimeout(() => {
        setDialogOpen(evalTrialEnded())
      }, DIALOG_CHECK_INTERVAL)
    }
    checkDialog()
  }, [profile, isDialogOpen])

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null)
  }

  function handleMenuClose() {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      <MenuItem
        onClick={() => {
          handleMenuClose()
          history.push('/profile')
        }}>
        Perfil
      </MenuItem>
      <MenuItem
        onClick={() => {
          props.onLogout()
        }}>
        Sair
      </MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <MenuItem style={{ display: 'none' }}>
        <IconButton aria-label="Show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Mensagens</p>
      </MenuItem>
      <MenuItem style={{ display: 'none' }}>
        <IconButton aria-label="Show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notificações</p>
      </MenuItem>

      <MenuItem onClick={handleProfileMenuOpen}>
        {profile && profile.avatarUrl ? (
          <Avatar src={profile.avatarurl} />
        ) : (
          <IconButton
            aria-label="Account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit">
            <AccountCircle />{' '}
          </IconButton>
        )}

        <p>Perfil</p>
      </MenuItem>
    </Menu>
  )
  const MenuPart = ({ handleVisibility }) => {
    return (
      <IconButton edge="start" color="inherit" aria-label="Open drawer" onClick={handleVisibility}>
        <MenuIcon />
      </IconButton>
    )
  }
  const LogoPart = (_logoProps) => {
    return (
      <div
        style={{
          position: 'relative',
          left: '0',
          top: '0',
          display: 'inline-grid'
        }}>
        <Link to={getHomeByProfile()} component={RouterLink} style={{ color: 'white' }}>
          <Hidden mdDown>
            {' '}
            <img
              style={{
                height: '38px',
                verticalAlign: 'middle'
              }}
              src={support_format_webp() ? '/images/logo_white.webp' : '/images/logo_white.png'}
              alt="logo"
            />{' '}
          </Hidden>
        </Link>
        <span>{_logoProps.organization}</span>
      </div>
    )
  }

  return (
    <Grid>
      <AppBar position="static">
        <Toolbar>
          {props.showMenu && <MenuPart handleVisibility={props.handleVisibility} />}
          {props.showLogo && (
            <LogoPart
              organization={
                profile &&
                profile.tenant &&
                profile.tenant.organization &&
                ' ' + profile.tenant.organization
              }
            />
          )}

          <div className={classes.grow} />
          {props.showButtons && (
            <div className={classes.sectionDesktop}>
              <IconButton
                aria-label="Show 4 new mails"
                color="inherit"
                style={{ display: 'none' }}>
                <Badge badgeContent={4} color="secondary">
                  <MailIcon />
                </Badge>
              </IconButton>
              <IconButton
                aria-label="Show 17 new notifications"
                color="inherit"
                style={{ display: 'none' }}>
                <Badge badgeContent={17} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>

              <Button
                style={{ color: 'white' }}
                disableElevation
                startIcon={<Icon>help_outline</Icon>}
                onClick={() =>
                  window.open(config.frontend.LANDINGPAGE_URL + '/faq', '_blank').focus()
                }>
                Perguntas Frequentes
              </Button>
              {window.location.pathname === '/tests' ? (
                <Button
                  style={{ color: 'white' }}
                  disableElevation
                  startIcon={<Icon>find_in_page</Icon>}
                  onClick={() => history.push('/tests/results')}>
                  Resultados
                </Button>
              ) : (
                <Button
                  disableElevation
                  color="primary"
                  startIcon={<Icon>view_list</Icon>}
                  onClick={() => history.push('/tests')}>
                  Meus Testes
                </Button>
              )}
              <Button
                color="primary"
                component={RouterLink}
                disableElevation
                to={`/pricing`}
                startIcon={<Icon className="blob">loyalty</Icon>}>
                {profile && profile.tenant.subscription ? 'Fazer upgrade' : 'Escolher um plano'}
              </Button>
              <Button
                style={{ color: 'white' }}
                disableElevation
                startIcon={<Icon>help_outline</Icon>}
                onClick={() =>
                  window.open(config.frontend.LANDINGPAGE_URL + '/contact', '_blank').focus()
                }>
                Ajuda
              </Button>
            </div>
          )}
          {props.showProfile && (
            <IconButton
              aria-label="Account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit">
              {profile && profile.avatarUrl ? (
                <Avatar src={profile.avatarUrl} alt="profile pic" />
              ) : (
                <AccountCircle />
              )}
            </IconButton>
          )}
          <Hidden mdDown>
            <span>{profile && profile.fullName}</span>
          </Hidden>
          {/* <div className={classes.sectionMobile}>
              <IconButton
                aria-label="Show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit">
                <MoreIcon />
              </IconButton>
            </div> */}
        </Toolbar>
        {/* )} */}
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {props.children}
      <SimpleDialog
        disableEscapeKeyDown={true}
        showDialogActions={false}
        disableBackdropClick={true}
        showClose={true}
        onClose={() => {
          setTimeout(() => {
            setDialogOpen(false)
          }, DIALOG_CHECK_INTERVAL)
        }}
        title="Seu tempo de experiência acabou!"
        content={
          <Grid container justify="center" component={Box} p={3}>
            <Typography>
              As funcionalidades serão automaticamente reduzidas a partir de agora. Clique no botão
              abaixo e escolha a opção que melhor se encaixar para você.
            </Typography>
            <Button to={'/pricing'} component={RouterLink}>
              Ver Planos
            </Button>
          </Grid>
        }
        open={isDialogOpen}
      />
    </Grid>
  )
}
AppToolBar.defaultProps = {
  showLogo: true,
  showButtons: true,
  showProfile: true,
  showMenu: true
}
export default AppToolBar
