import { useMutation, useQuery } from '@apollo/client'
import { Box, Button, Grid, Icon, Paper, Typography } from '@material-ui/core'
import Loading from 'components/Loading'
import gql from 'graphql-tag'
import React from 'react'
import 'video-react/dist/video-react.css'
import { handleApolloErrors } from '_services/apollo'
import { support_format_webp } from '_utils/tools'
import TestExecutionPage from './TestExecutionPage'

function StartMessage({ classes, title, email, onButtonClick }) {
  return (
    <React.Fragment>
      <Grid
        container
        style={{
          padding: '2rem',
          textAlign: 'center',
          minHeight: '100vh'
        }}
        justify="center"
        xs={8}
        alignContent="center">
        <Paper square>
          <Grid container md={12} direction="horizontal" component={Box} p={3}>
            <Grid item md={6}>
              <img style={{ width: '100%' }} src="/images/freepik/Exams.gif" alt="logo" />
            </Grid>
            <Grid item md={6}>
              <Grid container justify="center" component={Box} p={3}>
                <img className="logo" src="/images/logo.webp" alt="logo" />
              </Grid>
              <Typography variant="h4">{title}</Typography>{' '}
              <Typography variant="caption" component="div" style={{ margin: '1rem' }}>
                Email avaliado: {email}
              </Typography>
              <Typography variant="subtitle2" style={{ textAlign: 'justify' }}>
                Atenção, uma vez iniciado o teste <b>não atualize a página</b>, a execução do teste
                se dará de maneira contínua, caso o teste seja interrompido fechando-se a aba, ou o
                teste seja por tempo limitado o tempo continuará a ser contabilizado. Em caso de
                dúvidas sobre a execução entre em contato com o responsável pelo teste.
              </Typography>{' '}
              <br />
              <Button
                color="primary"
                size="large"
                startIcon={<Icon>play_arrow</Icon>}
                onClick={() => onButtonClick()}>
                Iniciar Teste
              </Button>
            </Grid>
          </Grid>{' '}
        </Paper>
      </Grid>
    </React.Fragment>
  )
}
function ExecutionNotAvailableMessage({ classes, title, email, onButtonClick }) {
  return (
    <React.Fragment>
      <Grid
        container
        style={{
          minHeight: '100vh'
        }}
        justify="center"
        md={6}
        alignItems="center"
        alignContent="center">
        <Paper>
          <Grid container md={12} component={Box} p={3}>
            <Grid item md={12}>
              <Grid container justify="center" alignItems="center" direction="column">
                <img
                  className="logo"
                  src={support_format_webp() ? '/images/logo.webp' : '/images/logo.png'}
                  alt="logo"
                />
                <img style={{ width: '35%' }} src="/images/not_found.png" alt="logo" />{' '}
                <Grid item xs={12}>
                  <Typography variant="h4">{title}</Typography>{' '}
                  <Typography variant="h6" style={{ textAlign: 'justify' }}>
                    Este teste não está disponível para execução, entre em contato com o avaliador
                    informando a situação.
                  </Typography>{' '}
                  <br />
                  Algumas causas possíveis:
                  <ul>
                    <li>O Período de execução ja expirou</li>
                    <li>O teste ja foi previamente submetido</li>
                    <li>Algum problema com a comunição do teste</li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  )
}

function TestEntryPointPage(props) {
  document.title = 'Question - Execução de Testes - Entrada'
  const query = new URLSearchParams(props.location.search)
  const { data, loading } = useQuery(RETRIEVE_CANDIDATE_ASSESSMENT, {
    name: 'getCandidateAssessment',
    variables: {
      id: props.match.params.id,
      code: query.get('code')
    },
    fetchPolicy: 'no-cache',
    onError: (error) => handleApolloErrors(error)
  })
  let candidateAssessment = data?.retrieveCandidateAssessment

  const [startCandidateAssessment] = useMutation(START_CANDIDATE_ASSESSMENT, {
    name: 'startCandidateAssessment',
    variables: {
      id: props.match.params.id,
      code: query.get('code')
    },
    fetchPolicy: 'no-cache',
    onError: (error) => handleApolloErrors(error),
    onCompleted: (results) => {
      window.location.reload()
    }
  })

  return loading ? (
    <Loading />
  ) : candidateAssessment && candidateAssessment.startedAt ? (
    <TestExecutionPage candidateAssessmentId={candidateAssessment.id} code={query.get('code')} />
  ) : (
    <Grid direction="column" container alignItems="center" justify="center">
      {candidateAssessment ? (
        <StartMessage
          title={candidateAssessment.assessment.title}
          onButtonClick={() => startCandidateAssessment()}
          timeControlled={candidateAssessment.assessment.timeControlled}
          email={candidateAssessment.candidate.email}
        />
      ) : (
        <ExecutionNotAvailableMessage />
      )}
    </Grid>
  )
}
const RETRIEVE_CANDIDATE_ASSESSMENT = gql`
  query RETRIEVE_CANDIDATE_ASSESSMENT($id: Long!, $code: String!) {
    retrieveCandidateAssessment(id: $id, code: $code) {
      id
      # executionEnabled
      startedAt
      candidate {
        fullName
        email
      }
      config {
        id
        dueDateAt
        executionEnabled
      }
      assessment {
        title
        timeControlled
      }
    }
  }
`

const START_CANDIDATE_ASSESSMENT = gql`
  mutation START_CANDIDATE_ASSESSMENT($id: Long!, $code: String!) {
    startCandidateAssessment(id: $id, code: $code) {
      id
      startedAt
    }
  }
`

export default TestEntryPointPage
