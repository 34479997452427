import * as Yup from 'yup'
import { cnpj as isCNPJ, isCPF } from '_utils/tools'
var numberPattern = /\d+/gi

export default () => {
  return Yup.object().shape({
    documentType: Yup.string(),
    organization: Yup.string().when('documentType', (documentType) => {
      return documentType === 'CPF'
        ? Yup.string().nullable()
        : Yup.string().required('Campo Obrigatório')
    }),
    fullName: Yup.string().required('Campo Obrigatório'),
    phone: Yup.string().required('Campo Obrigatório'),
    email: Yup.string().email('Email inválido').required('Campo Obrigatório'),
    password: Yup.string()
      .min(6, "O campo 'Senha' deve ter no mínimo 6 caracteres.")
      .required('Campo Obrigatório'),
    passwordConfirmation: Yup.string()
      .required('Campo Obrigatório')
      .oneOf([Yup.ref('password'), null], 'Senhas não conferem'),
    acceptedTACandPP: Yup.boolean().oneOf([true], 'Aceite os termos e condições para continuar.'),
    document: Yup.string().when('documentType', (documentType) => {
      return documentType === 'CPF'
        ? Yup.string()
            .test('cpf-isvalid', 'Documento inválido', (value) => {
              if (!value) {
                return false
              }
              var r = value.match(numberPattern).join('')
              if (r.length === 11) {
                return isCPF(value)
              }
            })
            .required('Campo Obrigatório')
        : Yup.string()
            .test('cnpj-isvalid', 'Documento inválido', function (value) {
              if (!value) {
                return false
              }
              var r = value.match(numberPattern).join('')
              if (r.length === 14) {
                return isCNPJ(value)
              }
            })
            .required('Campo Obrigatório')
    })
  })
}
