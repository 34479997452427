import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import { authentication } from '_reducers/authentication.reducer'
import { registration } from '_reducers/registration.reducer'
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const reduxLogger = createLogger({
  diff: true,
  collapsed: true
})
const FetchInterceptor = require('fetch-interceptor')

// eslint-disable-next-line
const interceptor = FetchInterceptor.register({
  onBeforeRequest(request, controller) {
    const query = new URLSearchParams(window.location.search)
    const param_token = query.get('token')
    if (param_token) {
      sessionStorage.setItem('access_token', param_token)
    }
  },
  onRequestSuccess(response, request, controller) {},
  onRequestFailure(response, request, controller) {}
})

export default () => {
  const rootReducer = combineReducers({
    authentication,
    registration,
    form: formReducer
  })

  const buildStore = () => {
    if (process.env.NODE_ENV !== 'production') {
      if (module.hot) {
        return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, reduxLogger)))
      }
    } else {
      return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
    }
  }

  const store = buildStore()

  return store
}
