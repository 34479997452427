import { ButtonGroup, Divider, Icon, List, ListItem, ListItemText } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Copyright from 'components/Layout/Footer/Copyright'
import withNavBar from 'components/Layout/withNavBar'
import Loading from 'components/Loading'
import config from '_utils/config'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import EmptyMessage from 'components/Messaging/EmptyMessage'
import { formatBytes } from '_utils/tools'
import { labels } from '_utils/appConstants'

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    },
    ul: {
      margin: 0,
      padding: 0
    },
    li: {
      listStyle: 'none'
    }
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbar: {
    flexWrap: 'wrap'
  },
  toolbarTitle: {
    flexGrow: 1
  },
  link: {
    margin: theme.spacing(1, 1.5)
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6)
  },
  content: {
    height: '100%'
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200]
  },
  cardPricing: {
    textAlign: 'center',
    margin: theme.spacing(1)
  },
  grow: {
    flexGrow: 2
  },
  cardPrice: { fontSize: '2em' },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    }
  }
}))

function Pricing({ plans }) {
  document.title = 'Question - Planos e Preços'
  const classes = useStyles()
  const [mode, setMode] = React.useState('MONTHLY')
  const [currentTier, setCurrentTier] = React.useState(100)
  const [currentTenant] = React.useState(
    sessionStorage.getItem('me') && JSON.parse(sessionStorage.getItem('me')).tenant
  )

  const [filteredPlans, setFilteredPlans] = React.useState([])
  React.useEffect(() => {
    if (currentTenant && currentTenant.subscription) {
      let plan = currentTenant.subscription.plan
      setCurrentTier(plan.tier)
      setFilteredPlans(plans.filter((p) => p.tier < currentTier))
    } else {
      setCurrentTier(100)
      setFilteredPlans(plans.filter((p) => p.tier < currentTier))
    }
  }, [plans, currentTenant, currentTier])
  return plans && plans.length > 0 ? (
    <React.Fragment>
      <Container maxWidth="md" component="main" className={classes.heroContent}>
        <Typography component="h4" variant="h4" align="center" color="textPrimary" gutterBottom>
          Escolha o plano adequado para você
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" component="p">
          Analise o plano que melhor se encaixa para os seus negócios e aumente a produtividade de
          sua equipe.
        </Typography>
        <br />{' '}
        <Grid container justify="center" component={Box} p={3}>
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            <Button
              id={'plan-period-monthly'}
              variant={mode === 'MONTHLY' ? 'contained' : 'outlined'}
              onClick={(e) => setMode('MONTHLY')}>
              Mensal
            </Button>

            <Button
              id={'plan-period-quarterly'}
              variant={mode === 'QUARTERLY' ? 'contained' : 'outlined'}
              onClick={(e) => setMode('QUARTERLY')}>
              Trimestral
            </Button>
            {/* )} */}
          </ButtonGroup>{' '}
        </Grid>
        <Grid container spacing={5} alignItems="center">
          {filteredPlans &&
            filteredPlans.map((plan, index) => (
              <Grid item key={index} xs={12} sm={12} md={4}>
                <Card elevation={6}>
                  <CardHeader
                    title={plan.name}
                    subheader={plan.description}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    action={index === 1 ? <Icon>star</Icon> : null}
                    className={classes.cardHeader}></CardHeader>{' '}
                  <div className={classes.cardPricing}>
                    <div style={{ display: 'block' }}>
                      R${' '}
                      <span style={{ fontSize: '2em' }}>
                        {mode === 'MONTHLY' &&
                          plan &&
                          plan.prices &&
                          (plan.prices[mode.toLowerCase()] / 100).toFixed(2).replace('.', ',')}
                        {mode === 'QUARTERLY' &&
                          plan &&
                          plan.prices &&
                          (plan.prices[mode.toLowerCase()] / 100 / 3).toFixed(2).replace('.', ',')}
                        {mode === 'YEARLY' &&
                          plan &&
                          plan.prices &&
                          (plan.prices[mode.toLowerCase()] / 100 / 12)
                            .toFixed(2)
                            .replace('.', ',')}
                      </span>{' '}
                      /Mês
                    </div>
                    <Typography variant="caption" color="textSecondary">
                      {'Cobrado ' + labels[mode]}
                    </Typography>{' '}
                    <CardActions style={{ display: 'block' }}>
                      <Button
                        id={'plan-selected-' + plan.name}
                        to={`/subscription/selection?planId=${plan.id}&mode=${mode}`}
                        component={RouterLink}
                        color="primary">
                        {plan.callToAction || 'Escolher'}
                      </Button>
                    </CardActions>
                  </div>
                  <CardContent className={classes.cardPricing}>
                    {plan.limits && (
                      <List dense>
                        {plan.limits.applications && (
                          <>
                            <ListItem button>
                              <ListItemText
                                style={{ textAlign: 'center' }}
                                primary={plan.limits.assessments}
                                secondary="Testes ativos"
                              />
                            </ListItem>
                            <Divider light />
                          </>
                        )}
                        {plan.limits.applications && (
                          <>
                            <ListItem button>
                              <ListItemText
                                style={{ textAlign: 'center' }}
                                primary={plan.limits.applications}
                                secondary="Aplicações de testes mensais"
                              />
                            </ListItem>
                            <Divider light />
                          </>
                        )}

                        {plan.limits.applications && (
                          <>
                            <ListItem button>
                              <ListItemText
                                style={{ textAlign: 'center' }}
                                primary={formatBytes(plan.limits.uploads)}
                                secondary="Espaço de armazenagem para mídias de testes"
                              />
                            </ListItem>
                            <Divider light />
                          </>
                        )}

                        {plan.limits.applications && (
                          <>
                            <ListItem button>
                              <ListItemText
                                style={{ textAlign: 'center' }}
                                primary={plan.limits.subUsers}
                                secondary="Total de Usuários"
                              />
                            </ListItem>{' '}
                          </>
                        )}
                      </List>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </React.Fragment>
  ) : (
    <EmptyMessage
      title="Nenhum plano ativo no momento"
      subtitle="Entre em contato com nosso suporte para auxiliar no processo"
    />
  )
}
const ConnectedPricing = (props) => {
  const [plans, setPlans] = React.useState([])
  const [error, setError] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const fetchData = async () => {
    await fetch(config.backend.BACKEND_URL + `/plans`, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json'
      })
    })
      .then((res) => res.json())
      .then((response) => {
        setPlans(response)
        setLoading(false)
      })
      .catch((e) => setError(e))
  }

  React.useEffect(() => {
    fetchData()
  }, [])
  return loading ? (
    <Loading />
  ) : (
    <>
      {error && <span>{error}</span>}
      <Pricing plans={plans} />
    </>
  )
}
export default withNavBar(ConnectedPricing, {
  showMenu: false,
  showProfile: true,
  showButtons: false
})
