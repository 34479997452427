import React from 'react'
import { Grid, Paper, makeStyles } from '@material-ui/core'
import ConnectedSignUpForm from './SignUpForm'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundImage: 'url(/images/bg3.jpeg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }
}))

export function SignUpPage(props) {
  document.title = 'Question - Nova conta'
  const classes = useStyles()

  return (
    <Grid container component="main" direction="row" className={classes.root}>
      <Grid
        xs={12}
        sm={8}
        md={5}
        item
        component={Paper}
        elevation={6}
        square
        container
        direction="row"
        alignContent="center"
        justify="center">
        <ConnectedSignUpForm {...props} />
      </Grid>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
    </Grid>
  )
}
function mapStateToProps(state, props) {
  const { authentication } = state
  const query = new URLSearchParams(props.location && props.location.search.replace('&', '~'))
  return {
    loading: authentication.loading,
    email: query.get('email') || '',
    password: query.get('password') || '',
    forwardUrl: (query.get(`forwardUrl`) && query.get(`forwardUrl`).replace('~', '&')) || ''
  }
}

export default connect(mapStateToProps)(SignUpPage)
