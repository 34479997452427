import gql from 'graphql-tag'

export const SAVE_TEST_RESULTS = gql`
  mutation saveCandidateAnswers($input: CandidateAnswersInput) {
    saveCandidateAnswers(input: $input) {
      id
      assessment {
        showResultsAfterFinish
      }
    }
  }
`
export const SAVE_TEST_FEEDBACK = gql`
  mutation saveCandidateFeedback($input: CandidateFeedbackInput) {
    saveCandidateFeedback(input: $input)
  }
`

export const RETRIEVE_CANDIDATE_ASSESSMENT = gql`
  query RETRIEVE_CANDIDATE_ASSESSMENT($id: Long!, $code: String!) {
    retrieveCandidateAssessment(id: $id, code: $code) {
      id
      score
      startedAt
      mailLink
      tenant {
        id
      }
      candidate {
        id
        email
        fullName
      }
      config {
        executionEnabled
        mediaTested
        dueDateAt
      }
      assessment {
        id
        title
        subtitle
        instructions
        timeControlled
        testDurationInMinutes
        questions {
          id
          text
          questionType
          required
          answerText
          choices {
            id
            text
          }
        }
      }
    }
  }
`
