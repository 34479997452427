import { Grid } from '@material-ui/core'
import EmptyMessage from 'components/Messaging/EmptyMessage'
import React from 'react'

export default function ThankYouMessage({ classes }) {
  return (
    <Grid
      container
      style={{
        padding: '2rem',
        textAlign: 'center'
      }}
      justify="center"
      alignContent="center">
      <Grid item>
        <EmptyMessage
          title="Obrigado!"
          subtitle="Resultado registrado, seu teste foi concluído com sucesso."
        />{' '}
      </Grid>
    </Grid>
  )
}
