import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  IconButton,
  RadioGroup
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  CustomCheckBoxField,
  CustomRadioField,
  CustomTextArea,
  CustomTextField
} from 'components/Layout/FormFields'
import { Field, FieldArray } from 'formik'
import React from 'react'
export const letters = 'abcdefghijklmnopqrstuvxzw'

export function SingleLineQuestion() {
  return (
    <Grid item md={12}>
      <Field disabled={true} placeholder="Resposta do avaliado." component={CustomTextField} />
    </Grid>
  )
}
export function MultipleChoiceQuestion(index, question, setFieldValue) {
  return (
    <FieldArray name={`questions[${index}].choices`}>
      {({ push, remove, name }) => {
        return (
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Marque a opção correta</FormLabel>
              <RadioGroup aria-label="score">
                {question.choices &&
                  question.choices.map((choice, choiceIndex) => {
                    return (
                      <Grid
                        key={choiceIndex}
                        container
                        direction="row"
                        alignContent="center"
                        alignItems="center">
                        <Grid item xs={2} md={1} lg={1}>
                          <Field
                            name={`${name}[${choiceIndex}].score`}
                            onClick={() => {
                              let newChoices = question.choices.map((item, _index) => {
                                return {
                                  ...item,
                                  score: 0
                                }
                              })
                              setFieldValue(`${name}`, newChoices)
                            }}
                            onChange={(e) => {
                              setFieldValue(`${name}[${choiceIndex}].score`, 1)
                            }}
                            component={CustomRadioField}
                            label={letters[choiceIndex] + '.'}
                          />
                        </Grid>
                        <Grid item xs={8} md={10} lg={10}>
                          <Field
                            multiline
                            fullWidth={true}
                            component={CustomTextArea}
                            name={`${name}[${choiceIndex}].text`}
                            placeholder={'Informe uma opção'}
                          />
                        </Grid>
                        <Grid item xs={1} md={1} lg={1}>
                          <IconButton onClick={() => remove(choiceIndex)}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )
                  })}
                <Grid item md={12} xs={12}>
                  <Button
                    color="default"
                    size="small"
                    onClick={() => push({})}
                    startIcon={<Icon>add</Icon>}>
                    Adicionar Opção
                  </Button>
                </Grid>
              </RadioGroup>{' '}
            </FormControl>
          </Grid>
        )
      }}
    </FieldArray>
  )
}

export function CheckBoxQuestion({ index, question, setFieldValue }) {
  return (
    <FieldArray name={`questions[${index}].choices`}>
      {({ push, remove, name }) => {
        return (
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup aria-label="score">
                <FormLabel component="legend">Marque as opções corretas</FormLabel>
                {question.choices &&
                  question.choices.map((choice, choiceIndex) => {
                    return (
                      <Grid
                        key={choiceIndex}
                        container
                        direction="row"
                        alignContent="center"
                        alignItems="center">
                        <Grid item xs={2} md={1} lg={1}>
                          <Field
                            name={`${name}[${choiceIndex}].score`}
                            checked={choice.score >= 1}
                            onChange={(e) => {
                              setFieldValue(
                                `${name}[${choiceIndex}].score`,
                                parseInt(e.target.value) === 0 ? 1 : 0
                              )
                            }}
                            component={CustomCheckBoxField}
                            label={letters[choiceIndex] + '.'}
                          />
                        </Grid>
                        <Grid item xs={8} md={10} lg={10}>
                          <Field
                            fullWidth={true}
                            multiline
                            component={CustomTextArea}
                            name={`${name}[${choiceIndex}].text`}
                            placeholder={'Informe uma opção'}
                          />
                        </Grid>
                        <Grid item xs={1} md={1} lg={1}>
                          <IconButton onClick={() => remove(choiceIndex)}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )
                  })}
              </RadioGroup>{' '}
            </FormControl>
            <Grid item md={12} xs={12}>
              <Button
                color="default"
                size="small"
                onClick={() => push({})}
                startIcon={<Icon>add</Icon>}>
                Adicionar Opção
              </Button>
            </Grid>
          </Grid>
        )
      }}
    </FieldArray>
  )
}
