import React from 'react'
import { Grid, CircularProgress, Typography, Box } from '@material-ui/core'

export default ({ message }) => (
  <Grid item component={Box} p={2}>
    <Grid item container justify="center">
      <CircularProgress />
    </Grid>
    <Grid item container justify="center">
      <Typography>{message || 'Aguarde, Processando...'}</Typography>
    </Grid>
  </Grid>
)
