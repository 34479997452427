import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Hidden,
  Typography
} from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
 
// const useStyles = makeStyles({
//   root: {
//     maxWidth: '100%', 
//     minHeight: 'auto'
//   },
//   media: {
//     height: 170,
//     objectFit: 'cover'
//   }
// })

export default function DashboardItem({ to, title, subtitle, description, icon, svgIcon, id }) {
  // const classes = useStyles()
  return (
    <Grid item md={3} xs={12}>
      <Link to={to}>
        <CardActionArea>
          <Card>
            <Hidden mdDown>
              <CardMedia image={icon} title={title}></CardMedia>
            </Hidden>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {title}
              </Typography>{' '}
              <Typography variant="inherit" color="textSecondary">
                {description}
              </Typography>
            </CardContent>
          </Card>
        </CardActionArea>
      </Link>
    </Grid>
  )
}
