import React from 'react'
import { Grid } from '@material-ui/core'
import ConnectedPasswordUpdateForm from 'pages/PasswordUpdate/PasswordUpdateForm'
class PasswordUpdatePage extends React.Component {
  render() {
    const query = new URLSearchParams(this.props.location.search)
    const token = query.get('token')
    const email = query.get('email')
    return (
      <Grid
        container
        className="bg-login"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}>
        <ConnectedPasswordUpdateForm token={token} email={email} />
      </Grid>
    )
  }
}

export default PasswordUpdatePage
