import React from "react";
import { Typography, Link, Box } from "@material-ui/core";

function Copyright() {
  return (
    <Box p={3}>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Todos os direitos reservados © "}
        <Link color="inherit" href="https://question.sistemasrh.com.br">
          Question
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </Box>
  );
}

export default Copyright;
