import { gql, useQuery } from '@apollo/client'
// import { LAST_EVENTS } from '_queries/STATS'

export const LAST_EVENTS = gql`
  query lastEvents($start: Int!, $end: Int!) {
    lastEvents(start: $start, end: $end) {
      actor
      createdAt
      description
      modifier
      name
      target
    }
  }
`
export default (limit = 10) => {
  return useQuery(LAST_EVENTS, {
    variables: { start: 0, end: limit }
  })
}
