import React from 'react'
import { Grid, Button } from '@material-ui/core'
import EmptyMessage from 'components/Messaging/EmptyMessage'
import { Link, withRouter } from 'react-router-dom'
function Subscribed(props) {
  return (
    <Grid container justify="center" alignContent="center">
      <EmptyMessage
        title="Sucesso!"
        subtitle="Sua assinatura foi realizada, você pode navegar normalmente que em breve enviaremos um email com mais informações"
        action={
          <Grid container justify="center" alignContent="center">
            {props && props.location.state && props.location.state.boletoUrl && (
              <Grid item xs={12}>
                <Button target="_blank" href={props.location.state.boletoUrl} color="primary">
                  Acessar Boleto
                </Button>
                <p>Se preferir você poderá acessar o boleto mais tarde a partir do seu perfil</p>
              </Grid>
            )}
            <Link to="/home" component={Button} variant="outlined">
              Ir para Página Principal
            </Link>
          </Grid>
        }
      />
    </Grid>
  )
}
export default withRouter(Subscribed)
