import React from 'react'
import { Grid, Link, Breadcrumbs, Typography } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import EditCandidateForm from './EditCandidateForm'
import gql from 'graphql-tag'
import { getHomeByProfile } from 'components/Layout/Navigation/AppToolBar'
import PageHeaderPath from 'components/PageHeaderPath'
import Loading from 'components/Loading'
import { useQuery } from '@apollo/client'

function EditCandidatePage(props) {
  document.title = 'Question - Edição Avaliado'
  const { data, loading } = useQuery(GET_CANDIDATE, {
    variables: {
      id: props.match.params.id
    }
  })
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <PageHeaderPath>
        <Breadcrumbs separator="›" aria-label="Breadcrumb">
          <Link to={getHomeByProfile()} component={RouterLink}>
            Home
          </Link>
          <Link to={'/candidates'} component={RouterLink}>
            Avaliados
          </Link>
          <Typography color="textPrimary"> Edição</Typography>
        </Breadcrumbs>
      </PageHeaderPath>

      {loading ? (
        <Loading />
      ) : (
        data &&
        data.candidate && (
          <Grid item md={4}>
            <EditCandidateForm candidate={data && data.candidate} />
          </Grid>
        )
      )}
    </Grid>
  )
}

const GET_CANDIDATE = gql`
  query GET_CANDIDATE($id: Long!) {
    candidate(id: $id) {
      id
      fullName
      email
      mobile
    }
  }
`

export default EditCandidatePage
