import gql from 'graphql-tag'

export const CREATE_SUBSCRIPTION = gql`
  mutation createSubscription(
    $planId: String!
    $creditCard: CreditCardDTOInput
    $billingAddress: BillingAddressDTOInput
    $paymentMethod: String!
    $billingMode: String!
  ) {
    createSubscription(
      planId: $planId
      creditCard: $creditCard
      billingAddress: $billingAddress
      paymentMethod: $paymentMethod
      billingMode: $billingMode
    ) {
      uuid
      boletoUrl
    }
  }
`
