import { useMutation, useQuery } from '@apollo/client'
import { Box, Button, Grid, Icon, Paper } from '@material-ui/core'
import { CustomSelectInput, CustomTextField } from 'components/Layout/FormFields'
import FormHeading from 'components/Layout/FormHeading'
import Loading from 'components/Loading'
import { Field, Form, Formik } from 'formik'
import gql from 'graphql-tag'
import React from 'react'
import { toast } from 'react-toastify'
import { handleApolloErrors } from '_services/apollo'
import { msgs } from '_utils/appConstants'
import history from '_utils/history'
import UserValidationSchema from './UserValidationSchema'
export const NewOperatorForm = (props) => {
  const { handleChange, roles, loading, handleSubmit } = props
  return (
    <Box p={3} component={Paper}>
      <Form onSubmit={handleSubmit} noValidate>
        <FormHeading>Novo Usuário</FormHeading>
        <Field name="fullName" component={CustomTextField} label="Nome Completo" />
        <Field name="email" component={CustomTextField} label="Email" />
        <Field
          name="id"
          component={CustomSelectInput}
          label="Selecione um perfil"
          options={roles}
          onChange={handleChange}
        />

        <Grid container item justify="center">
          {loading ? (
            <Loading />
          ) : (
            <Button type="submit" disabled={loading} startIcon={<Icon>save</Icon>}>
              Salvar
            </Button>
          )}
        </Grid>
      </Form>
    </Box>
  )
}

const ConnectedNewOperatorform = (props) => {
  const [tenants, setTenants] = React.useState([])
  const { data } = useQuery(GET_TENANTS, {})
  const [createUser] = useMutation(CREATE_USER, {
    name: 'createUser',
    update: (cache, { data: { createUser } }) => {
      cache.modify({
        fields: {
          allUsers(existingTenantsRef = [], { readField }) {
            if (existingTenantsRef.some((ref) => readField('id', ref) === createUser.id)) {
              return existingTenantsRef
            }

            return [...existingTenantsRef, createUser]
          }
        }
      })
    }
  })
  const roles = useQuery(GET_ROLES, {
    name: 'getAllRoles',
    onError: (error) => handleApolloErrors(error)
  })
  const mappedRolesData = React.useMemo(() => {
    const { data } = roles
    if (data) {
      return data.allRoles.map(({ __typename, ...item }) => ({
        label: item.label,
        id: item.id
      }))
    }
    return []
  }, [roles])
  React.useEffect(() => {
    data &&
      data.allTenants &&
      setTenants(
        data.allTenants &&
          data.allTenants.map((tenant) => ({
            label: tenant.organization || tenant.id,
            id: tenant.id
          }))
      )
  }, [roles, data])
  return (
    <Formik
      validationSchema={UserValidationSchema}
      initialValues={{
        email: '',
        fullName: '',
        roleId: '',
        tenantId: null
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)
        const variables = {
          email: values.email,
          fullName: values.fullName,
          roleId: values.id,
          tenantId: values.tenantId
        }
        createUser({ variables })
          .then((results) => {
            setSubmitting(false)
            toast.success(msgs.operationSuccess)
            history.push('/users')
          })
          .catch((error) => {
            handleApolloErrors(error)
          })
      }}>
      {(formProps) => (
        <NewOperatorForm
          {...formProps}
          loading={props.loading}
          roles={mappedRolesData}
          tenants={tenants}
        />
      )}
    </Formik>
  )
}
const CREATE_USER = gql`
  mutation createUser($email: String!, $fullName: String!, $roleId: Long!, $tenantId: Long) {
    createUser(email: $email, fullName: $fullName, roleId: $roleId, tenantId: $tenantId) {
      id
    }
  }
`
const GET_ROLES = gql`
  {
    allRoles {
      id
      label
      name
    }
  }
`

const GET_TENANTS = gql`
  {
    allTenants {
      id
      organization
    }
  }
`
export default ConnectedNewOperatorform
