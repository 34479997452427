const config = {
  tutorials: {
    TUTORIAL_BUTTONS_ENABLED: process.env.TUTORIAL_BUTTONS_ENABLED || true,
    TUTORIAL_TRENDING_DASHBOARD: process.env.TUTORIAL_TRENDING_DASHBOARD || false,
    TUTORIAL_TEST_PAGE: 'https://youtu.be/-t6mAxEOtrQ',
    TUTORIAL_EDIT_TEST_PAGE: 'https://youtu.be/PTgNSPMdaC4',
    TUTORIAL_VIEW_TEST_PAGE: 'https://youtu.be/x7pPuQmWcD4',
    TUTORIAL_USER_PAGE: '',
    TUTORIAL_CANDIDATE_PAGE: 'https://youtu.be/awZBrFd3arA',
    TUTORIAL_RESULT_PAGE: 'https://youtu.be/SzVWW0O9os8',
    TUTORIAL_DASHBOARD: 'https://www.youtube.com/playlist?list=PLFTCpeFNXt14nS9Prh3vPZyMk_AbmsUTH'
  },
  frontend: {
    LANDINGPAGE_URL: process.env.REACT_APP_LANDINGPAGE_URL
  },
  backend: {
    BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
    BACKEND_GRAPHQL_URL: process.env.REACT_APP_BACKEND_GRAPHQL_URL
  },
  storage: {
    CLOUD_NAME_STORAGE: process.env.REACT_APP_CLOUD_NAME_STORAGE,
    CLOUD_STORAGE_API: process.env.REACT_APP_CLOUD_STORAGE_API
  },
  payments: {
    ENC_KEY: process.env.REACT_APP_PAYMENTS_ENC_KEY
  },
  functional: {
    SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL
  },
  gtm: {
    gtmId: process.env.REACT_APP_GTM_ID,
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW
  }
}
export default {
  ...config
}
