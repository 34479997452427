import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Router } from 'react-router-dom'
import { PrivateRoute } from 'components/Layout/Navigation/PrivateRoute'
import history from '_utils/history'
import { ToastContainer } from 'react-toastify'
import NavigationWrapper from 'components/Layout/Navigation/Navigation'
import ResultsPage from 'pages/Results/ResultsPage'
import ForgotPassword from 'pages/ForgotPassword'
import ProfilePage from 'pages/Profile/ProfilePage'
import SignUpPage from 'pages/SignUp/SignUpPage'
import AdminHome from 'pages/Admin/Home'
import PasswordUpdatePage from 'pages/PasswordUpdate/PasswordUpdatePage'
import CandidatesPage from 'pages/Candidate/CandidatesPage'
import NewUserPage from 'pages/User/NewUserPage'
import UsersPage from 'pages/User/UsersPage'
import EditTestPage from 'pages/Tests/Edit/EditTestPage'
import TestVisualizationPage from 'pages/Tests/View/TestVisualizationPage'
import EditCandidatePage from 'pages/Candidate/Edit/EditCandidatePage'
import ViewCandidatePage from 'pages/Candidate/View/ViewCandidatePage'
import EditUserPage from 'pages/User/EditUserPage'
import TestsPage from 'pages/Tests/TestsPage'
import NewTestPage from 'pages/Tests/New/NewTestPage'
import SingleResultPage from 'pages/Results/SingleResultPage'
import SimpleTestVisualizationPage from 'pages/Candidate/TestView/SimpleTestResultVisualizationPage'
import SignInSide from 'pages/Login/SideLoginPage'
import Pricing from 'pages/Pricing/Pricing'
import Checkout from 'pages/Checkout/Checkout'
import Subscription from 'pages/Subscription/Subscription'
import { ThemeProvider } from '@material-ui/styles'
import defaultTheme from 'themes/default-theme'
import { CssBaseline } from '@material-ui/core'
import LogoutPage from 'pages/Logout'
import NewCandidatePage from 'pages/Candidate/New/NewCandidatePage'
import CandidateHomePage, {
  MyTestsPage,
  TestExecutionPage,
  TestEntryPointPage
} from 'pages/TestExecution'
import Subscribed from 'pages/Subscription/Subscribed'
import NotFoundPage from 'pages/NotFoundPage'
import config from '_utils/config'
import TagManager from 'react-gtm-module'

function App() {
  React.useEffect(() => {
    if (config && config.gtm && config.gtm.gtmId) {
      console.debug('GTM INIT')
      TagManager.initialize(config.gtm)
    }
  }, [])
  return (
    <Router history={history}>
      <ThemeProvider theme={defaultTheme}>
        <ToastContainer /> <CssBaseline />
        <Switch>
          <Route
            path={'/'}
            render={(props) => (
              <SignInSide {...props} changeTheme={(value) => this.changeTheme(value)} />
            )}
            exact={true}
          />
          <Route path="/login" component={SignInSide} exact={true} />
          <Route path="/signup" component={SignUpPage} exact={true} />
          <Route path="/logout" component={LogoutPage} exact={true} />
          <Route
            path="/tests/simple_results/:id"
            component={SimpleTestVisualizationPage}
            exact={true}
          />
          <Route path="/update-password" component={PasswordUpdatePage} exact={true} />
          <Route path="/forgot-password" component={ForgotPassword} exact={true} />
          <Route path="/candidate/tests/execute/:id" component={TestEntryPointPage} exact={true} />
          <Route path="/candidate/tests/execute2" component={TestExecutionPage} exact={true} />
          <Route path="/pricing" component={Pricing} exact={true} />{' '}
          <NavigationWrapper>
            <PrivateRoute
              path="/tests/visualization/:id"
              component={TestVisualizationPage}
              exact={true}
            />
            <PrivateRoute path="/subscription/selection" component={Subscription} exact={true} />
            <PrivateRoute path="/subscribed" component={Subscribed} exact={true} />
            <PrivateRoute path="/billing/checkout" component={Checkout} exact={false} />
            <PrivateRoute path="/home" component={AdminHome} exact={true} />
            <PrivateRoute path="/candidates" component={CandidatesPage} exact={true} />
            <PrivateRoute path="/new_candidate" component={NewCandidatePage} exact={true} />
            <PrivateRoute path="/new_user" component={NewUserPage} exact={true} />
            <PrivateRoute path="/users" component={UsersPage} exact={true} />
            <PrivateRoute path="/profile" component={ProfilePage} exact={true} />
            <PrivateRoute path="/manage/tests/new" component={NewTestPage} exact={true} />
            <PrivateRoute path="/tests" component={TestsPage} exact={true} />
            <PrivateRoute path="/tests/results" component={ResultsPage} exact={true} />
            <PrivateRoute path="/tests/results/:id" component={SingleResultPage} />
            <PrivateRoute path="/users/edit/:id" component={EditUserPage} exact={true} />
            <PrivateRoute path="/tests/edit/:id" component={EditTestPage} exact={true} />
            <PrivateRoute path="/candidates/edit/:id" component={EditCandidatePage} exact={true} />
            <PrivateRoute path="/candidates/view/:id" component={ViewCandidatePage} exact={true} />
            <PrivateRoute path="/candidate/home" component={CandidateHomePage} exact={true} />
            <PrivateRoute path="/candidate/tests" component={MyTestsPage} exact={true} />
          </NavigationWrapper>{' '}
          <Route component={NotFoundPage} />
        </Switch>
      </ThemeProvider>
    </Router>
  )
}

export default App
