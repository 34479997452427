import { useQuery } from '@apollo/client'
import { Box, Card, CardContent, Grid, Hidden, Typography, useTheme } from '@material-ui/core'
import DashboardItem from 'components/DashboardItem'
import HelpButton from 'components/HelpButton'
import FormHeading from 'components/Layout/FormHeading'
import { ProfileContext } from 'components/Layout/Navigation/Navigation'
import Loading from 'components/Loading'
import EventsContainer from 'components/Stats/EventsContainer'
import React, { useContext } from 'react'
import { Bar } from 'react-chartjs-2'
import YouTube from 'react-youtube'
import { SHARING_LAST_30D } from '_queries/STATS'
import config from '_utils/config'

export const Greetings = (props) => (
  <Hidden xsDown>
    <Box pb={2} pt={2}>
      <Typography variant="h6">
        Bem vindo ao Question <HelpButton link={config.tutorials.TUTORIAL_DASHBOARD} />
      </Typography>
      <Typography variant="body1">
        Administre seus testes online, analise resultados e aumente o seu conhecimento sobre seus
        processos através de nossa plataforma
      </Typography>
    </Box>
  </Hidden>
)
export default (props) => {
  document.title = 'Question - Admin Home'
  const profile = useContext(ProfileContext) || {}
  const opts = {
    width: '100%',
    playerVars: {}
  }
  const SharingChart = () => {
    const { loading, data } = useQuery(SHARING_LAST_30D, {})
    const theme = useTheme()
    var _data = {
      labels: data && data.sharingLast30Days.map((item) => item.created),
      datasets: [
        {
          label: '# de compartilhamentos',
          data: data && data.sharingLast30Days.map((item) => item.quantity),
          backgroundColor: theme.palette.primary.light,
          borderColor: theme.palette.primary.main,
          borderWidth: 1
        }
      ]
    }

    if (loading) return <Loading />

    return (
      _data &&
      _data.labels &&
      _data.labels.length > 2 && (
        <Card style={{ width: '100%', minHeight: '21em' }}>
          <Grid container component={Box} p={3}>
            <Typography variant="body1"> Compartilhamentos Últimos 30D</Typography>
            <Bar data={_data} options={options} />
          </Grid>
        </Card>
      )
    )
  }

  const options = {
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  }

  const TileOptions = () => {
    return (
      <Grid container md={12} spacing={1}>
        <DashboardItem
          id={'tests'}
          to="/tests"
          icon={'images/dashboard/assessments.png'}
          title="Testes"
          svgIcon="view_list"
          description="Gerencie os testes criados"
          subtitle="Administrar "
        />
        <DashboardItem
          id={'candidates'}
          to="/candidates"
          icon={'images/dashboard/candidates.png'}
          subtitle="Administrar"
          title="Avaliados"
          svgIcon="face"
          description="Administre os avaliados de sua conta"
        />
        <DashboardItem
          id={'results'}
          to="/tests/results"
          icon={'images/dashboard/results.png'}
          title="Resultados"
          svgIcon={'find_in_page'}
          description="Revise resultados dos testes, compartilhe."
          subtitle={'Administrar '}
        />
        {profile.admin && (
          <DashboardItem
            id={'users'}
            to="/users"
            title="Usuários"
            icon={'images/dashboard/users.png'}
            svgIcon={'person'}
            description="Adicione ou remova usuários da sua conta"
            subtitle={'Administrar '}
          />
        )}
      </Grid>
    )
  }
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item md={12} xs={12}>
        <Greetings profile={profile} />
      </Grid>
      <Grid item md={12} xs={12}>
        <TileOptions />
      </Grid>
      <Grid item md={5} xs={12}>
        <SharingChart />
      </Grid>
      <Grid item md={7} xs={12}>
        <EventsContainer />
      </Grid>
      <Grid container item md={12} spacing={3}>
        <Grid item xs={3} md={9}></Grid>
        <Grid item xs={12} md={3}>
          {process.env.TUTORIAL_TRENDING_DASHBOARD && (
            <Card>
              <CardContent>
                <FormHeading>Em alta</FormHeading>
                <YouTube opts={opts} videoId={config.tutorials.TUTORIAL_TRENDING_DASHBOARD} />
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
